<template>
    <div :class="['manage-slides', { expanded: isExpanded }]">
        <div class="action-bar">
            <div class="left-buttons">
                <!-- <div class="button-divider"></div> -->
            </div>

            <div class="right-buttons">
                <button class="icon-button rounded wide" @click="toggleHeight">
                    <svg
                        class="mr-1"
                        :style="{
                            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'width transform 0.5s',
                        }"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.16671 12.5L10 6.66667L15.8334 12.5"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    {{ !isExpanded ? 'Browse Music Library' : 'Back to Canvas' }}
                </button>
            </div>
        </div>
        <draggable
            v-model="slides"
            class="slides-container"
            v-if="!loading"
            :options="{ animation: 300, ghostClass: 'ghost-hidden', draggable: '.slide' }"
            @start="onDragStart"
            @end="onDragEnd"
        >
            <template v-for="(song, index) in slides">
                <div
                    class="slide"
                    :class="{
                        dragging: isDragging && dragIndex === index,
                    }"
                >
                    <div
                        class="slide-order"
                        :style="{
                            zIndex: 1000,
                        }"
                    >
                        {{ index + 1 }}
                    </div>
                    <MusicTrack
                        :song="song"
                        mode="slide"
                        :isSelected="true"
                        :token="token"
                        :key="song.id"
                        :class="{ mini: isExpanded }"
                    />
                </div>
            </template>
            <div
                class="empty"
                v-if="!slides.length"
                @click="
                    () => {
                        if (!isExpanded) {
                            toggleHeight();
                        }
                    }
                "
            >
                <template v-if="isExpanded">
                    <div class="icon">
                        <font-awesome-icon icon="fa-regular fa-music"></font-awesome-icon>
                    </div>
                    <strong>No Music Selected</strong>
                    <p>Add a meaningful track to accompany this tribute and make it truly memorable.</p>
                </template>
                <template v-else>
                    <div class="icon">
                        <font-awesome-icon icon="fa-regular fa-plus"></font-awesome-icon>
                    </div>
                    <strong>Add Music</strong>
                </template>
            </div>
            <div class="genre-slide staff-picked" @click="setGenre()">
                <div class="staff-picked-banner">
                    <img :src="laurelIcon" alt="Staff Picked" />
                    Staff Picked
                </div>
                <img :src="allMusicArtwork" alt="Explore All Music" class="slide-image" />
                <div class="genre-info">
                    <strong>Explore All Music</strong>
                </div>
            </div>
            <template v-for="genre in musicGenres" v-if="musicGenres.length">
                <div class="genre-slide" :key="genre.id" @click="setGenre(genre)">
                    <img :src="genre.image" :alt="genre.name" class="slide-image" />
                    <div class="genre-info">
                        <strong>{{ genre.name }}</strong>
                    </div>
                </div>
            </template>
        </draggable>
        <template v-else>
            <div class="slides-container">
                <div class="slide loading-slide" v-for="n in 10" :key="n"></div>
            </div>
        </template>
        <modal :open="showDeleteModal" @close="closeDeleteModal">
            <template v-slot:modal-title>
                <div>Are you sure you want to remove this song?</div>
            </template>

            <template v-slot:modal-content>
                <div>This action cannot be undone, and the song will be removed from your slide collection.</div>
            </template>

            <template v-slot:modal-footer>
                <button class="btn btn-deactivate" @click="confirmDelete">Confirm</button>
                <button class="btn btn-cancel" @click="closeDeleteModal">Cancel</button>
            </template>
        </modal>
        <div v-show="isExpanded" class="playlist-container">
            <MusicExplorer ref="musicExplorer" :token="token" />
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import modal from './WarningModal.vue';
import moment from 'moment';
import { orderBy, debounce, each, findIndex, map, isEqual } from 'lodash';
import SlideUpload from './SlideUpload.vue';
import ImagePreview from '../Media/ImagePreview.vue';
import { mapActions } from 'vuex';
import TributeSongService from '@/services/tributeSong.service';
import WaveformV2 from '@/components/ui/WaveformV2.vue';
import { PlayIcon, PauseIcon } from 'vue-feather-icons';
import MusicExplorer from './Music/MusicExplorer.vue';
import MusicTrack from './Music/MusicTrack.vue';
export default {
    name: 'ManageMusic',
    components: {
        draggable,
        modal,
        SlideUpload,
        ImagePreview,
        PlayIcon,
        MusicExplorer,
        WaveformV2,
        MusicTrack,
        CreativeEditor: () => import('./Editor/CreativeEditor.vue'),
    },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        tributeVideo: {
            type: Object,
            required: true,
        },
    },
    async mounted() {
        const response = await this.$auth.getIdTokenClaims();
        this.token = response.__raw;
        this.apiService = TributeSongService(this.token);

        if (this.eventId) {
            const promises = [];
            const selectedPromise = this.apiService.getSelected(this.$props.tributeVideo.id).then(songsResp => {
                if (songsResp.data?.length) {
                    this.slides = songsResp.data;
                    this.$store.dispatch('tributeVideo/updateTributeVideoSelectedSongs', songsResp.data);
                }
            });
            promises.push(selectedPromise);
            const genrePromise = this.apiService.getGenres().then(genreResults => {
                if (genreResults.data.musicGenres?.length) {
                    this.musicGenres = genreResults.data.musicGenres;
                }
            });
            promises.push(genrePromise);
            Promise.allSettled(promises).then(() => {
                this.loading = false;
            });
            // Listen for new songs being added/removed and update the state
            this.$store.subscribe(mutation => {
                if (mutation.type === 'tributeVideo/updateTributeVideoSelectedSongs') {
                    this.slides = mutation.payload;
                    this.$emit('refresh-preview', this.$props.tributeVideo.id);
                }
            });
        } else {
            // Could a user get to this page without an eventId?
            // For now we'll just stop the loading
            this.loading = false;
        }
    },
    beforeDestroy() {},

    data() {
        return {
            apiService: null,
            token: null,
            maxFiles: 400,
            musicGenres: [],
            loading: true,
            fallbackArtwork: require(`@/assets/images/fallback-song-art.png`),
            laurelIcon: require(`@/assets/icons/laurel.svg`),
            allMusicArtwork: require(`@/assets/images/exploreArtwork.png`),
            isDragging: false,
            dragIndex: null,
            selectedSlideIndex: null,
            textSlideBgColor: '#ffffff',
            textSlideContent: '',
            showDeleteModal: false,
            slides: [],
            tmpSlideOrder: [],
            // Still deciding if we want to display newest to beginning or end of arr
            // displaySlidesReversed: true,
        };
    },
    computed: {
        maxFilesLeft() {
            // computed difference between how many files max and existing items
            // TODO: included pending uploads while they resolve and remove if they error, etc
            return this.maxFiles - this.slides.length;
        },
        slidesContainerHeight() {
            if (this.isExpanded) {
                const slidesPerRow = Math.floor(this.$el.clientWidth / 250);
                const numberOfRows = Math.ceil(this.slides.length / slidesPerRow);
                return `${numberOfRows * 258}px`; // slide height (242px) + gap (8px * 2)
            } else {
                return '242px';
            }
        },
        isAnyImageSelected() {
            return this.selectedSlideIndex !== null;
        },
        isExpanded: {
            get() {
                return this.$store.state.tributeEditor.expanded;
            },
        },
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        handleResize() {
            const container = this.$el.querySelector('.slides-container');
            if (container) {
                container.scrollLeft = 0;
            }
        },
        moveSlideForward() {
            if (this.selectedSlideIndex !== null && this.selectedSlideIndex < this.slides.length - 1) {
                const currentIndex = this.selectedSlideIndex;
                const newIndex = currentIndex + 1;
                const slide = this.slides[currentIndex];

                if (!slide) return;

                this.slides.splice(newIndex, 0, this.slides.splice(currentIndex, 1)[0]);
                this.selectedSlideIndex = newIndex;

                const targetOrder = this.getTargetOrder(newIndex);
                this.quickOrder(slide.id, targetOrder);
            }
        },
        moveSlideBackward() {
            if (this.selectedSlideIndex !== null && this.selectedSlideIndex > 0) {
                const currentIndex = this.selectedSlideIndex;
                const newIndex = currentIndex - 1;
                const slide = this.slides[currentIndex];

                if (!slide) return;

                this.slides.splice(newIndex, 0, this.slides.splice(currentIndex, 1)[0]);
                this.selectedSlideIndex = newIndex;

                const targetOrder = this.getTargetOrder(newIndex);
                this.quickOrder(slide.id, targetOrder);
            }
        },
        getSongs() {
            this.apiService.getSongs().then(songResults => {});
        },
        setGenre(genre) {
            if (!this.isExpanded) {
                this.toggleHeight();
            }
            this.$nextTick(() => {
                if (genre) {
                    this.$refs.musicExplorer.setFilter('genre', genre.id);
                } else {
                    this.$refs.musicExplorer.setFilter();
                }
            });
        },
        onDragStart(evt) {
            // store the slide order for referencing later
            this.tmpSlideOrder = map(this.slides, 'id');
            this.isDragging = true;
            this.dragIndex = evt.oldIndex;
        },
        onDragEnd(evt) {
            this.isDragging = false;
            this.dragIndex = null;

            this.handleSingleItemDragEnd(evt).then(() => {
                console.log('clear out the tmpslideorder');
                this.tmpSlideOrder = [];
            });
        },
        getTargetOrder(arrIndex) {
            // return this.displaySlidesReversed ? arrIndex : this.totalSlides - 1 - arrIndex;
            // return this.totalSlides - 1 - arrIndex;
            return arrIndex;
        },
        async handleSingleItemDragEnd(evt) {
            console.log(evt, 'single item drag');
            // if the user placed the slide in the same spot, don't trigger a change
            if (isEqual(this.tmpSlideOrder, map(this.slides, 'id'))) {
                console.log('no order changes made, nothing to save');
                return;
            }

            const slide = this.slides[evt.newIndex];

            if (slide) {
                const targetOrder = this.getTargetOrder(evt.newIndex);
                await this.quickOrder(slide.id, targetOrder);
            }
        },
        quickOrder: debounce(async function (id, order) {
            if (this.isContributePage) {
                this.showSnackbar({ message: 'Unauthorized to edit order' });
                return;
            }
            try {
                // grab the order of song Ids and trigger a updateSelectedSongs
                const updatedSongOrder = map(this.slides, 'id');
                return this.apiService
                    .updateSelectedSongs(this.$props.tributeVideo.id, {
                        selectedSongs: updatedSongOrder,
                    })
                    .then(orderResults => {
                        if (orderResults.status === 200 && orderResults.data?.songs) {
                            this.$store.dispatch(
                                'tributeVideo/updateTributeVideoSelectedSongs',
                                orderResults.data.songs,
                            );
                            this.showSnackbar({ message: 'Song order changes saved.' });
                            this.$emit('refresh-preview', this.$props.tributeVideo.id);
                        }
                    })
                    .catch(() => {
                        // TODO: Better error handling
                        this.showSnackbar({
                            message: 'Unable to save changes, please try again later.',
                        });
                    });
            } catch (error) {
                this.showSnackbar({ message: 'Error updating order' });
            }
        }, 500),

        selectImage(index) {
            if (this.selectedSlideIndex === index) {
                this.selectedSlideIndex = null;
            } else {
                this.selectedSlideIndex = index;
                this.focusSlide(index);
            }
        },
        focusSlide(index) {
            this.$nextTick(() => {
                const slide = this.$el.querySelectorAll('.slide')[index];
                if (slide) {
                    slide.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                }
            });
        },
        openDeleteModal() {
            if (this.isAnyImageSelected) {
                this.showDeleteModal = true;
            }
        },
        closeDeleteModal() {
            this.showDeleteModal = false;
        },
        confirmDelete() {
            if (this.selectedSlideIndex !== null) {
                this.slides.splice(this.selectedSlideIndex, 1);
                this.selectedSlideIndex = null;
            }
            this.closeDeleteModal();
        },
        getCurrentSlideOrder() {
            return this.slides.map(slide => slide.id);
        },
        toggleHeight() {
            // this.isExpanded = !this.isExpanded;
            this.toggleExpandedState();
            // If music explorer is exapanded, don't play in background otherwise do
            this.$store.dispatch('miniPlayer/updatePlayingInBackground', !this.isExpanded);
        },
    },
};
</script>

<style lang="scss" scoped>
.manage-slides {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    transition: height 0.8s;
    z-index: 1;
}

.action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 38px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.primary-button,
.secondary-button {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.shadow-button {
    box-shadow: 0px 1px 2px 0px #0000000d;
}

.left-buttons {
    display: flex;
    align-items: center;
}

.primary-button {
    width: 228px;
    background: $main-orange;
    color: white;
    border: none;
    height: 38px;
    border-radius: 6px;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.secondary-button {
    width: 138px;
    background: white;
    color: #374151;
    border: 1px solid #d1d5db;
    height: 38px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.button-divider {
    width: 1px;
    height: 60%;
    background: #d1d5db;
    margin-right: 8px;
}

.right-buttons {
    display: flex;
    width: 260px;
    justify-content: flex-end;
    border-radius: 6px;
    gap: 8px;
}

.icon-button {
    width: 38px;
    height: 38px;
    background: white;
    border: 1px solid #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    &.rounded {
        border-radius: 8px !important;
    }
    &.wide {
        width: initial;
        padding: 0 10px;
        font-weight: 500;
    }
    &.primary-orange {
        background: $main-orange;
        color: #fff;
        border: none;
    }
    &.select {
        border: 1px solid lighten($btn-orange, 20%);
        background: lighten($btn-orange, 40%);
        color: $btn-orange;
    }
    &.remove {
        border: 1px solid lighten($btn-red, 20%);
        background: lighten($btn-red, 35%);
        color: $btn-red;
    }
}

.highlighted-icon-button {
    width: 38px;
    height: 38px;
    background: #fee2e2;
    border: 1px solid #fca5a5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slides-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    padding: 8px 0;
    overflow-x: auto;
    overflow-y: hidden;
    transition: height 0.8s;
    height: 100%;
    max-height: 242px;
}

.slide,
.genre-slide {
    aspect-ratio: 1 / 1;
    border-radius: 8px;
    position: relative;
    transition: transform 0.2s, border 0.2s;
    flex-shrink: 0;
    display: inline-block;
    overflow: hidden;

    .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        z-index: -1;
    }
}
.slide.loading-slide {
    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px, #ddd 400px);
    background-size: 200% 100%;
    background-repeat: repeat-x;
    animation: shimmer 1.6s infinite linear;
}
.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px lighten($medium-gray, 20%) dashed;
    width: 100%;
    max-width: 242px;
    justify-content: center;
    font-size: 0.9rem;
    aspect-ratio: 1 / 1;
    border-radius: 10px;
    cursor: pointer;
    .icon {
        color: $btn-orange;
        background: lighten($btn-orange, 40%);
        padding: 1.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        border-radius: 10em;
        margin-bottom: 1rem;
    }
}
.manage-slides.expanded {
    .slides-container {
        min-height: 170px;
        height: fit-content;
    }
    .slide {
        height: fit-content;
        aspect-ratio: auto;
    }
    .genre-slide {
        display: none;
    }
    .empty {
        min-height: 160px;
        max-height: 100%;
        max-width: 100%;
        aspect-ratio: auto;
        flex-grow: 1;
        cursor: auto;
    }
}
@keyframes shimmer {
    0% {
        background-position: -32px;
    }
    40%,
    100% {
        background-position: 360px;
    }
}
.genre-slide {
    user-select: none;
    cursor: pointer;
    .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        aspect-ratio: 1;
        z-index: -1;
    }
    .genre-info {
        position: absolute;
        bottom: 0;
        padding: 1rem;
        color: white;
        white-space: nowrap;
        width: 100%;
        // slight gradiant to help the genre text stand out against light backgrounds
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), transparent);
    }
    .staff-picked-banner {
        background: $btn-orange;
        border: 1px solid $main-orange;
        color: white;
        position: absolute;
        top: 0;
        inset: 0;
        height: 30px;
        padding: 8px;
        border-radius: 54px;
        border-bottom-right-radius: 0;
        width: fit-content;
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 8px;
        margin: 16px;
        font-size: 12px;
        img {
            transform: scale(1.8);
        }
    }
}

.slide.dragging {
    transform: rotate(-3deg);
    border: 4px solid $main-orange;
}

.slide.selected .slide-image {
    border: 4px solid $main-orange;
}

.slide-order {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 42px;
    height: 32px;
    background: white;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px #0000000d;
    color: #6b7280;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2.5%;
}

.selected-indicator {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    background: $main-orange;
    border: 1.5px solid $main-orange;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ghost-hidden {
    display: none !important;
}

.ghost-slide {
    width: 100%;
    max-width: 242px;
    aspect-ratio: 1;
    border: 1px dashed #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    flex-shrink: 0;
}

.ghost-icon-container {
    width: 52px;
    height: 38px;
    background: #fff7ed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.ghost-icon {
    width: 20px;
    height: 20px;
}
.playlist-container {
    overflow-y: auto;
}
</style>
