import ApiService from './api.service';

const TributePhotoService = token => {
    const api = ApiService.createApiInstance(token);

    return {
        createPhotoBatch(eventId, photoBatchData, replaceMainPhoto = false, config = {}) {
            return api.post(`/TributeVideoPhoto/${eventId}?replaceMain=${replaceMainPhoto}`, photoBatchData, {
                ...config,
            });
        },
        deletePhoto(id) {
            return api.delete(`/TributeVideoPhoto/${id}`);
        },
        deletePhotoBatch(ids) {
            return api.put(`/TributeVideoPhoto/batch-delete`, ids);
        },
        deletePhotoByGuid(guid) {
            return api.delete(`/TributeVideoPhoto/unique-delete/${guid}`);
        },
        getCollectionDuplicates(serviceId) {
            return api.get(`/TributeVideoPhoto/find-collection-duplicates/${serviceId}`);
        },
        getCollectionSimilars(serviceId) {
            return api.get(`/TributeVideoPhoto/find-collection-similars/${serviceId}`);
        },
        getCollectionUrls(tributeId) {
            return api.get(`/TributeVideoPhoto/get-collection-urls/${tributeId}`);
        },
        getUploadUrl(eventId, file) {
            return api.get(`/TributeVideoPhoto/sas/${eventId}/${file.name}`);
        },
        updatePhotoFileName(id, fileName) {
            return api.put(`/TributeVideoPhoto/${id}`, JSON.stringify(fileName));
        },
        getRemainingAllowedUploads(id) {
            return api.get(`/TributeVideoPhoto/remaining-allowed-uploads/${id}`);
        },
        updateProfilePhoto(eventId, photoId) {
            return api.post(`/TributeVideoPhoto/update-main-photo/${eventId}/${photoId}`);
        },
        getPhotos(eventId, params) {
            return api.get(`/TributeVideoPhoto/event-photos/${eventId}`, {
                params: params,
            });
        },
        revertPhotoToOriginal(photoId) {
            return api.post(`/TributeVideoPhoto/revert-original-photo/${photoId}`);
        },
        updateOrder(id, targetIndex) {
            return api.put(`TributeVideoPhoto/update-order/${id}/${targetIndex}`);
        },
        replacePhoto(id, fileName, keepOriginal) {
            let data = { fileName };

            return api.put(`/TributeVideoPhoto/replace-photo/${id}?keepOriginal=${keepOriginal}`, data);
        },
    };
};

export default TributePhotoService;
