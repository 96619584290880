import Vue from 'vue';
import Vuex from 'vuex';
import { setLocalSettings } from '../utilities/general';
import tributeVideo from './modules/tribute-video';
import tributeEditor from './modules/tribute-editor';
import miniPlayer from './modules/mini-player';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        tributeVideo,
        miniPlayer,
        tributeEditor,
    },
    state: {
        loggedInUser: null,
        blocked: false, // UI displays a global loading spinner if true
        menuShowing: true,
        topNavShowing: true,
        UsersTableShowing: false,
        streaming: false,
        videoSrc: '',
        enablePreview: false,
        customLogo: '',
        whitelabel: false,
        customHTML: '',
        userSupport: false,
        displayTerms: true,
        impersonatingName: '',
        displayPromoBanner: false,
        displayKeywordReportNav: false,
        snackbar: {
            message: null,
            color: null,
            timeout: null,
            button: {
                text: null,
                color: null,
                callback: () => {},
            },
        },
    },
    mutations: {
        updateDisplayKeywordReportNav(state, payload) {
            state.displayKeywordReportNav = payload;
        },
        updateDisplayPromoBanner(state, payload) {
            state.displayPromoBanner = payload;
        },
        updateDisplayTerms(state, payload) {
            state.displayTerms = payload;
        },
        updateLoggedInUser(state, payload) {
            state.loggedInUser = payload;
        },
        updateImpersonatingName(state, payload) {
            state.impersonatingName = payload;
        },
        updateCustomHTML(state, payload) {
            state.customHTML = payload;
        },
        updateUserSupport(state, payload) {
            state.userSupport = payload;
        },
        updateCustomLogo(state, payload) {
            state.customLogo = payload;
        },
        updateWhitelabel(state, payload) {
            state.whitelabel = payload;
        },
        toggleBlock(state, payload) {
            state.blocked = payload;
        },
        toggleSideNav(state) {
            state.menuShowing = !state.menuShowing;

            if (state.menuShowing) {
                setLocalSettings('appSettings', { menuState: 'max' });
            } else {
                setLocalSettings('appSettings', { menuState: 'min' });
            }
        },
        toggleTopNav(state, payload) {
            state.topNavShowing = payload;
        },
        toggleUsersTable(state) {
            state.UsersTableShowing = !state.UsersTableShowing;
        },
        toggleStreaming(state, payload) {
            state.streaming = payload;
        },
        setVideoSrc(state, payload) {
            state.videoSrc = payload;
        },
        setEnablePreview(state, payload) {
            state.enablePreview = payload;
        },
        showSnackbar(state, { message = '', color = '', timeout = '2000', button = null }) {
            state.snackbar.message = message;
            state.snackbar.color = color;
            state.snackbar.timeout = timeout;
            const defaultCallback = () => {};
            if (button !== null) {
                state.snackbar.button.text = button.text || null;
                state.snackbar.button.color = button.color || null;
                state.snackbar.button.callback = button.callback || defaultCallback;
            } else {
                state.snackbar.button = null;
            }
        },
    },
    actions: {
        updateDisplayKeywordReportNav({ commit }, payload) {
            commit('updateDisplayKeywordReportNav', payload);
        },
        updateDisplayPromoBanner({ commit }, payload) {
            commit('updateDisplayPromoBanner', payload);
        },
        updateDisplayTerms({ commit }, payload) {
            commit('updateDisplayTerms', payload);
        },
        updateLoggedInUser({ commit }, payload) {
            commit('updateLoggedInUser', payload);
        },
        updateImpersonatingName({ commit }, payload) {
            commit('updateImpersonatingName', payload);
        },
        updateCustomHTML({ commit }, payload) {
            commit('updateCustomHTML', payload);
        },
        updateUserSupport({ commit }, payload) {
            commit('updateUserSupport', payload);
        },
        updateCustomLogo({ commit }, payload) {
            commit('updateCustomLogo', payload);
        },
        updateWhitelabel({ commit }, payload) {
            commit('updateWhitelabel', payload);
        },
        toggleSideNav(context) {
            context.commit('toggleSideNav');
        },
        toggleTopNav(context, payload) {
            context.commit('toggleTopNav', payload);
        },
        toggleUsersTable(context) {
            context.commit('toggleUsersTable');
        },
        toggleStreaming(context, payload) {
            context.commit('toggleStreaming', payload);
        },
        setVideoSrc(context, payload) {
            context.commit('setVideoSrc', payload);
        },
        setVideoSrc(context, payload) {
            context.commit('setVideoSrc', payload);
        },
        setEnablePreview(context, payload) {
            context.commit('setEnablePreview', payload);
        },
        showSnackbar({ commit }, payload) {
            commit('showSnackbar', payload);
        },
        block(context, payload) {
            context.commit('toggleBlock', payload);
        },
    },
});
