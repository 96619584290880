<template>
    <div>
        <div class="pb-2">
            <v-hover v-slot="{ hover }">
                <v-btn
                    :outlined="hover ? false : true"
                    :elevation="hover ? 8 : 0"
                    :loading="loading"
                    :disabled="loading"
                    dark
                    small
                    color="#ff530d"
                    @click="importStep1"
                    >Import</v-btn
                >
            </v-hover>
        </div>

        <v-data-table
            :headers="headers"
            :items="templates"
            :server-items-length="totalTemplates"
            :loading="loading"
            :options.sync="options"
            @click:row="openDetails"
            :footer-props="{
                itemsPerPageOptions: [5, 25, 50],
            }"
            loading-text="Getting themes..."
        >
            <template v-slot:item.title="{ item }">
                <div v-if="item.staffFavorite">
                    <v-chip dark color="#ff530d" x-small>
                        <span> Staff Favorite </span>
                    </v-chip>
                </div>
                <div>{{ item.title }}</div>
            </template>

            <template v-slot:item.dynamicElements="{ item }">
                <div>
                    {{ item.dynamicElements.length }}
                </div>
            </template>

            <template v-slot:item.createDate="{ item }">
                <div>
                    {{ item.createDate | moment('l') }}
                </div>
            </template>
            <template v-slot:item.updateDate="{ item }">
                <div>
                    {{ item.updateDate | moment('l') }}
                </div>
            </template>
            <template v-slot:item.duration="{ item }">
                <div>
                    {{ item.duration | durationTimeStamp }}
                </div>
            </template>

            <template v-slot:item.url="{ item }">
                <div>
                    <font-awesome-icon
                        style="color: #00c853; font-size: 1.2rem"
                        v-if="item.url"
                        icon="fa-regular fa-circle-check"
                    ></font-awesome-icon>
                    <font-awesome-icon
                        style="color: #fc676b; font-size: 1.2rem"
                        v-else
                        icon="fa-regular fa-circle-x"
                    ></font-awesome-icon>
                </div>
            </template>

            <template v-slot:item.tributeTemplateStatus="{ item }">
                <v-chip small dark :color="tributeTemplateStatuses[item.tributeTemplateStatus].color">{{
                    tributeTemplateStatuses[item.tributeTemplateStatus].label
                }}</v-chip>
                <!-- <div>{{ item.tributeTemplateStatus }}</div> -->
            </template>

            <template v-slot:item.status="{ item }">
                <v-chip small dark :color="creatomateRenderStatuses[item.status].color">{{
                    creatomateRenderStatuses[item.status].label
                }}</v-chip>
                <!-- <div>{{ item.status }}</div> -->
            </template>

            <template v-slot:item.actions="{ item }">
                <div class="action-list">
                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-regular fa-clapperboard-play"
                                    style="font-size: 1.1rem"
                                    @click.stop="openTemplateLink(item.cosmosId)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Open Creatomate</span>
                        </template>
                    </custom-tooltip>

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    icon="fa-solid fa-rotate"
                                    style="font-size: 1rem"
                                    @click.stop="submitImport(item.cosmosId)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Sync Template</span>
                        </template>
                    </custom-tooltip>

                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <font-awesome-icon
                                    icon="fa-solid fa-camera-movie"
                                    style="font-size: 1rem"
                                    @click.stop="startNewDemo(item)"
                                />
                            </span>
                        </template>

                        <span>Update Demo Video</span>
                    </v-tooltip> -->

                    <custom-tooltip :tooltipProps="{ bottom: true }">
                        <template v-slot:activator>
                            <span>
                                <font-awesome-icon
                                    class="trash"
                                    icon="fa-regular fa-trash-can"
                                    style="font-size: 1rem"
                                    @click.stop="openDeleteModal(item)"
                                />
                            </span>
                        </template>

                        <template v-slot:content>
                            <span>Delete</span>
                        </template>
                    </custom-tooltip>
                </div>
            </template>
        </v-data-table>

        <v-dialog max-width="900px" v-model="demoModal">
            <v-card v-if="selectedTemplate != null" class="p-3">
                <v-row>
                    <v-col cols="12">
                        <h5>{{ selectedTemplate.title }}</h5>
                    </v-col>
                    <v-col
                        :cols="selectedTemplate.dynamicFields.length > 1 ? '6' : '12'"
                        v-for="item in selectedTemplate.dynamicFields"
                    >
                        <v-text-field
                            :rules="item.type == 0 ? [] : mediaRules"
                            v-model="item.value"
                            :hint="templateDynamicElements[item.type].label"
                            persistent-hint
                            :label="item.name"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <div class="text-right">
                            <v-btn
                                :loading="loading"
                                :disabled="loading"
                                @click="submitTemplateRender(selectedTemplate.id)"
                                >Submit</v-btn
                            >
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>

        <v-dialog max-width="900px" @click:outside="closeDetails" v-model="detailsModal">
            <v-card class="p-3">
                <div v-if="selectedTemplate != null">
                    <v-window v-model="detailStep">
                        <v-window-item :value="1">
                            <v-row>
                                <v-col cols="12">
                                    <div style="display: flex; justify-content: space-between; width: 100%">
                                        <h4 v-if="!editingTitle">{{ selectedTemplate.title }}</h4>

                                        <v-text-field
                                            @keydown.enter="saveTitle"
                                            autofocus
                                            v-if="editingTitle"
                                            label="Title"
                                            v-model="titleText"
                                        ></v-text-field>
                                        <div v-if="selectedTemplate.url">
                                            <v-btn
                                                depressed
                                                small
                                                class="mr-2"
                                                :loading="loading"
                                                :disabled="loading"
                                                @click="sendToProd"
                                            >
                                                Move to Prod
                                            </v-btn>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs" v-on="on" depressed small class="mr-2">
                                                        Template Status
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item
                                                        v-for="(item, index) in tributeTemplateStatuses"
                                                        :key="index"
                                                        hover
                                                        class="list-item"
                                                        @click="updateTemplateStatus(index)"
                                                    >
                                                        <v-list-item-title>
                                                            <div class="d-flex align-center">
                                                                {{ item.label }}
                                                                <v-icon
                                                                    v-if="
                                                                        selectedTemplate.tributeTemplateStatus === index
                                                                    "
                                                                    color="primary"
                                                                    class="ml-2"
                                                                    small
                                                                >
                                                                    mdi-check
                                                                </v-icon>
                                                            </div>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>

                                            <v-btn @click="editingTitle = !editingTitle" depressed small>{{
                                                editingTitle ? 'Cancel' : 'Edit Title'
                                            }}</v-btn>
                                            <!-- :color="editingTitle ? '' : 'primary'" -->
                                            <v-btn
                                                v-if="!editingTitle"
                                                class="ml-2"
                                                depressed
                                                small
                                                @click="detailStep = 2"
                                                color="primary"
                                                >View Demo</v-btn
                                            >
                                            <v-btn
                                                @click="saveTitle"
                                                v-if="editingTitle"
                                                class="ml-2"
                                                depressed
                                                small
                                                color="#06AA58"
                                                dark
                                                >Save</v-btn
                                            >
                                        </div>
                                    </div>
                                    <div>
                                        <v-btn
                                            color="#ff530d"
                                            @click="toggleStaffFavorite(selectedTemplate)"
                                            x-small
                                            fab
                                            text
                                        >
                                            <font-awesome-icon
                                                style="font-size: 1rem"
                                                :icon="
                                                    selectedTemplate.staffFavorite
                                                        ? 'fa-solid fa-heart'
                                                        : 'fa-regular fa-heart'
                                                "
                                            ></font-awesome-icon>
                                        </v-btn>
                                    </div>
                                </v-col>

                                <v-col cols="6">
                                    <v-text-field
                                        label="Demo Url"
                                        v-model="selectedTemplate.url"
                                        disabled
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Cosmos/Creatomate Id"
                                        v-model="selectedTemplate.cosmosId"
                                        disabled
                                        readonly
                                    ></v-text-field>
                                </v-col>
                                <v-col dense cols="12">
                                    <h5>Dynamic Elements</h5>
                                </v-col>
                                <v-col cols="12">
                                    <div>
                                        <v-data-table
                                            :items="selectedTemplate.dynamicElements"
                                            :headers="elementHeaders"
                                        >
                                            <template v-slot:item.type="{ item }">
                                                <div>
                                                    {{ templateDynamicElements[item.type].label }}
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-window-item>

                        <v-window-item :value="2">
                            <div class="p-1" style="width: 100%">
                                <v-btn small depressed class="mb-2" @click="detailStep = 1">
                                    <font-awesome-icon class="mr-2" icon="fa-solid fa-arrow-left"></font-awesome-icon>
                                    Details</v-btn
                                >
                                <video
                                    style="border-radius: 10px"
                                    v-if="selectedTemplate.url"
                                    :src="selectedTemplate.url"
                                    :key="reloadCount"
                                    controls
                                ></video>
                            </div>
                        </v-window-item>
                    </v-window>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="importModal" max-width=" 500px">
            <v-card class="p-3">
                <h5>Import</h5>
                <v-text-field label="Creatomate Template Id" v-model="importId"></v-text-field>
                <div class="text-right">
                    <v-btn
                        :dark="!loading"
                        color="#0d3d60"
                        :loading="loading"
                        :disabled="loading"
                        @click="submitImport(importId)"
                        >Submit</v-btn
                    >
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteModal" max-width=" 500px">
            <v-card class="p-3">
                <h5>Delete?</h5>
                <div v-if="selectedTemplate != null">
                    <div>
                        <span>Id: {{ selectedTemplate.id }}</span>
                    </div>
                    <strong>{{ selectedTemplate.title }}</strong>
                    <p>This will delete a tribute template and associated data. This cannot be undone.</p>

                    <div class="text-right">
                        <v-btn
                            color="error"
                            :loading="loading"
                            :disabled="loading"
                            @click="deleteTemplate(selectedTemplate.cosmosId)"
                            >Delete</v-btn
                        >
                    </div>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { creatomateRenderStatuses, templateDynamicElements, tributeTemplateStatuses } from '@/constants.js';
import { debounceV2 } from '@/utilities/debounce.js';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            templateDynamicElements,
            creatomateRenderStatuses,
            tributeTemplateStatuses,
            editingTitle: false,
            detailsModal: false,
            selectedTemplate: null,
            templates: [],
            totalTemplates: 0,
            loading: false,
            options: {
                itemsPerPage: 25,
                page: 1,
            },
            loading: true,
            detailStep: 1,
            reloadCount: 0,
            importModal: false,
            demoModal: false,
            deleteModal: false,
            titleText: '',
            importId: '',
            headers: [
                // { text: 'Id', value: 'id' },
                { text: 'Title', value: 'title' },
                // { text: 'Cosmos/Creatomate Id', value: 'cosmosId' },
                { text: 'Create Date', value: 'createDate' },
                { text: 'Last Sync', value: 'updateDate' },
                { text: 'Elements', value: 'dynamicElements', align: 'center' },
                { text: 'Duration', value: 'duration' },
                { text: 'Template Status', value: 'tributeTemplateStatus', align: 'center' },
                // { text: 'Demo', value: 'url', align: 'center' },
                { text: 'Render Status', value: 'status', align: 'center' },
                { text: 'Actions', value: 'actions', align: 'center', sortable: false },
            ],
            elementHeaders: [
                { text: 'Id', value: 'id' },
                { text: 'Field Name', value: 'name' },
                { text: 'Type', value: 'type' },
                { text: 'CreatomateId', value: 'creatomateId' },
            ],
            requiredRules: [value => !!value || 'Required'],
            mediaRules: [
                value =>
                    !value || // Make the field optional if it is empty
                    this.mediaRegex(value) ||
                    'Invalid media URL or filename.',
            ],
        };
    },
    components: { CustomTooltip },
    props: ['search'],
    watch: {
        search: debounceV2(function () {
            this.getTemplates();
        }, 500),
        options: {
            handler() {
                if (!this.loading) {
                    this.getTemplates();
                }
            },
        },
    },
    filters: {
        durationTimeStamp(val) {
            var minutes = Math.floor(val / 60);
            var seconds = val - minutes * 60;

            function str_pad_left(string, pad, length) {
                return (new Array(length + 1).join(pad) + string).slice(-length);
            }
            const finalTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
            return finalTime;
        },
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        mediaRegex(input) {
            let regex =
                /(https?:\/\/.*\.(?:png|jpe?g|gif|svg|mp4|webm|ogg|mp3|wav|flac)|.*\.(?:png|jpe?g|gif|svg|mp4|webm|ogg|mp3|wav|flac))$/i;

            // let regex = /\.[A-Za-z]+/i;
            return regex.test(input);
        },
        openTemplateLink(id) {
            const url = `https://creatomate.com/projects/456f5dd1-0153-4d09-94ad-a257b399e80b/templates/${id}`;
            window.open(url, '_blank');
        },
        deleteTemplate(cosmosId) {
            this.loading = true;
            this.axiosInstance
                .delete(`/TributeVideoTemplate/${cosmosId}`)
                .then(respsone => {
                    this.showSnackbar({ message: 'Template deleted' });
                    this.deleteModal = false;
                    this.loading = false;
                    this.selectedTemplate = null;
                    this.getTemplates();
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error deleting template', color: 'red' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openDeleteModal(item) {
            this.selectedTemplate = item;
            this.deleteModal = true;
        },
        submitTemplateRender(id) {
            let mods = this.getModsJson();
            this.loading = true;

            // this.axiosInstance
            //     .post(`TributeVideoTemplate/update-demo-url/${id}`, mods)
            //     .then(response => {
            //         this.selectedTemplate = null;
            //         this.demoModal = false;
            //         this.showSnackbar({ message: 'Template demo updated. Please allow time for render' });
            //     })
            //     .catch(error => {
            //         console.log(error, 'error');
            //         this.showSnackbar({ message: 'Error updating template demo', color: 'red' });
            //     })
            //     .finally(() => {
            //         this.loading = false;
            //     });
        },
        getModsJson() {
            let array = this.selectedTemplate.dynamicFields.filter(x => x.value);
            let dict = this.toDictionaryJson(array);
            return JSON.parse(dict);
        },
        toDictionaryJson(items) {
            const dictionary = {};
            for (const { creatomateId, value } of items) {
                if (creatomateId && creatomateId !== '') {
                    dictionary[creatomateId] = value || '';
                }
            }
            return JSON.stringify(dictionary);
        },
        startNewDemo(item) {
            this.demoModal = true;
            this.selectedTemplate = item;
            this.selectedTemplate.dynamicFields = this.selectedTemplate.dynamicElements.map(x => ({
                creatomateId: x.creatomateId,
                name: x.name,
                id: x.id,
                type: x.type,
                value: '',
            }));
        },
        submitImport(id) {
            this.loading = true;
            this.axiosInstance
                .post(`/TributeVideoTemplate/import/${id}`)
                .then(res => {
                    this.getTemplates();
                    this.showSnackbar({ message: 'Template imported' });
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error importing template', color: 'red' });
                })
                .finally(() => {
                    this.loading = false;
                    this.importId = '';
                    this.importModal = false;
                });
        },
        importStep1() {
            this.importModal = true;
        },
        closeDetails() {
            this.selectedTemplate = null;
            this.titleText = '';
            this.editingTitle = false;
            this.detailsModal = false;
            this.detailStep = 1;
        },
        toggleStaffFavorite(template) {
            var id = template.id;
            var val = !template.staffFavorite;

            template.staffFavorite = val;

            this.axiosInstance.post(`/TributeVideoTemplate/toggle-staff-favorite/${id}/${val}`).then(resp => {
                this.getTemplates();
            });
        },
        openDetails(item) {
            this.selectedTemplate = item;
            this.titleText = this.selectedTemplate.title;
            this.detailsModal = true;
        },
        saveTitle() {
            if (!this.titleText) {
                this.showSnackbar({ message: 'Title must have a value' });
                return;
            }

            if (!this.selectedTemplate?.id) {
                this.showSnackbar({ message: 'Invalid tempalte id' });
                return;
            }

            const id = this.selectedTemplate.id;
            let data = { title: this.titleText };

            this.axiosInstance
                .post(`TributeVideoTemplate/update-title/${id}`, data)
                .then(resp => {
                    this.closeDetails();
                    this.showSnackbar({ message: 'Title updated' });
                    this.getTemplates();
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error updating title', color: 'error' });
                });
        },
        getSortByValue(value) {
            switch (value) {
                case 'title':
                    return 'Title';
                case 'id':
                    return 'Id';
                case 'createDate':
                    return 'CreateDate';
                case 'updateDate':
                    return 'UpdateDate';
                case 'duration':
                    return 'Duration';
                case 'status':
                    return 'Status';
                case 'dynamicElements':
                    return 'DynamicElements';
                default:
                    console.warn('No cases found in switch statement.');
                    return '';
            }
        },
        getTemplates() {
            this.loading = true;

            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            var params = {
                pageSize: itemsPerPage,
                pageNumber: page ? page - 1 : 1,
                sortBy: sortBy && sortBy[0] ? this.getSortByValue(sortBy[0]) : null || null,
                sortAsc: sortBy && sortBy[0] ? !sortDesc[0] : null || null,
                search: this.search,
            };

            this.axiosInstance
                .get(`/TributeVideoTemplate`, { params: params })
                .then(res => {
                    this.templates = res.data.templates;
                    console.log(res.data.templates, 'res.data.templates');
                    this.totalTemplates = res.data.total;
                })
                .catch(error => {
                    console.log(error, 'error');
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        refreshTemplate(data) {
            const index = this.templates.findIndex(x => x.id === data.id);

            if (index !== -1) {
                this.templates.splice(index, 1, {
                    ...this.templates[index],
                    duration: data.duration,
                    status: data.status,
                    title: data.title,
                    updateDate: data.updateDate,
                    url: data.url,
                });
            }
        },
        updateTemplateStatus(status) {
            const templateId = this.selectedTemplate?.id;

            this.block(true);
            this.axiosInstance
                .post(`TributeVideoTemplate/update-status/${templateId}?status=${status}`)
                .then(() => {
                    this.selectedTemplate.tributeTemplateStatus = status;
                    this.showSnackbar({ message: 'Template status updated' });
                    this.getTemplates();
                })
                .catch(error => {
                    console.error(error);
                    this.showSnackbar({
                        message: 'Error updating template status',
                        color: 'error',
                    });
                })
                .finally(() => {
                    this.block(false);
                });
        },
        sendToProd() {
            const templateId = this.selectedTemplate?.id;
            if (!templateId) {
                this.showSnackbar({ message: 'Invalid template id', color: 'error' });
                return;
            }

            this.block(true);
            this.axiosInstance
                .post(`TributeVideoTemplate/export/send-to-prod/${templateId}`)
                .then(resp => {
                    if (resp.data) {
                        this.selectedTemplate.tributeTemplateStatus = resp.data.status;
                    }
                    this.getTemplates();
                    this.detailsModal = false;
                    this.showSnackbar({ message: 'Template sent to production' });
                })
                .catch(error => {
                    console.error(error);
                    this.showSnackbar({
                        message: 'Error sending template to production',
                        color: 'error',
                    });
                })
                .finally(() => {
                    this.block(false);
                });
        },
    },
    sockets: {
        NotifyTemplateRender(data) {
            this.refreshTemplate(data);
        },
    },
    async created() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.getTemplates();
    },
};
</script>
<style lang="scss" scoped>
video {
    max-height: 100%;
    max-width: 100%;
    display: block;
}

.list-item:hover {
    background-color: rgba(0, 0, 0, 0.04);
}
</style>
