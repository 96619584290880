<template>
    <div>
        <div class="action-bar">
            <div class="right-buttons">
                <button
                    class="icon-button"
                    @click="toggleHeight"
                    :style="{
                        borderTopRightRadius: '6px',
                        borderBottomRightRadius: '6px',
                    }"
                >
                    <svg
                        :style="{
                            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.5s',
                        }"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.16671 12.5L10 6.66667L15.8334 12.5"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>

                <button :class="['explore-button', { expanded: isExpanded }]" @click="toggleHeight">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.33331 4.16732C3.33331 3.70708 3.70641 3.33398 4.16665 3.33398H15.8333C16.2935 3.33398 16.6666 3.70708 16.6666 4.16732V5.83398C16.6666 6.29422 16.2935 6.66732 15.8333 6.66732H4.16665C3.70641 6.66732 3.33331 6.29422 3.33331 5.83398V4.16732Z"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M3.33331 10.834C3.33331 10.3737 3.70641 10.0007 4.16665 10.0007H9.16665C9.62688 10.0007 9.99998 10.3737 9.99998 10.834V15.834C9.99998 16.2942 9.62688 16.6673 9.16665 16.6673H4.16665C3.70641 16.6673 3.33331 16.2942 3.33331 15.834V10.834Z"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M13.3333 10.834C13.3333 10.3737 13.7064 10.0007 14.1666 10.0007H15.8333C16.2935 10.0007 16.6666 10.3737 16.6666 10.834V15.834C16.6666 16.2942 16.2935 16.6673 15.8333 16.6673H14.1666C13.7064 16.6673 13.3333 16.2942 13.3333 15.834V10.834Z"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    Explore Themes
                </button>
            </div>
        </div>

        <div :class="['slides-container', { expanded: isExpanded }]">
            <transition-group
                name="slide-animation"
                tag="div"
                :class="['selected-templates-wrapper', { expanded: isExpanded }]"
            >
                <ThemeCard
                    v-for="(template, index) in selectedTemplates"
                    :key="template.id"
                    :template="template"
                    :isSelected="true"
                    :onEdit="editTemplate"
                />
            </transition-group>
            <div :class="['divider-wrapper', { expanded: isExpanded }]">
                <span :class="['header-text', { expanded: isExpanded }]"
                    >DISCOVER THOUSANDS OF THEMES WITH ENDLESS OPTIONS TO EXPLORE</span
                >
                <div v-if="selectedTemplates.length > 0" :class="['divider', { expanded: isExpanded }]"></div>
            </div>

            <transition-group name="slide-animation" tag="div" :class="['templates-wrapper', { expanded: isExpanded }]">
                <ThemeCard
                    v-for="(template, index) in templates"
                    :key="template.id"
                    :template="template"
                    :isSelected="false"
                    :onSelect="() => quickSelectTemplate(tributeVideo.id, template.id)"
                    :onPreview="() => previewTemplate(template)"
                    :loading="loading"
                />
            </transition-group>
        </div>
    </div>
</template>

<script>
import TributeThemeService from '@/services/tributeTemplate.service';
import CrownIcon from '@/assets/icons/crownIcon.vue';
import { debounceV2 } from '@/utilities/debounce.js';
import ThemeCard from './ThemeCard.vue';
import { mapActions } from 'vuex';

export default {
    name: 'ManageThemes',
    components: {
        CrownIcon,
        ThemeCard,
    },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        tributeVideo: {
            type: Object,
            required: true,
        },
    },

    computed: {
        templateTextElements() {
            if (this.tempTemplate) {
                return this.tempTemplate.dynamicElements.filter(x => x.type == 0 && x.name != 'dash');
            }
            return [];
        },
        templateImageElements() {
            if (this.tempTemplate) {
                return this.tempTemplate.dynamicElements.filter(x => x.type == 1);
            }
            return [];
        },
        isExpanded: {
            get() {
                return this.$store.state.tributeEditor.expanded;
            },
        },
    },

    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', ['updateTributeVideo', 'updateTributeVideoSelectedTemplates']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        editTemplate(template) {
            this.tempTemplate = template;
            this.tempMods = this.getModifications(template.dynamicElements);
            this.getTemplateSource(template.creatomateId).then(source => {
                this.tempSource = source;
                this.showEditModal = true;
            });
        },
        async refreshSelectedTemplates() {
            if (this.tributeVideo.id) {
                var selectedTemps = await this.getSelectedTemplates(this.tributeVideo.id);
                console.log(selectedTemps);
                if (selectedTemps.templates) {
                    this.updateTributeVideoSelectedTemplates(selectedTemps.templates);
                }
            }
        },
        getModifications(array) {
            return array.reduce((dict, { creatomateId, value }) => {
                if (creatomateId) dict[creatomateId] = value || '';
                return dict;
            }, {});
        },
        updatePreviewMods: debounceV2(async function () {
            if (this.tempTemplate) {
                this.tempMods = this.getModifications(this.tempTemplate.dynamicElements);

                if (this.selectedTemplates.length > 0) {
                    if (this.selectedTemplates[0].id == this.tempTemplate.id) {
                        await this.saveTemplate(this.tempTemplate.dynamicElements);
                    }
                }
            }
        }, 1000),
        //Moving away from this flow, use quickSelectTemplate instead to let backend process changes
        //Use SelectTemplateWIthMods() instead

        // async selectTemplate(item) {
        //     this.tempTemplate = item;
        //     let dictionary = this.getModifications(item.dynamicElements.filter(x => x.value));

        //     let data = {
        //         id: this.tempTemplate.id,
        //         customFont: this.customFont,
        //         source: this.source,
        //         modifications: dictionary,
        //     };
        //     const response = await this.api.updateSelectedTemplates(this.tributeVideo.id, data);

        //     this.updateTributeVideoSelectedTemplates(response.data.templates);
        //     this.selectedTemplates = [this.tempTemplate];
        // },
        //Selects template and w/ service info autofilled
        async quickSelectTemplate(tributeVideoId, templateId) {
            try {
                this.loading = true;

                if (!tributeVideoId) throw new Error('Invalid tribute video id');

                if (!templateId) throw new Error('Invalid template id');

                var resp = await this.api.selectTemplate(tributeVideoId, templateId);
                if (resp.data) {
                    this.selectedTemplates = [resp.data];
                }

                this.$emit('refresh-preview');
            } catch {
                console.log(error, 'Error updating theme');
            } finally {
                this.loading = false;
            }
        },
        async selectTemplateWithMods(tributeVideoId, templateId, mods = null) {
            try {
                // TODO:  Update this to use values from preview fields
                //this method uses same endpoint as quickSelemtTemplate but includes with optional
                // customMods object
                // dynamicElement.creatomateId : value
                // custom mods  included will overwrite the default value,
                // fields not included will use use the template's default value  (no need to pass default values to backend)

                //example replaces first name in Salute template
                mods = {
                    'a781c8a7-61bc-4733-a7f0-1d845594217f': 'Modified',
                };

                this.loading = true;

                if (!tributeVideoId) throw new Error('Invalid tribute video id');

                if (!templateId) throw new Error('Invalid template id');

                var resp = await this.api.selectTemplate(tributeVideoId, templateId, mods);
                if (resp.data) {
                    this.selectedTemplates = [resp.data];
                }

                this.$emit('refresh-preview');
            } catch {
                console.log(error, 'Error updating theme');
            } finally {
                this.loading = false;
            }
        },
        // async getTemplateSource(creatomateId) {
        //     return this.axiosInstance
        //         .get(`/TributeVideoTemplate/template-source/${creatomateId}`)
        //         .then(resp => {
        //             console.log('GET TEMPLATE SOURCE - RESP DATA');
        //             console.log(resp.data.source);
        //             return resp.data.source;
        //         })
        //         .catch(err => {
        //             console.log(err, 'error');
        //             this.showSnackbar({ message: 'Error laoding template source', color: 'error' });
        //         });
        // },

        getTextElementValue({ name, defaultValue }) {
            const t = this.tributeVideo,
                y = d => this.$moment(d).format('YYYY');
            const result = {
                firstname: t.firstName,
                lastname: t.lastName,
                fullname: `${t.firstName} ${t.lastName}`,
                lifespan: this.$moment(t.deathDate).diff(this.$moment(t.birthDate), 'years'),
                birthdate: y(t.birthDate),
                deathyear: y(t.deathDate),
                deathdate: y(t.deathDate),
                dash: '-',
            }[name.toLowerCase()];
            return result !== undefined ? result : defaultValue;
        },

        previewTemplate(template) {
            console.log('do nothing');
            console.log(template, 'tempalte');
        },

        toggleHeight() {
            this.toggleExpandedState();
        },
    },

    async mounted() {
        const { __raw } = await this.$auth.getIdTokenClaims();
        this.token = __raw;
        this.api = TributeThemeService(this.token);

        if (this.eventId && this.tributeVideo.id) {
            const [selectedResponse, allResponse] = await Promise.all([
                this.api.getSelected(this.tributeVideo.id),
                this.api.getTemplates(this.eventId),
            ]);

            this.selectedTemplates = selectedResponse.data.templates || [];
            this.templates = allResponse.data.templates || [];
        }
    },
    data() {
        return {
            api: null,
            token: null,
            templates: [],
            selectedTemplates: [],
            modifications: null,
            showEditModal: false,
            tempTemplate: null,
            tempMods: {},
            tempSource: '',
            loading: false,
        };
    },
};
</script>

<style lang="scss" scoped>
.manage-slides {
    // position: fixed;
    // bottom: 10px;
    // left: 12px;
    // right: 10px;
    // width: 99%;
    height: 34vh;
    background: white;
    border-radius: 8px;
    padding: 8px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    transition: height 0.8s;
}

.manage-slides.expanded {
    max-height: 80vh;
}

.action-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 38px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.header-text.expanded {
    display: block;
    height: auto;
    margin: 0;
    text-align: left;
    font-family: 'inter';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.025em;
    text-align: center;
    text-decoration-skip-ink: none;
    color: #6b7280;
}

.header-text {
    display: none;
}

.divider {
    display: block;
    width: 2px;
    height: 84px;
    margin: auto 0;
    border: 1px solid #d1d5db;
}

.divider-wrapper {
    display: block;
    align-items: center;
    width: 100%;
    padding-top: 80px;
}

.divider.expanded {
    display: none;
}

.divider-wrapper.expanded {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 0;
    height: 20px;
}

.slides-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-top: 8px;
    height: 100%;
    transition: height 0.8s;
    scroll-snap-type: x mandatory;
}

.slides-container > * {
    scroll-snap-align: start;
}

.slides-container.expanded {
    flex-wrap: wrap;
    overflow-x: hidden;
    align-content: flex-start;
}

.templates-wrapper,
.selected-templates-wrapper {
    display: flex;
    gap: 16px;
    flex-wrap: nowrap;
    height: 250px;
    flex: 1 1 auto;
}

.templates-wrapper,
.selected-templates-wrapper {
    overflow-x: visible;
}

.slides-container:not(.expanded) {
    overflow-x: auto;
}

.templates-wrapper.expanded {
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    max-width: 100vw;
}

.right-buttons {
    display: flex;
    align-items: center;
}

.icon-button {
    margin-right: 8px;
}

.explore-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    width: 138px;
    background: white;
    color: #374151;
    border: 1px solid #d1d5db;
    height: 38px;
    border-radius: 6px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.icon-button {
    width: 38px;
    height: 38px;
    background: white;
    border: 1px solid #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.explore-button.expanded {
    display: none;
}

.selected-templates-wrapper.expanded {
    overflow-x: auto;
}
</style>
