import axios from 'axios';

const blobAxios = axios.create();

const BlobService = {
    upload(endpoint, file, config = {}) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            let fileData = file?.data ? file.data : file;
            reader.readAsArrayBuffer(fileData);
            reader.addEventListener('error', error => reject(error));
            reader.addEventListener('load', async () => {
                try {
                    const response = await blobAxios.put(endpoint, reader.result, {
                        headers: {
                            'Content-Type': file?.type ? file.type : '',
                            'x-ms-blob-type': 'BlockBlob',
                        },
                        ...config,
                    });
                    resolve(response.data);
                } catch (error) {
                    reject(error);
                }
            });
        });
    },

    // You can add more blob-related methods here if needed
};

export default BlobService;
