export default {
    namespaced: true,
    state: {
        currentTrackId: null,
        isPlaying: false,
        progress: 0,
        playInBackground: true
    },
    mutations: {
        playTrack(state, payload) {
            // console.log('MINIPLAYER STORE | playTrack', payload);
            state.currentTrackId = payload;
            state.isPlaying = true;
            state.progress = 0;
        },
        updateCurrentTrack(state, payload) {
            // console.log('MINIPLAYER STORE | updateCurrentTrack', payload);
            state.currentTrackId = payload;
            if (!payload) {
                state.isPlaying = false;
            }
        },
        updateIsPlaying(state, status) {
            // console.log('MINIPLAYER STORE | updateIsPlaying', status);
            state.isPlaying = status;
        },
        updateProgress(state, progress) {
            state.progress = progress;
        },
        updatePlayingInBackground(state, pibStatus) {
            state.playInBackground = pibStatus;
        },
        togglePlayingInBackground(state) {
            state.playInBackground = state.playInBackground;
        }
    },
    actions: {
        updateCurrentlyPlayingTrack({ commit }, songId) {
            commit('updateCurrentTrack', songId);
        },
        updatePlayingStatus({ commit }, status) {
            commit('updateIsPlaying', status);
        },
        clearCurrentTrack({ commit }) {
            commit('updateCurrentTrack', null);
        },
        playTrack({ commit }, songId) {
            commit('playTrack', songId);
        },
        updateProgress({ commit }, progress) {
            commit('updateProgress', progress);
        },
        updatePlayingInBackground({ commit }, pibStatus)  {
            commit('updatePlayingInBackground', pibStatus);
        }
    }
}
