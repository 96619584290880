<template>
    <div class="stepper-navigation">
        <div @click="$router.push(`/services/${$route.params.slug}`)" class="logo pointer">
            <img src="@/assets/tribute-logo.png" alt="Logo" />
        </div>
        <div class="steps-container">
            <div class="steps">
                <div
                    @click="$emit('change-step', index + 1)"
                    v-for="(step, index) in steps"
                    :key="index"
                    class="step pointer"
                >
                    <div class="step-bar" :class="{ active: index + 1 === currentStep }"></div>
                    <div class="step-number" :class="{ active: index + 1 === currentStep }">
                        {{ index + 1 }}
                    </div>
                    <div class="step-title" :class="{ active: index + 1 === currentStep }">
                        {{ step }}
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <v-menu offset-y v-model="sharingMenuOpen" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                    <button class="share-button" v-bind="attrs" v-on="on" :disabled="currentStep <= 1">
                        Share <span class="hidden-md-and-down">link for family</span>
                        <span class="divider"></span>
                        <span class="chevron">&#9662;</span>
                    </button>
                </template>
                <v-card>
                    <v-list class="sharing-menu">
                        <!-- Family Share -->
                        <v-list-item>
                            <v-list-item-title> Invite Family to Upload Media </v-list-item-title>
                            <v-list-item-subtitle>
                                Allow family members to contribute photos and videos for the tribute.
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-text-field
                                    outlined
                                    dense
                                    class="has-addon__right"
                                    prepend-inner-icon="mdi-account-multiple-outline"
                                    :value="familyShareLink"
                                    readonly
                                >
                                    <template slot="preppend">
                                        <i class="fa fa-user-friends"></i>
                                    </template>
                                    <v-btn
                                        depressed
                                        slot="append-outer"
                                        @click="copyToClipboard('Family share', familyShareLink)"
                                    >
                                        <v-icon left dark class="mr-1"> mdi-clipboard-outline </v-icon>
                                        Copy</v-btn
                                    >
                                </v-text-field>
                            </v-list-item-subtitle>
                        </v-list-item>
                        <!-- Contributors -->
                        <v-list-item>
                            <v-list-item-title> Contributors </v-list-item-title>
                            <v-list-item-subtitle>
                                Only allows uploading photos.<br />
                                Recommended for extended family and friends.
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-text-field
                                    outlined
                                    dense
                                    class="has-addon__right"
                                    prepend-inner-icon="mdi-account-group-outline"
                                    :value="contributorLink"
                                    readonly
                                >
                                    <v-btn
                                        depressed
                                        slot="append-outer"
                                        @click="copyToClipboard('Contributor', contributorLink)"
                                    >
                                        <v-icon left dark class="mr-1"> mdi-clipboard-outline </v-icon>
                                        Copy</v-btn
                                    >
                                </v-text-field>
                            </v-list-item-subtitle>
                        </v-list-item>
                        <hr class="m-2" />
                        <!-- Share Video -->
                        <v-list-item>
                            <v-list-item-title> Share Completed Tribute Video </v-list-item-title>
                            <v-list-item-subtitle>
                                Allow anyone with the link to view the tribute video.
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-text-field
                                    outlined
                                    dense
                                    class="has-addon__right"
                                    prepend-inner-icon="mdi-play-circle-outline"
                                    :value="completedVideoLink"
                                    readonly
                                >
                                    <v-btn
                                        depressed
                                        slot="append-outer"
                                        @click="copyToClipboard('Completed video', completedVideoLink)"
                                    >
                                        <v-icon left dark class="mr-1"> mdi-clipboard-outline </v-icon>
                                        Copy</v-btn
                                    >
                                </v-text-field>
                            </v-list-item-subtitle>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
            <button class="cancel-button">Cancel</button>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name: 'TributeStepper',
    props: {
        currentStep: {
            type: Number,
            required: true,
        },
        tributeVideo: {
            type: Object,
        },
    },
    data() {
        return {
            steps: ['Setup', 'Manage Slides', 'Set Theme', 'Add Music', 'Create'],
            sharingMenuOpen: false,
        };
    },
    computed: {
        familyShareLink() {
            if (this.$props.tributeVideo) {
                return this.$props.tributeVideo.familyLink;
            }
            return '...';
        },
        contributorLink() {
            if (this.$props.tributeVideo) {
                return this.$props.tributeVideo.storyContributeLink;
            }
            return '...';
        },
        completedVideoLink() {
            // TODO: Figure out where the completed video link lives
            return '...';
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        copyToClipboard(type, link) {
            // TODO: browser support for this is really good, but need to double check if a fallback is needed
            navigator.clipboard.writeText(link);
            this.showSnackbar({
                message: `${type} link copied.`,
            });
            this.sharingMenuOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.stepper-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    padding: 10px 20px;
    margin-bottom: 20px;
    box-shadow: 0px 1px 2px 0px #0000000f, 0px 1px 3px 0px #0000001a;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.logo img {
    width: 144.62px;
    height: 24px;
}

.steps-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.steps {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    max-width: 1200px;
}

.step {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    border-radius: 4px;
    padding: 8px;
}
.step:hover {
    background-color: #f8fafb;
}

.step-bar {
    width: 100px;
    height: 4px;
    background-color: #d1d5db;
    margin-bottom: 8px;
    border-radius: 6px;
}

.step-bar.active {
    background-color: #ea580c;
}

.step-number {
    font-size: 0.8rem;
    font-weight: bold;
    color: #d1d5db;
}

.step-number.active {
    color: #000;
}

.step-title {
    font-size: 0.9rem;
    text-align: left;
    color: #d1d5db;
}

.step-title.active {
    color: #000;
}

.actions {
    display: flex;
    gap: 10px;
}

.share-button {
    padding: 0.5rem 1rem;
    font-weight: 500;
    background-color: #fff;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    display: flex;
    align-items: center;
    gap: 8px;

    &:disabled {
        cursor: not-allowed;
        color: #d1d5db;
    }
}

.sharing-menu {
    padding: 1.5rem 0.5rem;
    display: flex;
    flex-direction: column;

    .v-list-item {
        display: inline-block;
        padding: 0.5rem;
    }

    .v-list-item__title {
        font-weight: 500;
    }

    .v-list-item__title,
    .v-list-item__subtitle {
        margin: 4px 0;
        line-height: 1.3;
    }

    ::v-deep {
        .v-input.has-addon__right {
            .v-input__slot {
                margin: 0;
            }

            .v-input__control {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                fieldset {
                    border-color: #ccc;
                    border-right: transparent;
                }
            }

            .v-input__append-outer {
                margin: 0 !important;

                button {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    border: 1px solid #ccc;
                    height: 40px;
                }
            }
        }
    }
}

.share-button .divider {
    width: 1px;
    height: 20px;
    background-color: #d1d5db;
    margin-left: 8px;
}

.cancel-button {
    padding: 0.4rem 1rem;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
    cursor: pointer;
}

.chevron {
    font-size: 1rem;
    color: #d1d5db;
}
</style>
