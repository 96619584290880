<template>
    <div>
        <slot name="activator" :on-click="openFileSelection">
            <label for="image" class="upload-button" @click="openFileSelection">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M2.667 10.667v.666c0 1.105.896 2 2 2h6.667c1.105 0 2-.895 2-2v-.666M10.667 5.333L8 2.667M8 2.667L5.333 5.333M8 2.667v8"
                        stroke="#6B7280"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
                <span>Upload</span>
            </label>
        </slot>

        <input type="file" id="uppy-file-input" style="display: none" />
    </div>
</template>
<script>
import Uppy from '@uppy/core';
import FileInput from '@uppy/file-input';
import { assign, each, omit } from 'lodash';
import initApiServices from '@/services/ApiInitializer';
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            uppy: null,
            apiService: null,
            tempFiles: [],
            uploadedBytes: 0,
            totalBytes: 0,
            anonPages: ['TributeUploadPage', 'TributeFamilyPage'],
        };
    },
    props: {
        maxFiles: {
            type: Number,
            default: 999,
        },
        videoAllowed: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        tempFiles: {
            handler(newVal) {
                this.$emit('change', newVal);
            },
            deep: true,
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        initUppy() {
            let allowedFileTypes = this.getAllowedFilesTypes();

            this.uppy = new Uppy({
                restrictions: {
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: this.maxFiles,
                    allowedFileTypes,
                },
            })
                .use(FileInput, {
                    target: '#uppy-file-input',
                    pretty: false,
                })
                .on('file-added', async file => {
                    this.tempFiles = [...this.tempFiles, file];
                    this.$emit('filesAdded');
                })
                .on('file-removed', async file => {
                    this.tempFiles = this.uppy.getFiles();
                })
                .on('restriction-failed', (file, error) => {
                    this.showSnackbar({ message: error.message, color: 'error' });
                });
        },
        openFileSelection() {
            const input = this.uppy.getPlugin('FileInput');
            if (input) {
                input.handleClick();
            }
        },
        reset() {
            this.uppy.cancelAll();
        },
        async initUpload(eventId) {
            if (!eventId || eventId <= 0) {
                throw new Error('Invalid eventId');
            }

            const files = this.tempFiles;

            if (files.length === 0) {
                throw new Error('No files found');
            }

            const cancelTokenSource = this.axios.CancelToken.source();

            try {
                this.loading = true;
                this.uploadedBytes = 0;
                this.totalBytes = files.reduce((acc, file) => acc + file.size, 0);

                const uploadResponse = await this.processFileBatch(eventId, files, cancelTokenSource.token);

                return uploadResponse;
            } catch (error) {
                console.error('Error uploading files', error);
            } finally {
                this.reset();
                this.loading = false;
            }
        },
        getAllowedFilesTypes() {
            let allowedFileTypes = [];

            if (!this.videoAllowed) {
                allowedFileTypes = ['.jpg', '.jpeg', '.png', '.svg', '.heic'];
            } else {
                allowedFileTypes = ['.jpg', '.jpeg', '.png', '.svg', '.mp4', '.mov', '.heic'];
            }

            return allowedFileTypes;
        },
        async processFileBatch(eventId, files, cancelToken) {
            try {
                const results = await Promise.allSettled(
                    files.map(file => this.processSingleFileAzureUpload(eventId, file, cancelToken)),
                );

                const successfullUploads = results
                    .filter(result => result.status === 'fulfilled')
                    .map(result => result.value);

                const response = await this.uploadDbPhotoBatch(eventId, successfullUploads, cancelToken);

                return response;
            } catch (error) {
                console.error('Error during batch file process', error);
            }
        },
        async processSingleFileAzureUpload(eventId, file, cancelToken) {
            try {
                const {
                    data: { sas, fileName },
                } = await this.getUploadUrl(eventId, file);

                await this.uploadToAzureStorage(sas, file, cancelToken);

                const fileData = await this.prepareFileData(file, sas, fileName);

                this.uploadedBytes += file.size;

                return fileData;
            } catch (error) {
                this.handleFileUploadError(file, error);
                throw error;
            }
        },
        handleFileUploadError(file, error) {
            if (error.message === 'Upload canceled by user.') {
                this.showSnackbar({ message: 'Upload cancelled', color: 'error' });
            } else {
                console.error(`Error uploading ${file.name}:`, error);
                this.showSnackbar({ message: `Error uploading ${file.name}`, color: 'error' });
            }
        },
        async prepareFileData(file, sas, fileName) {
            let uploadSource = 0;
            let uploadUserName = 'Unknown';
            let uploadUserRelationShip = 'Funeral Staff';

            if (this.anonPages.includes(this.$route.name)) {
                uploadSource = 2;
                uploadUserName = 'Unknown';
                uploadUserRelationShip = 'Unknown';
            } else if (this.$auth.user.name) {
                uploadUserName = this.$auth.user.name;
            }

            let fileData = {
                duration: 0,
                mediaType: 0,
                uploadSource: uploadSource,
                uploadUserName: uploadUserName,
                uploadUserRelationship: uploadUserRelationShip,
                url: sas.split('?sv=')[0],
                uniqueName: fileName,
                name: file.name,
            };

            let isVideo = this.isVideoFile(file.extension);

            if (isVideo) {
                fileData.duration = await this.getVideoDuration(file);
                fileData.mediaType = 1;
            }

            return fileData;
        },
        async uploadDbPhotoBatch(eventId, photoBatchData, cancelToken) {
            try {
                const config = {
                    cancelToken: cancelToken,
                };
                var resp = await this.apiService.tributePhoto.createPhotoBatch(eventId, photoBatchData, false, config);
                if (resp.data) {
                    return resp.data;
                }
            } catch (error) {
                console.log(error, 'error');
            }
        },
        async getUploadUrl(eventId, file) {
            try {
                return await this.apiService.tributePhoto.getUploadUrl(eventId, file);
            } catch (error) {
                console.log(error, 'error');
            }
        },
        async uploadToAzureStorage(sas, file, cancelToken) {
            await this.apiService.blob.upload(sas, file, {
                onUploadProgress: this.createProgressHandler(file),
                cancelToken: cancelToken,
            });
        },
        createProgressHandler(file) {
            return progressEvent => {
                const progressPayload = {
                    uploader: this,
                    bytesUploaded: progressEvent.loaded,
                    bytesTotal: progressEvent.total,
                    percentage: (progressEvent.loaded / progressEvent.total) * 100,
                    uploadComplete: progressEvent.total < progressEvent.loaded ? false : true,
                    uploadStarted: progressEvent.total > 0 ? true : false,
                };
                this.uppy.emit('upload-progress', file, progressPayload);
                // update the file's internal progress
                each(this.tempFiles, tmpfile => {
                    if (tmpfile.id === file.id) {
                        assign(tmpfile.progress, omit(progressPayload, ['uploader']));
                    }
                });
                const currentBytes = this.uploadedBytes + progressEvent.loaded;
                const percent = Math.ceil((currentBytes / this.totalBytes) * 100);

                this.$emit('progress', percent);
            };
        },
        isVideoFile(fileExtension) {
            const videoFileTypes = ['mp4', 'mov', 'avi', 'mkv', 'wmv', 'flv', 'webm'];

            // Check if the file extension is included in the videoFileTypes array
            return videoFileTypes.includes(fileExtension.toLowerCase());
        },
        getVideoDuration(file) {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.preload = 'metadata';

                video.onloadedmetadata = () => {
                    window.URL.revokeObjectURL(video.src);
                    const duration = video.duration;
                    resolve(Math.round(duration));
                };

                video.onerror = () => {
                    reject(new Error('Failed to retrieve video duration.'));
                };

                video.src = URL.createObjectURL(file.data);
            });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
    },
    async mounted() {
        this.initUppy();
        await this.setAuthToken();
        this.apiService = initApiServices(this.token);
    },
};
</script>
<style lang="scss" scoped>
.upload-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #d1d5db;
    background-color: #fff;
    color: #374151;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #0000000d;
}
</style>
