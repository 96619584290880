<template>
    <v-card>
        <v-card-title class="text-h5"> Super Admin Event Edit </v-card-title>

        <v-card-text v-if="loaded">
            <form class="super-container" @submit.prevent="handleFormSubmission">
                <v-text-field v-model="formData.id" label="Event ID" />
                <v-text-field v-model="formData.externalId" label="External ID" />
                <v-text-field v-model="formData.funeralHomeId" label="Funderal Home ID" />
                <v-text-field v-model="formData.serviceId" label="Service ID" />
                <v-text-field v-model="formData.ownerId" label="Owner ID" />
                <v-text-field v-model="formData.title" label="Title" />
                <v-text-field v-model="formData.duration" label="Duration" />

                <v-checkbox v-model="formData.private" label="Private - Do not use. Breaks video player" />
                <v-checkbox v-model="formData.live" label="Live" />

                <v-text-field v-model="formData.oldServiceId" label="Old Service ID" />
                <v-text-field v-model="formData.pin" label="Pin" />
                <v-checkbox v-model="formData.introVideo" label="Intro Video" />
                <v-checkbox v-model="formData.hidden" label="Hidden" />
                <v-text-field v-model="formData.order" label="Order" />

                <v-select
                    :items="eventItems"
                    item-text="label"
                    item-value="value"
                    label="Event Status"
                    v-model="formData.eventStatus"
                ></v-select>

                <calendar label="Time" datetime v-model="formData.time" />

                <calendar disabled label="Create Date" v-model="formData.createDate" />

                <v-text-field v-model="formData.eventType" label="Event Type" />
                <v-text-field v-model="formData.convertedVideo" label="Converted Video" />
                <v-text-field v-model="formData.liveURL" label="Live URL" />
                <v-text-field v-model="formData.liveStreamId" label="Live Stream ID" />
                <v-text-field v-model="formData.liveSecondsConsumed" label="Live Seconds Consumed" type="number" />
                <v-text-field v-model="formData.previewText" label="Preview Text" />
                <v-text-field v-model="formData.videoThumbnail" label="Video Thumbnail" />
                <v-text-field v-model="formData.videoQueType" label="Video Que Type" />
                <v-text-field v-model="formData.passareEventUuid" label="passareEventUuid" />

                <v-select
                    :items="streamIssues"
                    item-text="text"
                    item-value="value"
                    label="Success / Issue"
                    v-model="formData.issue"
                ></v-select>
                <v-select
                    item-text="text"
                    item-value="value"
                    :items="boolSelectOptions"
                    label="Stream Bad Health"
                    v-model="formData.problemAtStreamBadHealth"
                ></v-select>
                <v-select
                    item-text="text"
                    item-value="value"
                    :items="boolSelectOptions"
                    label="Stream Not Started"
                    v-model="formData.problemAtStreamNotStarted"
                ></v-select>

                <v-textarea v-model="formData.notes" label="Notes" />

                <div>
                    <v-text-field
                        label="Contact User"
                        :hint="landline ? 'Landline' : 'Mobile'"
                        persistent-hint
                        readonly
                        v-model="userInfo"
                    ></v-text-field>
                    <v-btn style="margin-top: 10px" block color="#0C3C60" dark large type="submit">Save</v-btn>
                </div>
            </form>
        </v-card-text>
    </v-card>
</template>

<script>
import Calendar from '@/components/ui/Calendar.vue';
import { eventStates, streamIssues } from '@/constants';

export default {
    props: {
        event: {
            type: Object,
            required: true,
        },
    },
    components: {
        Calendar,
    },
    data() {
        return {
            token: null,
            formData: {},
            loaded: false,
            eventItems: [...eventStates],
            streamIssues,
            userInfo: '',
            landline: false,
            boolSelectOptions: [
                { text: 'True', value: true },
                { text: 'False', value: false },
            ],
        };
    },
    methods: {
        /**
         * Veutify automatically takes text input and converst to string. This is not ideal
         * as a number needs to be a number type, not a string. This will go through and convert
         * any string that is a valid number into a number.
         */
        convertIntObj: function (obj) {
            {
                let res = {};
                Object.keys(obj).forEach(key => {
                    if (key === 'pin') {
                        res[key] = obj[key];
                    } else if (key === 'title') {
                        res[key] = obj[key].toString();
                    } else {
                        res[key] = !isNaN(parseFloat(obj[key])) && isFinite(obj[key]) ? parseInt(obj[key]) : obj[key];
                    }
                });
                return res;
            }
        },
        checkForUTC: function (timeString) {
            if (timeString.includes('Z')) return timeString;
            else return timeString + 'Z';
        },
        checkForLocal: function (timeString) {
            if (timeString.includes('Z')) return timeString.replace('Z', '');
            else return timeString;
        },
        handleFormSubmission: function () {
            let result = this.convertIntObj(this.formData);

            // // Convert Time to UTC  String
            // let eventTime = new Date(result.time);
            // let UTCEventTime = new Date(eventTime.getTime() - eventTime.getTimezoneOffset() * 60000).toISOString();
            // result.time = UTCEventTime;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .put(`${process.env.VUE_APP_API}/Events/admin-edit/${this.event.id}`, result)
                .then(response => {
                    this.$emit('close');
                })
                .catch(error => {});
        },
        getUser(id) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(`${process.env.VUE_APP_API}/users/${id}`)
                .then(response => {
                    const user = response.data;

                    if (user) {
                        this.landline = user.landLine && !user.landLineOverride;

                        this.userInfo = `${user.id}: ${user.firstName} ${user.lastName} - ${user.phoneNo}`;
                    }
                })
                .catch(error => {});
        },
    },
    mounted() {
        this.$auth.getIdTokenClaims().then(async result => {
            // Get Api Token
            this.token = result.__raw;

            this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(`${process.env.VUE_APP_API}/Events/admin-edit/${this.event.id}`)
                .then(async response => {
                    this.formData = { ...response.data };
                    this.formData.time = this.checkForUTC(response.data.time);
                    this.formData.createDate = this.checkForUTC(response.data.createDate);

                    if (response.data.userId > 0) {
                        this.userInfo = `${response.data.userId}:`;
                        await this.getUser(response.data.userId);
                    }

                    this.loaded = true;
                })
                .catch(error => {});
        });
    },
};
</script>

<style lang="scss" scoped>
.super-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
}
</style>
