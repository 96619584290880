<template>
    <div class="preview-component-container">
        <div class="preview-container" id="preview-container" :class="loading ? 'hidden-preview' : ''"></div>
        <div class="preview-overlay" :class="loading ? '' : 'hidden-preview'">
            <div v-if="loadError" class="d-flex flex-column align-items-center">
                <font-awesome-icon style="font-size: 2rem" icon="fa-regular fa-cloud-exclamation"></font-awesome-icon>
                <p class="mt-2">Error loading preview.</p>
            </div>
            <div v-else-if="!source" class="d-flex flex-column align-items-center">
                <font-awesome-icon style="font-size: 2rem" icon="fa-regular fa-cloud-exclamation"></font-awesome-icon>
                <p class="mt-2">Preview source not found.</p>
            </div>
            <div v-else class="d-flex flex-column align-items-center">
                <v-progress-circular indeterminate></v-progress-circular>
                <p class="mt-2">Loading preview...</p>
            </div>
        </div>
    </div>
</template>
<script>
import { Preview } from '@creatomate/preview';

export default {
    data() {
        return {
            preview: null,
            loading: true,
            loadError: false,
        };
    },
    props: {
        source: {
            type: String,
            default: null,
        },
        template: {
            type: Boolean,
            default: false,
        },
        modifications: {
            type: String,
            required: false,
            default: '',
        },
    },
    watch: {
        source() {
            if (this.source) {
                this.initPreview();
            }
        },
        async modifications() {
            if (this.source) {
                this.initPreview();
            }
        },
    },
    mounted() {
        if (this.source) {
            this.initPreview();
        }
    },
    methods: {
        reset() {
            this.loading = true;
        },
        async playVideo() {
            if (this.preview) {
                await this.preview.play();
            }
        },
        async pauseVideo() {
            if (this.preview) {
                await this.preview.pause();
            }
        },
        initPreview() {
            this.loading = true;

            const el = document.getElementById('preview-container');

            if (el) {
                const preview = new Preview(el, 'player', process.env.VUE_APP_CREATOMATE_KEY);
                this.preview = preview;

                // Once the SDK is ready, load a template from the project
                preview.onReady = async () => {
                    if (this.template) {
                        try {
                            await preview.loadTemplate(this.source);
                        } catch (error) {
                            this.loadError = true;

                            // console.log('template error', error.data);
                        }
                    } else {
                        const json = JSON.parse(this.source);
                        json.render_scale = 0.5;
                        console.log(json, 'source');
                        try {
                            await preview.setSource(json);
                        } catch (error) {
                            this.loadError = true;

                            console.log('source error');
                        }
                    }

                    if (this.modifications) {
                        const mods = JSON.parse(this.modifications);
                        // console.log(mods, 'mods');
                        try {
                            await preview.setModifications(mods);
                        } catch (error) {
                            console.log('modification error', error);
                        }
                    }

                    this.loading = false;
                };

                // preview.onLoad = () => {
                //     this.loading = true;
                // };

                // preview.onLoadComplete = () => {
                //     this.loading = false;
                // };
            }
        },
    },
};
</script>
<style lang="scss">
.preview-component-container {
    position: relative;
    aspect-ratio: 16/9;
    background-color: black;

    .hidden-preview {
        opacity: 0;
        z-index: -2;
    }
}

.preview-container {
    aspect-ratio: 16/9;
    opacity: 1;
    transition: 0.5s;
    // background-color: #000000c6;
}

.preview-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #00000000;
    color: white;
}
</style>
