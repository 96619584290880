<template>
    <div class="d-flex flex-column">
        <div class="action-bar">
            <div class="left-buttons"></div>
            <div class="right-buttons">
                <button
                    class="icon-button"
                    @click="toggleHeight"
                    :style="{
                        borderTopRightRadius: '6px',
                        borderBottomRightRadius: '6px',
                    }"
                >
                    <svg
                        :style="{
                            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.5s',
                        }"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.16671 12.5L10 6.66667L15.8334 12.5"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </div>
        <div style="height: 200px" class="d-flex flex-grow-1 justify-content-center align-items-center">
            <button @click="submitTributeRender" :disabled="loading || renderInProg" class="primary-button">
                Create Tribute Video Render
            </button>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import TributeVideoService from '@/services/tributeVideo.service';
export default {
    props: {
        tributeVideo: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        isExpanded: {
            get() {
                return this.$store.state.tributeEditor.expanded;
            },
        },
    },
    data() {
        return {
            apiInstance: null,
            loading: false,
            renderInProg: false,
        };
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        toggleHeight() {
            this.toggleExpandedState();
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        async submitTributeRender() {
            try {
                console.log(this.apiInstance, 'api instance');
                console.log(this.tributeVideo, 'tribute video');

                console.log('submit tribute render');

                if (!this.tributeVideo.id) {
                    this.showSnackbar({ message: 'Error submitting render', color: 'error' });
                    return;
                }

                this.loading = true;
                this.block(true);
                await this.apiInstance.submitRender(this.tributeVideo.id);

                this.showSnackbar({
                    message: 'Tribute Video Uploaded. Please allow time for processing',
                });
                // TODO: Fetch render status on page mount
                this.renderInProg = true;
                this.$emit('render-started');
            } catch (error) {
                const errorMessage = error.response.data.Message;
                if (errorMessage == 'Multipart render error') {
                    this.showSnackbar({
                        color: 'error',
                        message: 'Multipart render error, please try another template.',
                    });
                } else {
                    this.showSnackbar({ color: 'error', message: 'Error creating tribute video' });
                }
            } finally {
                this.loading = false;
                this.block(false);
            }
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.apiInstance = TributeVideoService(this.token);
    },
};
</script>
<style lang="scss" scoped>
.primary-button,
.secondary-button {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.primary-button {
    width: 228px;
    background: #ea580c;
    color: white;
    border: none;
    height: 38px;
    border-radius: 6px;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.secondary-button {
    width: 138px;
    background: white;
    color: #374151;
    border: 1px solid #d1d5db;
    height: 38px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.primary-button:disabled {
    opacity: 0.5;
}

.action-bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 38px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.right-buttons {
    display: flex;
    align-items: center;
}

.icon-button {
    margin-right: 8px;
    width: 38px;
    height: 38px;
    background: white;
    border: 1px solid #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}
</style>
