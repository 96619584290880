<template>
    <div>
        <div class="component">
            <div>
                <div class="envelope-wrapper" :style="computedStyle">
                    <div class="envelope" id="envelope" :style="computedStyle" @click="toggleCard(true)"></div>
                    <div class="letter elevation-2" :style="computedStyle">
                        <div class="letter-border"></div>
                        <div class="body">
                            <v-btn class="close-btn" @click="toggleCard(false)" fab text small>
                                <font-awesome-icon icon="fa-solid fa-x"></font-awesome-icon>
                            </v-btn>

                            <slot name="content"> </slot>
                        </div>
                    </div>
                    <!-- <div class="letter-shadow"></div> -->
                </div>
            </div>
        </div>
        <!-- 
        <div style="border: 2px solid lime" class="mt-8">
            <v-btn @click="toggleCard(true)">Open</v-btn>
            <v-btn @click="toggleCard(false)">Close</v-btn>
        </div> -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            isOpen: false,
            toggleLocked: false,
            envelopeWidth: 500,
            envelopeHeight: 280,
        };
    },
    watch: {
        isOpen(newVal) {
            this.$emit('is-open', newVal);
        },
    },
    computed: {
        computedStyle() {
            return {
                '--computed-width': Math.round(this.envelopeWidth) + 'px',
                '--computed-height': Math.round(this.envelopeHeight) + 'px',
                '--computed-letter-height': !this.isOpen ? Math.round(this.envelopeHeight) + 'px' : '120%',
                '--computed-letter-height-mobile': !this.isOpen ? Math.round(this.envelopeHeight) + 'px' : '200%',
                '--computed-half-width': Math.round(this.envelopeWidth / 2) + 'px',
                '--computed-half-height': Math.round(this.envelopeHeight / 2) + 'px',
                '--envelope-flap-height': Math.round(this.envelopeWidth / 2.9) + 'px',
            };
        },
    },
    methods: {
        toggleCard(val) {
            if (this.toggleLocked || this.isOpen == val) return;
            this.toggleLocked = true;

            const envelope = document.querySelector('.envelope');
            const letter = document.querySelector('.letter');
            const shadow = document.querySelector('.letter-shadow');

            if (val) {
                // Opening the envelope
                envelope.classList.add('opened');
                envelope.classList.remove('closed');

                setTimeout(() => {
                    envelope.classList.add('flap-behind');
                    letter.classList.add('in-front');

                    this.applyTransform(letter, 'translateY(-250px) scale(0.5)');

                    setTimeout(() => {
                        // this.applyTransform(letter, 'translateY(0px) scale(1)');
                        // this.applyTransform(shadow, 'translateY(40px) scaleX(1)');

                        letter.classList.add('expanded');
                        this.toggleLocked = false;
                        this.isOpen = val;
                    }, 500);
                }, 500);
            } else {
                // Closing the envelope
                // this.applyTransform(letter, 'translateY(-250px) scale(0.5)');
                // this.applyTransform(shadow, 'scaleX(0.5)');
                letter.classList.remove('expanded');

                letter.classList.remove('in-front');
                setTimeout(() => {
                    this.applyTransform(letter, 'translateY(0px) scale(0.5)');
                    this.isOpen = val;
                    setTimeout(() => {
                        envelope.classList.remove('flap-behind');
                        envelope.classList.remove('opened');
                        envelope.classList.add('closed');

                        this.toggleLocked = false;
                    }, 500);
                }, 500);
            }
        },
        applyTransform(el, transformString) {
            if (el) {
                el.style.transform = transformString;
                el.style.transition = 'transform 0.5s ease-in-out';
            }
        },
        initResizeObserver() {
            const container = document.getElementById('envelope');

            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    // console.log('Element has been resized: ', entry.target);
                    // console.log('New size:', entry.contentRect.width, 'x', entry.contentRect.height);
                    this.envelopeWidth = entry.contentRect.width;
                    this.envelopeHeight = entry.contentRect.height;
                }
            });

            resizeObserver.observe(container);
        },
    },
    mounted() {
        this.initResizeObserver();
    },
};
</script>
<style lang="scss" scoped>
$base: #006da3;
$base-light: #0077b2;
$base-dark: #0f578b;
$shadow: #255e86;
$letter: white;

.component {
    position: relative;
    text-align: left;
    -webkit-font-smoothing: antialiased; /* Chrome, Safari */
    -moz-osx-font-smoothing: grayscale; /* Firefox */
}

.envelope-wrapper {
    position: relative;
    aspect-ratio: 16/9;
    // border: 2px solid red;
}
.letter-shadow {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: scale(0.5);
    height: 1px;
    background: transparent;
    border-color: transparent;
    border-radius: 30%;
    box-shadow: rgb(238, 238, 243) 0px 20px 10px 5px;
}

.letter {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    transform: scale(0.5);
    padding: 20px;
    overflow: auto;
    height: var(--computed-letter-height);
    transition: 0.5s ease-in-out !important;
    @include center-by-all-means;
    z-index: 15;
    background: $letter;
    .body {
        position: absolute;
        top: 20px;
        left: 0;
        width: 100%;
        height: calc(100% - 20px);
    }

    .letter-border {
        position: absolute;
        top: 0;
        width: 100%;
        height: 20px;
        background: repeating-linear-gradient(-45deg, #cb5a5e, #cb5a5e 8px, transparent 8px, transparent 18px);
    }
}

.envelope {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    // max-width: 500px;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    background-color: $base-light;
    cursor: pointer;
}

.envelope::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: var(--computed-width);
    height: 0;
    border-top: var(--envelope-flap-height) solid $base-dark;
    border-left: var(--computed-half-width) solid transparent;
    border-right: var(--computed-half-width) solid transparent;
    box-sizing: border-box;
    z-index: 30;
    transform-origin: top;
}

.envelope::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    // width: 500px;
    // height: 280px;
    width: var(--computed-width);
    height: var(--computed-height);
    z-index: 25;
    background:
        //bottom-right
        linear-gradient(30deg, $base 47%, $shadow 50%, $base 50%) var(--computed-half-width) var(--computed-half-height) /
            var(--computed-half-width) var(--computed-half-height) no-repeat,
        // //top-left
        linear-gradient(31deg, $base 49%, $shadow 50%, transparent 50%) 0px 0px / var(--computed-half-width)
            var(--computed-half-height) no-repeat,
        // //bottom-left
        linear-gradient(150deg, $base 50%, $shadow 50%, $base 53%) 0px var(--computed-half-height) /
            var(--computed-half-width) var(--computed-half-height) no-repeat,
        // //top-right
        linear-gradient(148.7deg, transparent 50%, $shadow 50%, $base 51%) var(--computed-half-width) 0px /
            var(--computed-half-width) var(--computed-half-height) no-repeat;
}

.close-btn {
    position: absolute;
    top: 15px;
    right: 5px;
    z-index: 50;
}

/* Animation for the envelope flap */
@keyframes flapOpen {
    from {
        transform: rotateX(0deg);
    }
    to {
        transform: rotateX(180deg);
    }
}

@keyframes flapClose {
    from {
        transform: rotateX(180deg);
    }
    to {
        transform: rotateX(0deg);
    }
}

/* Envelope flap transition*/
.envelope.opened::before {
    animation: flapOpen 0.5s forwards;
    // z-index: 10;
}

.envelope.closed::before {
    animation: flapClose 0.5s forwards;
}

/* Envelope flap transition*/
.envelope.flap-behind::before {
    z-index: 10;
}

/* Letter transition */
.letter.raise {
    transform: translateY(-200px);
    transition: transform 0.5s ease-in-out;
}

.letter.lower {
    transform: translateY(0);
    transition: transform 0.5s ease-in-out;
}

.letter.expanded {
    position: fixed;
    top: 0;
    width: 55%;
    max-height: 80vh;
    transform: translateY(0) scale(1) !important;
}

.letter.in-front {
    z-index: 40;
}

@media only screen and (max-width: 600px) {
    .letter {
        height: var(--computed-letter-height-mobile);
    }

    .letter.expanded {
        position: fixed;
        top: 0;
        width: 90%;
        max-height: 80vh;
        transform: translateY(0) scale(1);
    }
}
</style>
