<template>
    <AppLayout :has-background="true" :contained="true">
        <div class="bg-white p-4 rounded elevation-1">
            <h4 class="mt-2 mb-4">Video Uploader</h4>

            <div v-if="uppy" style="background: white">
                <v-stepper v-model="currentTab" elevation="0">
                    <v-stepper-header>
                        <v-stepper-step color="#ff530d" :complete="currentTab > 1" step="1">
                            Upload Videos
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step color="#ff530d" :complete="currentTab > 2" step="2">
                            Set Video Order
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step color="#ff530d" :complete="currentTab > 3" step="3">
                            Select Start Time
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step color="#ff530d" :complete="currentTab > 4" step="4">
                            Select End Time
                        </v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step color="#ff530d" step="5">
                            {{ this.uploaderType == 'edit' ? 'Save Changes' : 'Upload' }}
                        </v-stepper-step>
                    </v-stepper-header>
                </v-stepper>

                <div
                    v-if="uploaderType == 'edit' && importType != null && video.archiveFilePath"
                    class="ml-8"
                    style="gap: 12px"
                >
                    <!-- <v-chip @click="importSourceModal = true" dark :color="importTypes[importType].color" label
                        >Source: {{ importTypes[importType].label }}</v-chip
                    > -->
                    <div v-if="importType == 0">
                        <div class="text-caption mb-1">Missing footage?</div>

                        <v-btn color="primary" dark small @click="archiveImportModal = true" depressed>
                            <font-awesome-icon icon="fa-solid fa-clock-rotate-left" class="mr-2"></font-awesome-icon>
                            <span> Restore Original File{{ archiveFiles.length == 1 ? '' : 's' }} </span>
                        </v-btn>
                    </div>
                    <v-btn small v-if="importType == 1" @click="importCurrentVideos" depressed>Use Current Video</v-btn>
                </div>

                <v-stepper-items>
                    <!-- START: SECTION 0 -->
                    <section v-show="currentTab === 1" step="1">
                        <div
                            class="video-uploader-drag-drop-zone"
                            v-show="tempFiles.length === 0"
                            style="padding: 2rem"
                        >
                            <drag-drop
                                :uppy="uppy"
                                :props="{
                                    restrictions: {
                                        allowedFileTypes: ['.mp4', '.mov'],
                                    },
                                }"
                            />
                            <div class="uploader-info-content">
                                <h2>Drop video files here</h2>
                                <p>or</p>
                                <span class="fake-btn elevation-4">CLICK TO UPLOAD</span>
                                <!-- <v-btn class="w-25" color="secondary" @click="uploadFileButton" style="cursor: pointer"
                                    >Click to Upload</v-btn
                                > -->
                            </div>
                        </div>

                        <div @drop.prevent="handleDrop" @dragover.prevent class="video-uploader-preview">
                            <div v-if="tempFiles.length > 0 && currentTab === 1" class="uploader-info-content">
                                <h2>Preview</h2>

                                <draggable v-model="tempFiles" ghost-class="vid-container-chosen">
                                    <transition-group class="video-uploader-preview-grid" name="list">
                                        <div
                                            class="video-uploader-preview-grid-vid-container"
                                            v-for="(video, index) in tempFiles"
                                            :key="video.id"
                                        >
                                            <!-- <div
                                                class="video-uploader-preview-grid-vid-container-x"
                                                @click="removeFile(video)"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-regular fa-x"
                                                    style="font-size: 0.7rem"
                                                ></font-awesome-icon>
                                            </div> -->
                                            <div class="video-player-container">
                                                <styled-videojs-player
                                                    aspect-ratio="16/9"
                                                    class="video-uploader-preview-grid-vid-container-video-player"
                                                    hide-play-btn-on-play
                                                    :src="getFileURL(video)"
                                                ></styled-videojs-player>
                                            </div>
                                            <div class="d-flex">
                                                <p class="flex-grow-1 ma-2 ml-8 text-center text-truncate">
                                                    {{ video.name }}
                                                </p>

                                                <div
                                                    @click="removeFile(video)"
                                                    class="d-flex align-center justify-center pointer video-uploader-preview-grid-vid-container-delete"
                                                >
                                                    <font-awesome-icon
                                                        class="ma-2"
                                                        icon="fa-solid fa-trash"
                                                        style="font-size: 1rem"
                                                    ></font-awesome-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </transition-group>
                                </draggable>

                                <div class="video-uploader-preview-button-container">
                                    <v-btn @click="uploadFileButton">
                                        <font-awesome-icon icon="fa-regular fa-plus" class="mr-2"></font-awesome-icon>
                                        Add More Videos</v-btn
                                    >
                                    <v-btn color="#ff530d" style="color: white" @click="moveToSortVideos"
                                        >Next
                                        <font-awesome-icon
                                            icon="fa-solid fa-arrow-right"
                                            style="font-size: 1rem; margin-left: 0.25rem"
                                    /></v-btn>
                                </div>
                            </div>
                        </div>
                    </section>
                    <!-- END: SECTION 0 -->

                    <!-- START: SECTION 1 -->
                    <div
                        @drop.prevent="handleDrop"
                        @dragover.prevent
                        class="video-uploader-preview"
                        v-if="currentTab === 2"
                        step="2"
                    >
                        <div class="uploader-info-content">
                            <h2>Sort Order</h2>

                            <draggable v-model="tempFiles" ghost-class="vid-container-chosen">
                                <transition-group class="video-uploader-preview-grid" name="list">
                                    <div
                                        class="video-uploader-preview-grid-vid-container"
                                        v-for="(video, index) in tempFiles"
                                        :key="video.id"
                                    >
                                        <div class="video-uploader-preview-grid-vid-container-number elevation-3">
                                            {{ index + 1 }}
                                        </div>
                                        <!-- <div
                                            class="video-uploader-preview-grid-vid-container-x"
                                            @click="removeFile(video)"
                                        >
                                            <font-awesome-icon
                                                icon="fa-regular fa-x"
                                                style="font-size: 0.7rem"
                                            ></font-awesome-icon>
                                        </div> -->
                                        <div class="video-player-container">
                                            <styled-videojs-player
                                                class="video-uploader-preview-grid-vid-container-video-player"
                                                aspect-ratio="16/9"
                                                :src="getFileURL(video)"
                                                hide-play-btn-on-play
                                            ></styled-videojs-player>
                                        </div>
                                        <div class="d-flex">
                                            <p class="flex-grow-1 ma-2 ml-8 text-center text-truncate">
                                                {{ video.name }}
                                            </p>

                                            <div
                                                @click="removeFile(video)"
                                                class="d-flex align-center justify-center pointer video-uploader-preview-grid-vid-container-delete"
                                            >
                                                <font-awesome-icon
                                                    class="ma-2"
                                                    icon="fa-solid fa-trash"
                                                    style="font-size: 1rem"
                                                ></font-awesome-icon>
                                            </div>
                                        </div>
                                    </div>
                                </transition-group>
                            </draggable>

                            <div class="video-uploader-preview-button-container">
                                <v-btn @click="currentTab = 1">
                                    <font-awesome-icon icon="fa-regular fa-arrow-left" class="mr-2"></font-awesome-icon>
                                    Add More Videos</v-btn
                                >
                                <v-btn color="#ff530d" style="color: white" @click="moveToSetStartTime"
                                    >Set Start Time
                                    <font-awesome-icon icon="fa-solid fa-arrow-right" style="margin-left: 0.25rem"
                                /></v-btn>
                            </div>
                        </div>
                    </div>
                    <!-- END: SECTION 1 -->

                    <!-- START: SECTION 2 -->
                    <div class="video-uploader-start-time" v-show="currentTab === 3" step="3">
                        <div class="uploader-info-content">
                            <div>
                                <h2 style="margin-bottom: 3rem">Set Start Time</h2>
                            </div>
                            <section>
                                <v-row justify="center">
                                    <v-col>
                                        <div
                                            class="video-uploader-start-time-video d-flex justify-center align-center"
                                            v-show="startURL"
                                        >
                                            <div class="time-preview-player-container">
                                                <styled-videojs-player
                                                    aspect-ratio="16/9"
                                                    ref="startTimeVideoPlayer"
                                                    controls-on-mount
                                                    v-if="startURL"
                                                    :src="startURL"
                                                    @timeupdate="updateStartTimestamp()"
                                                    @play="console.log('start playing')"
                                                    hide-play-btn-on-play
                                                ></styled-videojs-player>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col class="d-flex justify-center">
                                        <div class="video-uploader-start-time-content" v-if="startURL">
                                            <h5 class="text-truncate" v-if="tempFiles[0]">{{ tempFiles[0].name }}</h5>
                                            <v-row
                                                style="
                                                    background: #f2f2f2;
                                                    width: 100%;
                                                    padding: 0 1rem;
                                                    margin: 0;
                                                    flex: 0;
                                                "
                                            >
                                                <v-col>
                                                    <v-text-field readonly v-model="startTime.hours" label="Hours" />
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        readonly
                                                        v-model="startTime.minutes"
                                                        label="Minutes"
                                                    />
                                                </v-col>
                                                <v-col>
                                                    <v-text-field
                                                        readonly
                                                        v-model="startTime.seconds"
                                                        label="Seconds"
                                                    />
                                                </v-col>
                                            </v-row>

                                            <div class="video-uploader-start-time-video-control-container">
                                                <custom-tooltip :tooltipProps="{ bottom: true }">
                                                    <template v-slot:activator>
                                                        <div
                                                            class="control-button"
                                                            @click.stop="
                                                                $refs.startTimeVideoPlayer.setCurrentTime(
                                                                    $refs.startTimeVideoPlayer.currentTime - 5,
                                                                )
                                                            "
                                                        >
                                                            <font-awesome-icon icon="fa-solid fa-chevrons-left" />
                                                        </div>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span> 5 Seconds</span>
                                                    </template>
                                                </custom-tooltip>

                                                <custom-tooltip :tooltipProps="{ bottom: true }">
                                                    <template v-slot:activator>
                                                        <div
                                                            class="control-button"
                                                            @click.stop="
                                                                $refs.startTimeVideoPlayer.setCurrentTime(
                                                                    $refs.startTimeVideoPlayer.currentTime - 1,
                                                                )
                                                            "
                                                        >
                                                            <font-awesome-icon icon="fa-solid fa-chevron-left" />
                                                        </div>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span> 1 Second</span>
                                                    </template>
                                                </custom-tooltip>

                                                <custom-tooltip :tooltipProps="{ bottom: true }">
                                                    <template v-slot:activator>
                                                        <div
                                                            class="control-button"
                                                            @click.stop="
                                                                $refs.startTimeVideoPlayer.paused
                                                                    ? $refs.startTimeVideoPlayer.play()
                                                                    : $refs.startTimeVideoPlayer.pause()
                                                            "
                                                        >
                                                            <font-awesome-icon
                                                                v-if="$refs.startTimeVideoPlayer.paused"
                                                                icon="fa-solid fa-play"
                                                            />
                                                            <font-awesome-icon v-else icon="fa-solid fa-pause" />
                                                        </div>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span>{{
                                                            $refs.startTimeVideoPlayer.paused ? 'Play' : 'Pause'
                                                        }}</span>
                                                    </template>
                                                </custom-tooltip>

                                                <custom-tooltip :tooltipProps="{ bottom: true }">
                                                    <template v-slot:activator>
                                                        <div
                                                            class="control-button"
                                                            @click.stop="
                                                                $refs.startTimeVideoPlayer.setCurrentTime(
                                                                    $refs.startTimeVideoPlayer.currentTime + 1,
                                                                )
                                                            "
                                                        >
                                                            <font-awesome-icon icon="fa-solid fa-chevron-right" />
                                                        </div>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span> 1 Second</span>
                                                    </template>
                                                </custom-tooltip>

                                                <custom-tooltip :tooltipProps="{ bottom: true }">
                                                    <template v-slot:activator>
                                                        <div
                                                            class="control-button"
                                                            @click.stop="
                                                                $refs.startTimeVideoPlayer.setCurrentTime(
                                                                    $refs.startTimeVideoPlayer.currentTime + 5,
                                                                )
                                                            "
                                                        >
                                                            <font-awesome-icon icon="fa-solid fa-chevrons-right" />
                                                        </div>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span> 5 Seconds</span>
                                                    </template>
                                                </custom-tooltip>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </section>
                            <div class="video-uploader-start-time-button-container">
                                <v-btn @click="moveToVideoSelection"
                                    ><font-awesome-icon
                                        icon="fa-solid fa-arrow-left"
                                        style="font-size: 1rem; margin-right: 0.25rem"
                                    />
                                    Back to Video Selection
                                </v-btn>
                                <v-btn color="#ff530d" style="color: white" @click="moveToSetEndTime"
                                    >Set End Time
                                    <font-awesome-icon
                                        icon="font-solid fa-arrow-right"
                                        style="margin-left: 0.25rem; font-size: 1rem"
                                    />
                                </v-btn>
                            </div>
                        </div>
                    </div>
                    <!-- END: SECTION 2 -->

                    <!-- START: SECTION 3 -->
                    <div class="video-uploader-start-time" v-show="currentTab === 4" step="4">
                        <div class="uploader-info-content">
                            <div>
                                <h2 style="margin-bottom: 3rem">Set End Time</h2>
                            </div>
                            <section>
                                <v-row justify="center">
                                    <v-col order="1">
                                        <div
                                            class="video-uploader-start-time-video d-flex justify-center"
                                            v-show="endURL"
                                        >
                                            <div class="time-preview-player-container">
                                                <styled-videojs-player
                                                    aspect-ratio="16/9"
                                                    ref="endTimeVideoPlayer"
                                                    controls-on-mount
                                                    hide-play-btn-on-play
                                                    v-if="endURL"
                                                    :src="endURL"
                                                    @timeupdate="updateEndTimestamp()"
                                                    @play="console.log('end playing')"
                                                ></styled-videojs-player>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col class="d-flex justify-center" order="0">
                                        <div class="video-uploader-start-time-content" v-if="endURL">
                                            <h5 class="text-truncate">{{ tempFiles[tempFiles.length - 1].name }}</h5>
                                            <v-row
                                                style="
                                                    background: #f2f2f2;
                                                    width: 100%;
                                                    padding: 0 1rem;
                                                    margin: 0;
                                                    flex: 0;
                                                "
                                            >
                                                <v-col>
                                                    <v-text-field readonly v-model="endTime.hours" label="Hours" />
                                                </v-col>
                                                <v-col>
                                                    <v-text-field readonly v-model="endTime.minutes" label="Minutes" />
                                                </v-col>
                                                <v-col>
                                                    <v-text-field readonly v-model="endTime.seconds" label="Seconds" />
                                                </v-col>
                                            </v-row>

                                            <div class="video-uploader-start-time-video-control-container">
                                                <custom-tooltip :tooltipProps="{ bottom: true }">
                                                    <template v-slot:activator>
                                                        <div
                                                            class="control-button"
                                                            @click.stop="
                                                                $refs.endTimeVideoPlayer.setCurrentTime(
                                                                    $refs.endTimeVideoPlayer.currentTime - 5,
                                                                )
                                                            "
                                                        >
                                                            <font-awesome-icon icon="fa-solid fa-chevrons-left" />
                                                        </div>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span> 5 Seconds</span>
                                                    </template>
                                                </custom-tooltip>

                                                <custom-tooltip :tooltipProps="{ bottom: true }">
                                                    <template v-slot:activator>
                                                        <div
                                                            class="control-button"
                                                            @click.stop="
                                                                $refs.endTimeVideoPlayer.setCurrentTime(
                                                                    $refs.endTimeVideoPlayer.currentTime - 1,
                                                                )
                                                            "
                                                        >
                                                            <font-awesome-icon icon="fa-solid fa-chevron-left" />
                                                        </div>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span> 1 Second</span>
                                                    </template>
                                                </custom-tooltip>

                                                <custom-tooltip :tooltipProps="{ bottom: true }">
                                                    <template v-slot:activator>
                                                        <div
                                                            class="control-button"
                                                            @click.stop="
                                                                $refs.endTimeVideoPlayer.paused
                                                                    ? $refs.endTimeVideoPlayer.play()
                                                                    : $refs.endTimeVideoPlayer.pause()
                                                            "
                                                        >
                                                            <font-awesome-icon
                                                                v-if="$refs.endTimeVideoPlayer.paused"
                                                                icon="fa-solid fa-play"
                                                            />
                                                            <font-awesome-icon v-else icon="fa-solid fa-pause" />
                                                        </div>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span>Play</span>
                                                    </template>
                                                </custom-tooltip>

                                                <custom-tooltip :tooltipProps="{ bottom: true }">
                                                    <template v-slot:activator>
                                                        <div
                                                            class="control-button"
                                                            @click.stop="
                                                                $refs.endTimeVideoPlayer.setCurrentTime(
                                                                    $refs.endTimeVideoPlayer.currentTime + 1,
                                                                )
                                                            "
                                                        >
                                                            <font-awesome-icon icon="fa-solid fa-chevron-right" />
                                                        </div>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span> 1 Second</span>
                                                    </template>
                                                </custom-tooltip>

                                                <custom-tooltip :tooltipProps="{ bottom: true }">
                                                    <template v-slot:activator>
                                                        <div
                                                            class="control-button"
                                                            @click.stop="
                                                                $refs.endTimeVideoPlayer.setCurrentTime(
                                                                    $refs.endTimeVideoPlayer.currentTime + 5,
                                                                )
                                                            "
                                                        >
                                                            <font-awesome-icon icon="fa-solid fa-chevrons-right" />
                                                        </div>
                                                    </template>

                                                    <template v-slot:content>
                                                        <span> 5 Seconds</span>
                                                    </template>
                                                </custom-tooltip>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </section>
                            <div class="video-uploader-start-time-button-container">
                                <v-btn @click="moveToSetStartTime">
                                    <font-awesome-icon
                                        icon="fa-solid fa-arrow-left"
                                        style="margin-right: 0.25rem; font-size: 1rem"
                                    />Back to Set Start Time</v-btn
                                >
                                <v-btn color="#ff530d" style="color: white" @click="moveToUpload"
                                    >{{ this.uploaderType == 'edit' ? 'Save Changes' : 'Upload' }}
                                    <font-awesome-icon
                                        class="ml-2"
                                        icon="fa-solid fa-arrow-right"
                                        style="margin-right: 0.25rem; font-size: 1rem"
                                /></v-btn>
                            </div>
                        </div>
                    </div>
                    <!-- END: SECTION 3 -->

                    <!-- START: SECTION 4 -->
                    <div class="video-uploader-preview" v-if="currentTab === 5" step="5">
                        <div class="uploader-info-content" style="padding: 4rem 0" v-show="!videosAreRendering">
                            <h2 style="text-align: center">Uploading ...</h2>
                            <p style="text-align: center"><strong>PLEASE DO NOT CLOSE THIS PAGE</strong></p>

                            <div class="text-center">
                                <p class="my-2 text-caption">{{ totalUploadProgress }}%</p>
                                <v-progress-linear
                                    class="my-2"
                                    v-model="totalUploadProgress"
                                    background-color="#FFBFA5"
                                    color="#FF530D"
                                    height="20"
                                ></v-progress-linear>
                            </div>
                            <p class="text-caption text-center">
                                Video:
                                {{
                                    currentVideoProcess < this.tempFiles.length
                                        ? currentVideoProcess + 1
                                        : currentVideoProcess
                                }}
                                / {{ this.tempFiles.length }}
                            </p>
                        </div>

                        <div class="uploader-info-content" style="padding: 4rem 0" v-show="videosAreRendering">
                            <div class="loading-container">
                                <div class="loading"><i></i><i></i><i></i><i></i></div>
                                <h5>Upload finished, moving file to render process</h5>
                            </div>
                        </div>
                    </div>
                    <!-- END: SECTION 4 -->
                </v-stepper-items>
            </div>
        </div>

        <v-dialog persistent v-model="displayOverwriteWarning" max-width="350px">
            <v-card>
                <v-card-title>Overwrite Warning</v-card-title>
                <v-card-text>This upload will overwrite previous videos for this event.</v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="$router.back()">back</v-btn>

                    <v-btn depressed @click="displayOverwriteWarning = false" color="error">continue</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="importSourceModal" max-width="500px">
            <v-card>
                <v-card-title>Import Edit Source</v-card-title>
                <v-card-text>
                    <p>Would you like to edit from the current video or from the restored original version?</p>
                    <p>
                        Please note, the 'current version' refers to the video currently in the player, which may have
                        undergone previous edits. The 'original version' is the first upload, preserved in our archives
                        without any modifications.
                    </p>
                </v-card-text>

                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="importArchiveVideos" large depressed>Restored Original</v-btn>
                    <v-btn @click="importCurrentVideos" large depressed>Current Video</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="archiveImportModal" max-width="600px">
            <v-card>
                <v-card-title>Restore Original File{{ archiveFiles.length == 1 ? '' : 's' }}?</v-card-title>
                <v-card-text>
                    <div v-if="video" class="text-caption">
                        Original Files Uploaded on {{ $moment.utc(video.createDate).local().format('l') }}
                    </div>

                    <div v-if="archiveFiles.length > 0">
                        <v-window class="my-3" v-model="archivePreviewIndex">
                            <v-window-item v-for="(url, index) in archiveFiles" :key="`preview-${index}`">
                                <div v-if="archiveImportModal" class="video-player-container">
                                    <styled-videojs-player :src="url"></styled-videojs-player>
                                </div>
                            </v-window-item>
                        </v-window>

                        <div v-if="archiveFiles.length > 1" class="d-flex justify-space-between">
                            <v-btn :disabled="archivePreviewIndex == 0" @click="prevPreview">
                                <font-awesome-icon icon="fa-solid fa-chevron-left"></font-awesome-icon>
                            </v-btn>
                            <div>{{ archivePreviewIndex + 1 }} / {{ archiveFiles.length }}</div>
                            <v-btn :disabled="archivePreviewIndex + 1 == archiveFiles.length" @click="nextPreview">
                                <font-awesome-icon icon="fa-solid fa-chevron-right"></font-awesome-icon>
                            </v-btn>
                        </div>
                        <div v-else class="d-flex justify-center">
                            <div>{{ archivePreviewIndex + 1 }} / {{ archiveFiles.length }}</div>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn @click="archiveImportModal = false">Cancel</v-btn>
                    <v-btn @click="importArchiveVideos" color="primary">
                        <font-awesome-icon icon="fa-solid fa-clock-rotate-left" class="mr-2"></font-awesome-icon>
                        <span> Restore Original File{{ archiveFiles.length == 1 ? '' : 's' }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </AppLayout>
</template>

<script>
import { DragDrop } from '@uppy/vue';
import draggable from 'vuedraggable';
import Uppy from '@uppy/core';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/drag-drop/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import { mapActions, mapState } from 'vuex';
import StyledVideojsPlayer from '@/components/videojs/StyledVideojsPlayer.vue';
import videojs from 'video.js';

// 10 x 1MB = 10MB chunks
const DEFAULT_CHUNK_SIZE = 10 * 1048576;

export default {
    metaInfo: {
        title: 'Video Uploader',
    },
    name: 'App',
    components: {
        DragDrop,
        draggable,
        CustomTooltip,
        StyledVideojsPlayer,
    },
    data: () => {
        return {
            archiveImportModal: false,
            totalUploadProgress: 0,
            token: null,
            tempFiles: [],
            currentTab: 1,
            endURL: null,
            startURL: null,
            startTimeNumber: 0,
            endTimeNumber: 0,
            startTime: {
                hours: 0,
                minutes: 0,
                seconds: 0,
            },
            endTime: {
                hours: 0,
                minutes: 0,
                seconds: 0,
            },
            fileToSASMap: {},
            currentVideoProcess: 0,
            eventInfo: {},
            eventVideo: {},
            uploaderType: 'create',
            importedVideos: {},
            vods: [],
            totalUploadBytes: 0,
            uploadedBytes: 0,
            draggingIndex: -1,
            blobUrls: {},
            videosAreRendering: false,
            displayOverwriteWarning: false,
            importSourceModal: false,
            importType: null,
            video: {},
            importTypes: [
                { label: 'Current Video', value: 0, color: '#4caf50' },
                { label: 'Restored Original', value: 1, color: '#0653aa' },
            ],
            archivePreviewIndex: 0,
        };
    },
    watch: {
        currentTab() {
            if (this.$refs.startTimeVideoPlayer) {
                this.$refs.startTimeVideoPlayer.pause();
            }

            if (this.$refs.endTimeVideoPlayer) {
                this.$refs.endTimeVideoPlayer.pause();
            }
        },
        uploadedBytes(newVal) {
            this.updateProgress();
        },
        tempFiles: {
            deep: true,
            handler: async function tempFileHanlder(newFiles, oldFiles) {
                if (newFiles.length > 0) {
                    const endFile = newFiles[newFiles.length - 1];
                    this.endURL = this.getFileURL(endFile);
                    const duration = await this.calculateDuration(endFile);

                    if (this.$refs.endTimeVideoPlayer) {
                        this.$refs.endTimeVideoPlayer.setCurrentTime(duration);
                    }
                } else {
                    this.startURL = null;
                    this.endURL = null;
                    this.currentTab = 1;
                }
            },
        },
    },
    computed: {
        uppy: function () {
            return new Uppy({
                restrictions: {
                    allowedFileTypes: ['.mp4', '.mov'],
                },
            })
                .on('file-added', file => {
                    this.blobUrls[file.id] = this.getFileURL(file);
                    this.tempFiles = [...this.tempFiles, file];
                })
                .on('file-removed', file => {
                    this.tempFiles = this.tempFiles.filter(item => item.id != file.id);
                })
                .on('upload-success', (file, response) => {});
        },
        archiveFiles() {
            if (this.video.archiveFilePath) {
                return this.video.archiveFilePath.split(',');
            }

            return [];
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        handleDrop(event) {
            if (!this.uppy) return;

            const files = event.dataTransfer.files;

            for (let i = 0; i < files.length; i++) {
                this.uppy.addFile({
                    name: files[i].name,
                    type: files[i].type,
                    data: files[i],
                });
            }
        },
        prevPreview() {
            if (this.archiveFiles.length == 0) return;

            if (this.archivePreviewIndex == 0) return;

            this.archivePreviewIndex--;
        },
        nextPreview() {
            if (this.archiveFiles.length == 0) return;

            if (this.archivePreviewIndex + 1 == this.archiveFiles.length) return;

            this.archivePreviewIndex++;
        },
        restoreArchiveFiles() {
            this.tempFiles = [];

            const urlList = this.eventVideo.archiveFilePath.split(',');

            for (let i = 0; i < urlList.length; i++) {
                const url = urlList[i];
                this.importMp4FromUrl(url);
            }
        },
        calculateDuration(file) {
            return new Promise((resolve, reject) => {
                let url;
                let fileType;
                let urlRequiresRevoke = false;

                if (!file.playbackUrl) {
                    url = URL.createObjectURL(file.data);

                    fileType = 'video/mp4';
                    urlRequiresRevoke = true;
                } else if (file.playbackUrl.endsWith('.m3u8')) {
                    url = file.playbackUrl;
                    fileType = 'application/x-mpegURL';
                    urlRequiresRevoke = false;
                } else if (file.playbackUrl) {
                    url = file.playbackUrl;
                    fileType = 'video/mp4';
                    urlRequiresRevoke = false;
                }

                const videoElement = document.createElement('video');
                videoElement.className = 'video-js';
                document.body.appendChild(videoElement);

                const player = videojs(videoElement, {
                    controls: false,
                    autoplay: false,
                    preload: 'auto',
                    sources: [
                        {
                            src: url,
                            type: fileType,
                        },
                    ],
                });

                player.on('loadedmetadata', async () => {
                    const duration = await player.duration();
                    resolve(duration);

                    player.dispose();
                    if (urlRequiresRevoke) {
                        URL.revokeObjectURL(url);
                    }
                });

                player.on('error', error => {
                    console.error('Error loading video:', error);
                    reject(error);

                    // Ensure cleanup even on error
                    player.dispose();
                    if (urlRequiresRevoke) {
                        URL.revokeObjectURL(url);
                    }
                });
            });
        },
        updateStartTimestamp() {
            if (this.$refs.startTimeVideoPlayer) {
                let timestamp = new Date(this.$refs.startTimeVideoPlayer.currentTime * 1000)
                    .toISOString()
                    .substr(11, 8);

                this.startTime = {
                    hours: timestamp.substring(0, 2),
                    minutes: timestamp.substring(3, 5),
                    seconds: timestamp.substring(6, 8),
                };

                this.startTimeNumber = this.$refs.startTimeVideoPlayer.currentTime;
            }
        },
        updateEndTimestamp() {
            if (this.$refs.endTimeVideoPlayer) {
                let timestamp = new Date(this.$refs.endTimeVideoPlayer.currentTime * 1000).toISOString().substr(11, 8);

                this.endTime = {
                    hours: timestamp.substring(0, 2),
                    minutes: timestamp.substring(3, 5),
                    seconds: timestamp.substring(6, 8),
                };

                this.endTimeNumber = this.$refs.endTimeVideoPlayer.currentTime;
            }
        },
        moveToVideoSelection() {
            if (this.tempFiles.length === 1) {
                this.currentTab = 1;
            } else {
                this.currentTab = 2;
            }
        },
        moveToSortVideos() {
            if (this.tempFiles.length === 1) {
                this.moveToSetStartTime();
            } else {
                this.currentTab = 2;
            }
        },
        moveToSetStartTime() {
            this.startURL = this.getFileURL(this.tempFiles[0]);
            this.currentTab = 3;
        },
        async moveToSetEndTime() {
            this.currentTab = 4;
        },
        moveToUpload() {
            this.currentTab = 5;
            this.initUpload();
        },
        getFileURL(video) {
            if (!video) return;

            if (this.blobUrls[video.id]) {
                return this.blobUrls[video.id];
            }

            if (video.playbackUrl) {
                return video.playbackUrl;
            } else if (video.data) {
                return URL.createObjectURL(video.data);
            }
        },
        uploadFileButton() {
            let fileBtn = document.querySelector('.uppy-DragDrop-browse');
            if (fileBtn) {
                fileBtn.click();
            }
        },
        removeFile(file) {
            if (this.importedVideos[file.id]) {
                let index = this.tempFiles.findIndex(x => x.id == file.id);
                if (index !== -1) {
                    this.tempFiles.splice(index, 1);
                }
            } else {
                this.uppy.removeFile(file.id);
            }
        },
        commit() {
            let url = this.saslink + '&comp=blocklist';

            var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
            // for (var i = 0; i < this.BLOCK_IDS.length; i++) {
            //     requestBody += '<Latest>' + this.BLOCK_IDS[i] + '</Latest>';
            // }
            requestBody += '<Latest>YmxvY2stMDAwMDAx</Latest>';
            requestBody += '<Latest>YmxvY2stMDAwMDAy</Latest>';
            requestBody += '<Latest>YmxvY2stMDAwMDAz</Latest>';
            requestBody += '<Latest>YmxvY2stMDAwMDA0</Latest>';
            requestBody += '</BlockList>';

            this.axios
                .create({
                    headers: {
                        'x-ms-blob-content-type': 'video/mp4',
                    },
                })
                .put(url, requestBody)
                .then(response => {})
                .catch(error => {});
        },
        pad(number, length) {
            var str = '' + number;
            while (str.length < length) {
                str = '0' + str;
            }
            return str;
        },
        uploadBlock(data, url) {
            return new Promise((resolve, reject) => {
                this.axios
                    .create({
                        headers: {
                            'x-ms-blob-type': 'BlockBlob',
                        },
                    })
                    .put(url, data)
                    .then(response => {
                        resolve();
                    })
                    .catch(error => {
                        reject();
                    });
            });
        },
        updateProgress() {
            const totalPercent = Math.round((this.uploadedBytes / this.totalUploadBytes) * 100);
            this.totalUploadProgress = totalPercent;
        },
        uploadToSas(
            selectedFile,
            currentFilePointer,
            totalBytesRemaining,
            maxBlockSize,
            BlockIDS,
            TOTAL_SIZE,
            currentVideoIndex,
        ) {
            return new Promise((resolve, reject) => {
                if (totalBytesRemaining > 0) {
                    let fileSliceContent = selectedFile.data.slice(
                        currentFilePointer,
                        currentFilePointer + maxBlockSize,
                    );

                    let reader = new FileReader();
                    reader.readAsArrayBuffer(fileSliceContent);

                    // Triggered when the reader finishes reading the content
                    reader.onloadend = event => {
                        if (event.target.readyState == FileReader.DONE) {
                            const data = new Uint8Array(event.target.result);

                            let block_id = btoa('block-' + this.pad(BlockIDS.length, 6));
                            let newBlocksArray = [...BlockIDS, block_id];
                            let url = this.fileToSASMap[selectedFile.id].sas + '&comp=block&blockid=' + block_id;

                            // Call Axios to Upload this chunk then update counters for next
                            // chunk to be called recursively.
                            this.uploadBlock(data, url)
                                .then(() => {
                                    let newTotalBytesRemaining = totalBytesRemaining - maxBlockSize;
                                    let newCurrentFilePointer = currentFilePointer + maxBlockSize;

                                    let newMaxBlockSize = maxBlockSize;
                                    if (newTotalBytesRemaining < maxBlockSize) {
                                        newMaxBlockSize = newTotalBytesRemaining;
                                    }

                                    this.uploadedBytes += data.byteLength;

                                    // Resursively go to the next pointer
                                    return resolve(
                                        this.uploadToSas(
                                            selectedFile,
                                            newCurrentFilePointer,
                                            newTotalBytesRemaining,
                                            newMaxBlockSize,
                                            newBlocksArray,
                                            TOTAL_SIZE,
                                            currentVideoIndex,
                                        ),
                                    );
                                })
                                .catch(() => {
                                    return reject();
                                });
                        }
                    };
                } else {
                    return resolve(BlockIDS);
                }
            });
        },
        async uploadToSasV2(
            selectedFile,
            currentFilePointer,
            totalBytesRemaining,
            maxBlockSize,
            blockIDs,
            totalSize,
            currentVideoIndex,
        ) {
            // Base case: if no bytes are remaining, resolve with the block IDs
            if (totalBytesRemaining <= 0) {
                return blockIDs;
            }

            try {
                const { data, newTotalBytesRemaining, newCurrentFilePointer, blockID } = await this.processFileChunk(
                    selectedFile,
                    currentFilePointer,
                    totalBytesRemaining,
                    maxBlockSize,
                    blockIDs,
                );

                this.uploadedBytes += data.byteLength;
                blockIDs.push(blockID);

                const newMaxBlockSize = newTotalBytesRemaining < maxBlockSize ? newTotalBytesRemaining : maxBlockSize;

                // Recursive call with updated params
                return await this.uploadToSasV2(
                    selectedFile,
                    newCurrentFilePointer,
                    newTotalBytesRemaining,
                    newMaxBlockSize,
                    blockIDs,
                    totalSize,
                    currentVideoIndex,
                );
            } catch (error) {
                throw error;
            }
        },
        async processFileChunk(selectedFile, currentFilePointer, totalBytesRemaining, maxBlockSize, blockIDs) {
            const fileSliceContent = selectedFile.data.slice(currentFilePointer, currentFilePointer + maxBlockSize);
            const data = await this.readFileChunk(fileSliceContent);
            const blockID = this.generateBlockID(blockIDs.length);
            const url = this.generateUrlForBlock(selectedFile.id, blockID);

            // Upload this chunk
            await this.uploadBlock(data, url);

            // Calculate new pointers and sizes after uploading the chunk
            const newTotalBytesRemaining = totalBytesRemaining - maxBlockSize;
            const newCurrentFilePointer = currentFilePointer + maxBlockSize;

            return { data, newTotalBytesRemaining, newCurrentFilePointer, blockID };
        },
        readFileChunk(fileSliceContent) {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.onloadend = event => {
                    if (event.target.readyState === FileReader.DONE) {
                        const data = new Uint8Array(event.target.result);
                        resolve(data);
                    } else {
                        reject(new Error('Failed to read file chunk'));
                    }
                };
                reader.onerror = reject;
                reader.readAsArrayBuffer(fileSliceContent);
            });
        },
        generateBlockID(index) {
            return btoa('block-' + this.pad(index, 6));
        },
        generateUrlForBlock(fileId, blockID) {
            return this.fileToSASMap[fileId].sas + '&comp=block&blockid=' + blockID;
        },
        commitBlockList(link, BlockIDS) {
            return new Promise((resolve, reject) => {
                let url = link + '&comp=blocklist';

                var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
                for (var i = 0; i < BlockIDS.length; i++) {
                    requestBody += '<Latest>' + BlockIDS[i] + '</Latest>';
                }
                requestBody += '</BlockList>';

                this.axios
                    .create({
                        headers: {
                            'x-ms-blob-content-type': 'video/mp4',
                        },
                    })
                    .put(url, requestBody)
                    .then(response => {
                        resolve();
                    })
                    .catch(error => {});
            });
        },
        fetchSaSLink(file, order) {
            return new Promise((resolve, reject) => {
                this.axios
                    .create({
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                        },
                    })
                    .post(
                        process.env.VUE_APP_API +
                            `/events/createmedia/chunked/${this.$route.params.id}?fileName=${file.name}&order=${order}`,
                    )
                    .then(response => {
                        this.fileToSASMap = {
                            ...this.fileToSASMap,
                            [file.id]: response.data,
                        };

                        resolve(response.data);
                    })
                    .catch(error => {
                        reject();
                    });
            });
        },
        createExternalMediaRecord(file, order) {
            return new Promise((resolve, reject) => {
                let data = {
                    fileName: file.name,
                    order: order,
                    url: file.playbackUrl,
                };

                this.axiosInstance
                    .post(`/events/createmedia/external/${this.$route.params.id}`, data)
                    .then(response => {
                        this.fileToSASMap = {
                            ...this.fileToSASMap,
                            [file.id]: response.data,
                        };

                        resolve(response.data);
                    })
                    .catch(error => {
                        reject();
                    });
            });
        },
        async calculateStartStop() {
            let duration = 0;
            for (let i = 0; i < this.tempFiles.length; i++) {
                duration += await this.calculateDuration(this.tempFiles[i]);
            }

            // Calculate end video duration
            const endVideoDuration = await this.calculateDuration(this.tempFiles[this.tempFiles.length - 1]);

            const data = {
                start: this.startTimeNumber,
                stop: duration - (endVideoDuration - this.endTimeNumber),
            };
            return data;
        },

        createRenderObject() {
            return new Promise(async (resolve, reject) => {
                const trimData = await this.calculateStartStop();

                // Media Object Array
                const medias = this.tempFiles.map((video, index) => {
                    return {
                        id: this.fileToSASMap[video.id].id ? this.fileToSASMap[video.id].id : 0,
                        name: video.name,
                        sas: this.fileToSASMap[video.id].sas ? this.fileToSASMap[video.id].sas : null,
                        order: index,
                        mediaType: 1,
                        url: video.playbackUrl ? this.fileToSASMap[video.id].url : this.fileToSASMap[video.id].sas,
                    };
                });

                let videoQueType = this.eventInfo.introVideo ? 0 : 1;
                let endpoint = `/render/create`;

                if (this.eventInfo.eventStatus == 7 && this.uploaderType == 'edit') {
                    endpoint = '/render/edit';
                    videoQueType = 8;

                    if (this.eventInfo.introVideo) {
                        videoQueType = 7;

                        if (trimData.start < 19) {
                            trimData.start = 19;
                        }
                    }
                }

                // Final Payload
                const payload = {
                    eventId: parseInt(this.$route.params.id),
                    medias,
                    videoQueType: videoQueType,
                    start: trimData.start,
                    stop: trimData.stop,
                };

                // // Make API Request
                this.axiosInstance
                    .post(process.env.VUE_APP_API + endpoint, payload)
                    .then(response => {
                        this.videosAreRendering = true;
                        this.startRedirectCountdown();
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject();
                    });
            });
        },
        calcTotalUploadSize() {
            const filesToUpload = this.tempFiles.filter(f => !f.playbackUrl && f.size);

            const totalBytes = filesToUpload.reduce((sum, file) => sum + file.size, 0);
            return totalBytes;
        },
        async initUpload() {
            this.totalUploadBytes = this.calcTotalUploadSize();
            this.uploadedBytes = 0;

            // Loop Through File and upload each one
            for (let i = 0; i < this.tempFiles.length; i++) {
                this.currentVideoBeingUploaded = i == this.tempFiles.length - 1 ? i : i + 1;
                let SELECTED_FILE = this.tempFiles[i];

                if (this.importedVideos[SELECTED_FILE.id]) {
                    //If imported video do not create a media record & do not upload file to azure
                    //pass media w/ id = 0 to use url but filter out before media create/update

                    this.fileToSASMap = {
                        ...this.fileToSASMap,
                        [SELECTED_FILE.id]: {
                            id: 0,
                            url: SELECTED_FILE.playbackUrl,
                            order: i,
                        },
                    };
                } else {
                    //If local added file, upload to azure & create uploaded file(media) record
                    await this.handleAzureUpload(SELECTED_FILE, i);
                }

                this.currentVideoProcess++;
            }

            // Create Render Object
            let RENDER_OBJECT = await this.createRenderObject();
        },
        async handleAzureUpload(file, index) {
            let TOTAL_BYTES = file.size;

            // Fetch SAS information
            let SAS_INFO = await this.fetchSaSLink(file, index);

            // Upload to SAS
            let UPLOAD_RESULT = await this.uploadToSasV2(
                file,
                0,
                TOTAL_BYTES,
                DEFAULT_CHUNK_SIZE,
                [],
                TOTAL_BYTES,
                index,
            );

            // Commit File to SaS
            let COMMIT = await this.commitBlockList(SAS_INFO.sas, UPLOAD_RESULT);
        },

        startRedirectCountdown() {
            setTimeout(() => {
                this.$router.push(`/services/${this.eventInfo.serviceSlug}/${this.eventInfo.id}`);
            }, 4000);
        },
        importMp4FromUrl(url) {
            // WEB-1362 -For videos from the service/event use the Service title, with fallback to the route parsed filename
            let fileName = this.eventInfo?.title || url.split('/').pop();

            const foundFile = Object.values(this.importedVideos).find(obj => obj.playbackUrl === url);

            let tempFile = foundFile ? foundFile : { playbackUrl: url, name: fileName, id: uuidv4() };
            this.tempFiles.push(tempFile);

            this.importedVideos[tempFile.id] = tempFile;
        },
        getVods(eventId) {
            return this.axiosInstance
                .get(`/live/get-vod/${eventId}`)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    console.log(error);
                });
        },
        getEvent(eventId) {
            return this.axiosInstance.get(`/Events/` + eventId).then(response => {
                return response.data;
            });
        },
        getVideo(eventId) {
            return this.axiosInstance.get(`/Videos/events/` + eventId).then(response => {
                return response.data;
            });
        },
        async fetchVideoAndCreateBlob(url) {
            try {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Http error! status: ${response.status}`);
                }

                const blob = await response.blob();
                return blob;
            } catch (error) {
                this.showSnackbar({ message: 'Error getting video data', color: 'error' });
                return;
            }
        },
        async importVods(eventId) {
            this.$store.dispatch('block', true);

            this.vods = await this.getVods(eventId);

            for (let i = 0; i < this.vods.length; i++) {
                const vod = this.vods[i];

                let vodInTempFiles = this.tempFiles.some(obj => obj.playbackUrl == vod.playbackUrl);

                if (!vodInTempFiles) {
                    const fileName = this.getVodName(vod.playbackUrl);

                    const foundFile = Object.values(this.importedVideos).find(
                        obj => obj.playbackUrl === vod.playbackUrl,
                    );

                    let tempFile = foundFile
                        ? foundFile
                        : { playbackUrl: vod.playbackUrl, name: fileName, id: uuidv4() };
                    this.tempFiles.push(tempFile);

                    this.importedVideos[tempFile.id] = tempFile;
                }
            }

            let urlOrder = this.vods.map(obj => obj.playbackUrl);

            this.tempFiles.sort((a, b) => {
                return urlOrder.indexOf(a.playbackUrl) - urlOrder.indexOf(b.playbackUrl);
            });
            this.$store.dispatch('block', false);
        },
        getVodName(url) {
            if (!url || !url.endsWith('.m3u8')) {
                return;
            }
            const parts = url.split('/');

            return parts[parts.length - 2] + '.m3u8';
        },
        async importCurrentVideos() {
            if (!this.eventInfo) return;

            this.clearImports();

            //Finished state
            if (this.eventInfo.eventStatus === 3) {
                this.importVods(this.$route.params.id);
            }

            //Completed state
            if (this.eventInfo.eventStatus === 7) {
                this.importMp4FromUrl(this.eventInfo.convertedVideo);
                this.eventVideo = await this.getVideo(this.$route.params.id);
            }

            this.importType = 0;
            this.importSourceModal = false;
            this.currentTab = 1;
        },

        clearImports() {
            this.tempFiles = [];

            this.importedVideos = {};
        },
        importArchiveVideos() {
            this.clearImports();
            this.importType = 1;
            this.importSourceModal = false;
            this.archiveImportModal = false;

            let archiveFiles = [];
            if (this.video.archiveFilePath) {
                archiveFiles = this.video.archiveFilePath.split(',');
            }

            for (let i = 0; i < archiveFiles.length; i++) {
                let archiveFile = archiveFiles[i];
                this.importMp4FromUrl(archiveFile);
            }

            this.currentTab = 1;
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
    },

    beforeDestroy() {
        this.uppy.close();
    },
    async mounted() {
        await this.setAuthToken();
        this.createAxiosInstance();
        this.eventInfo = await this.getEvent(this.$route.params.id);

        this.uploaderType = this.$route.query?.uploaderType == 'edit' ? 'edit' : 'create';
        if (this.uploaderType != 'edit' && this.eventInfo.eventStatus == 7) {
            this.displayOverwriteWarning = true;
        }

        if (this.uploaderType == 'edit') {
            // this.importSourceModal = true;
            this.importCurrentVideos();
            this.video = await this.getVideo(this.$route.params.id);
        }
    },
};
</script>

<style lang="scss">
.tile {
    width: 80%;
    margin: 4rem auto 10%;
    padding: 2rem inherit;
}

.uppy-DragDrop-label {
    opacity: 0 !important;
}
.uppy-DragDrop-arrow {
    opacity: 0 !important;
}

.video-uploader-drag-drop-zone {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    div {
        width: 100%;
    }
    .fake-btn {
        background-color: #ff530d;
        border-radius: 5px;
        color: white;
        padding: 5px 10px;
    }

    .uploader-info-content {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;
        pointer-events: none;

        h2 {
            font-size: 1.5rem;
            margin: 0;
        }

        p {
            margin: 0;
            opacity: 0.75;
        }
        button {
            pointer-events: all;
        }
    }
}

.video-uploader-preview {
    background: white;
    padding: 2rem;
    border-radius: 4px;

    &-grid {
        background: #fafafa;
        border-radius: 1rem;
        margin: 2rem 0;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        padding: 2rem;

        &-vid-container {
            background: #e2e2e2;
            border-radius: 4px;
            position: relative;

            // video {
            //     border-top-right-radius: 4px;
            //     border-top-left-radius: 4px;
            // }
            .video-js {
                height: 180px;
                aspect-ratio: 16/9;
            }

            width: 320px;
            // p {
            //     text-align: center;
            //     font-size: 14px;
            //     padding: 0.25rem;
            //     margin: 0 !important;
            // }
        }
        &-vid-container-x {
            background: #3d3d3d;
            color: white;
            position: absolute;
            width: 1.1rem;
            height: 1.1rem;
            display: flex;
            border-radius: 9999px;
            justify-content: center;
            align-items: center;
            top: -0.4rem;
            left: -0.4rem;
            cursor: pointer;
            z-index: 5;

            transition: 0.2s all;

            p {
                margin: 0 !important;
                line-height: 0;
            }
        }

        &-vid-container-x:hover {
            background: #ff530d;
        }

        &-vid-container-delete {
            transition: 0.2s;
        }
        &-vid-container-delete:hover {
            color: #ff5252;
        }

        &-vid-container-number {
            // background: #f7f7f7;
            background: #ff530d;
            // color: rgb(63, 63, 63);
            color: white;
            position: absolute;
            width: 2rem;
            height: 1.6rem;
            display: flex;
            border-radius: 4px;
            justify-content: center;
            align-items: center;
            top: -0.5rem;
            right: -0.5rem;
            // cursor: pointer;
            z-index: 5;
            transition: 0.2s all;
            // border: 1px solid rgb(180, 180, 180);
            font-size: 1.2rem;
            p {
                margin: 0 !important;
                line-height: 0;
                font-size: 2rem;
            }
        }

        &-vid-container-video-player {
            video {
                border-radius: 4px 4px 0 0;
            }

            .video-js {
                border-radius: 4px 4px 0 0;
            }
        }
    }

    &-button-container {
        display: flex;
        justify-content: space-between;
    }
}

.video-uploader-start-time {
    background: white;
    padding: 2rem;

    &-content {
        display: flex;
        flex-direction: column;
        padding: 2rem;
        align-self: center;
        width: 100%;
        min-width: 380px;
        max-width: 500px;
    }

    &-video-control-container {
        display: flex;
        gap: 10px;

        align-self: center;
        margin-top: 1rem;
        // min-width: 200px;

        .control-button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f2f2f2;
            padding: 0.45rem;
            border-radius: 9999px;
            cursor: pointer;
            transition: 0.2s all;
            width: 40px;
            height: 40px;
        }

        .control-button:hover {
            background: #b6b5b5;
        }
    }

    &-button-container {
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
    }

    .time-preview-player-container {
        width: 100%;
        min-width: 380px;
        max-width: 500px;
        aspect-ratio: 16/9;
    }

    &-video {
        min-height: 250px;
    }
}

.vid-container-chosen {
    position: relative;

    .video-uploader-preview-grid-vid-container-number {
        opacity: 0;
    }

    .video-player-container {
        height: 180px;
        width: 320px;
        border-radius: 5px;
    }
}
.vid-container-chosen::before {
    content: '';
    background: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5000;
}
.vid-container-chosen::after {
    content: '';
    background: rgb(243, 243, 243);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5000;
    border-radius: 10px;
    border: 3px dashed gray;
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    text-align: center;
}
.loading {
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    animation: loading-ani1 4s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    margin-bottom: 30px;
    margin-top: 30px;
}
.loading i {
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 0.3rem;
    position: absolute;
}
.loading i:nth-child(1) {
    background: #ff530c;
    transform: translate(0, 0);
    animation: loading-ani2 4s ease-in-out infinite;
}
.loading i:nth-child(2) {
    background: #0b3c60;
    transform: rotate(90deg) translate(0, 0);
    animation: loading-ani3 6s ease-in-out infinite;
}
.loading i:nth-child(3) {
    background: #ab1b58;
    transform: rotate(180deg) translate(0, 0);
    animation: loading-ani4 8s ease-in-out infinite;
}
.loading i:nth-child(4) {
    background: #df2000;
    transform: rotate(270deg) translate(0, 0);
    animation: loading-ani5 10s ease-in-out infinite;
}
@keyframes loading-ani1 {
    25% {
        transform: rotate(135deg);
    }
    50% {
        transform: rotate(225deg);
    }
    75% {
        transform: rotate(315deg);
    }
    100% {
        transform: rotate(405deg);
    }
}
@keyframes loading-ani2 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: translate(0, 0);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: translate(0, 40px);
    }
}
@keyframes loading-ani3 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: rotate(90deg) translate(0, 0);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: rotate(90deg) translate(0, 40px);
    }
}
@keyframes loading-ani4 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: rotate(180deg) translate(0, 0);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: rotate(180deg) translate(0, 40px);
    }
}
@keyframes loading-ani5 {
    17.5%,
    25%,
    42.5%,
    50%,
    67.5%,
    75%,
    92.5%,
    100% {
        transform: rotate(270deg) translate(0, 0);
    }
    12.5%,
    37.5%,
    62.5%,
    87.5% {
        transform: rotate(270deg) translate(0, 40px);
    }
}
</style>
