<template>
    <div class="tribute-view">
        <TributeStepper
            :currentStep="currentStep"
            :tributeVideo="tributeVideo"
            @change-step="val => handleChangeStep(val)"
        />

        <div class="step-content">
            <div :class="['middle-content', { expanded: tributeEditorExpanded }]" v-if="currentStep > 1">
                <div class="action-column">
                    <v-btn class="text-normal" @click="handleChangeStep(currentStep - 1)">
                        <v-icon left> mdi-chevron-left </v-icon>
                        Prev
                    </v-btn>
                </div>
                <div class="preview-player">
                    <CreatomatePreview :source="previewSource" :modifications="previewMods" />
                </div>
                <v-btn
                    v-if="tributeEditorExpanded"
                    @click="togglePreview"
                    color="white"
                    class="text-normal"
                    style="max-width: 500px; width: 30%"
                >
                    <font-awesome-icon icon="fa-regular fa-play-circle" class="fa-lg mr-2 play-icon" />
                    Preview Video
                </v-btn>
                <div class="action-column">
                    <v-btn class="next-btn text-normal" @click="handleChangeStep(currentStep + 1)" :disabled="currentStep >= 5">
                        Next
                        <v-icon right> mdi-chevron-right </v-icon>
                    </v-btn>
                </div>
            </div>

            <TributeSetup
                v-if="currentStep === 1 && $route.params.slug"
                :serviceSlug="$route.params.slug"
                @tribute-video-created="data => handleTributeCreated(data)"
            />

            <ResizableWorkspace v-if="currentStep === 2 && tributeVideo.eventId">
                <ManagePhotos
                    :eventId="tributeVideo.eventId"
                    :tributeVideo="tributeVideo"
                    @nextStep="this.handleChangeStep(this.currentStep + 1)"
                    @refresh-preview="tryGeneratePreviewJson(tributeVideo.id)"
                />
            </ResizableWorkspace>

            <ResizableWorkspace v-if="currentStep === 3 && tributeVideo.eventId && tributeVideo.id">
                <ManageThemes
                    :eventId="tributeVideo.eventId"
                    :tributeVideo="tributeVideo"
                    @nextStep="this.handleChangeStep(this.currentStep + 1)"
                    @refresh-preview="tryGeneratePreviewJson(tributeVideo.id)"
                />
            </ResizableWorkspace>

            <ResizableWorkspace v-if="currentStep === 4 && tributeVideo">
                <ManageMusic
                    @nextStep="this.handleChangeStep(this.currentStep + 1)"
                    :eventId="tributeVideo.eventId"
                    @refresh-preview="tryGeneratePreviewJson(tributeVideo.id)"
                    :tributeVideo="tributeVideo"
                />
            </ResizableWorkspace>

            <ResizableWorkspace v-if="currentStep === 5 && tributeVideo.id">
                <StepCreate :tributeVideo="tributeVideo" />
            </ResizableWorkspace>
        </div>

        <MiniMusicPlayer :token="token" v-if="token" />
    </div>
</template>

<script>
import initApiServices from '@/services/ApiInitializer';
import { mapActions } from 'vuex';

import TributeStepper from '@/components/Tribute/TributeStepper.vue';
import TributeSetup from '@/components/Tribute/TributeSetup.vue';
import ManagePhotos from '@/components/Tribute/ManagePhotos.vue';
import ManageThemes from '@/components/Tribute/ManageThemes.vue';
import MiniMusicPlayer from '@/components/Tribute/Music/MiniMusicPlayer.vue';
import CreatomatePreview from '@/components/Misc/CreatomatePreview.vue';
import ManageMusic from '@/components/Tribute/ManageMusic.vue';
import StepCreate from '@/components/Tribute/StepCreate.vue';
import ResizableWorkspace from '@/components/Tribute/Layout/ResizableWorkspace.vue';

export default {
    name: 'TributeView',
    metaInfo: {
        title: 'Tribute Video',
    },
    components: {
        TributeStepper,
        TributeSetup,
        ManagePhotos,
        CreatomatePreview,
        ManageThemes,
        ManageMusic,
        MiniMusicPlayer,
        StepCreate,
        ResizableWorkspace,
    },
    computed: {
        tributeEditorExpanded() {
            return this.$store.state.tributeEditor.expanded;
        },
    },
    data() {
        return {
            currentStep: 1,
            tributeVideo: null,
            standardRender: null,
            copyrightSafeRender: null,
            apiService: null,
            token: null,
            previewSource: null,
            previewMods: null,
        };
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        handleChangeStep(val) {
            if (val <= 0) return;
            if (val > 5) return;

            this.currentStep = val;
        },
        togglePreview() {
            this.toggleExpandedState();
        },
        handleTributeCreated(data) {
            this.tributeVideo = data;

            if (this.tributeVideo.eventId) {
                this.handleChangeStep(this.currentStep + 1);
            }

            if (this.tributeVideo.id) {
                this.tryGeneratePreviewJson(this.tributeVideo.id);
            }

            this.tryFetchTributeVideo(this.$route.params.slug);
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        async tryFetchTributeVideo(slug) {
            try {
                this.block(true);
                const resp = await this.apiService.tributeVideo.getTributeVideoByServiceSlug(slug);
                if (resp.data) {
                    this.tributeVideo = resp.data.tributeVideo;
                    this.standardRender = resp.data.standardRender;
                    this.copyrightSafeRender = resp.data.copyrightSafeRender;

                    if (this.standardRender) {
                        console.log('RENDER READY', this.standardRender);
                    }
                    this.currentStep = 2;
                    this.$store.dispatch('tributeVideo/updateTributeVideo', {
                        ...this.tributeVideo,
                    });
                }
            } catch (error) {
                console.error('Error fetching tribute video', error);
            } finally {
                this.block(false);
            }
        },

        async tryGeneratePreviewJson(tributeId) {
            try {
                if (!tributeId) {
                    throw new Error('Invalid tribute video id');
                }

                var resp = await this.apiService.tributeVideo.generatePreviewJson(tributeId);

                if (resp.data.creatomateJson) {
                    const creatomateJson = JSON.parse(resp.data.creatomateJson);

                    if (!creatomateJson.source) throw new Error('Error parsing preview source');

                    this.previewSource = JSON.stringify(creatomateJson.source);

                    if (creatomateJson.modifications) {
                        this.previewMods = JSON.stringify(creatomateJson.modifications);
                    }
                }
            } catch (error) {
                console.log(error, 'error');
            }
        },
    },
    async mounted() {
        await this.setAuthToken();
        this.apiService = initApiServices(this.token);

        await this.tryFetchTributeVideo(this.$route.params.slug);

        console.log(this.tributeVideo, 'tributeVideo');
        // TODO: setup default template so that preview is ready @ step 2
        if (this.tributeVideo?.id) {
            this.tryGeneratePreviewJson(this.tributeVideo.id);
        }
    },
};
</script>

<style lang="scss" scoped>
.tribute-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    background-color: #f3f4f6;
}

.step-content {
    width: 100%;
    max-width: 800px;
    margin-top: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    height: auto;
    transition: height 0.8s ease;
}

.preview-player {
    max-width: 800px;
    width: 100%;
    background-color: #ffffff;
    padding: 12px;
    border-radius: 8px;
    top: 0;
    position: relative;
    transition: top 400ms ease-in-out;
    &.expanded {
        top: -50vh;
    }
}
.middle-content {
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100vh - 34vh - 140px);
    .preview-player {
        height: fit-content;
        align-self: center;
        .preview-component-container {
            margin: 0 auto;
            max-height: min(49vh, 430px);
        }
    }
    .action-column {
        min-width: 160px;
        text-align: center;
        align-content: center;
    }
    &.expanded {
        height: fit-content;
        margin-top: -12px;

        .preview-player {
            height: 0;
            overflow: hidden;
            display: none;
        }
    }
}
.play-icon {
    color: $primary-grey;
}
.text-normal {
    text-transform: none;
    letter-spacing: normal;
}
.next-btn {
    background-color: $primary-orange !important;
    color: white !important;
}
</style>
