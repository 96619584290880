import ApiService from './api.service';

const ServicesService = token => {
    const api = ApiService.createApiInstance(token);

    return {
        create(data) {
            return api.post(`/Services`, data);
        },
        get(id) {
            return api.get(`/Services/${id}`);
        },
        getBySlugAndPin(slug, pin) {
            return api.get(`/Services/${slug}/${pin}`);
        },
    };
};

export default ServicesService;
