<template>
    <div v-if="open" class="modal-overlay" @click="closeModal">
      <div class="modal-container" @click.stop>
        <div class="modal-header">
          <div class="icon-container">
            <span class="icon">&#9888;</span>
          </div>
          <div class="modal-title">
            <slot name="modal-title"></slot>
          </div>
        </div>
        <div class="modal-content">
          <slot name="modal-content"></slot>
        </div>
        <div class="modal-footer">
          <slot name="modal-footer">
            <button class="btn btn-cancel" @click="closeModal">Cancel</button>
          </slot>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      open: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      closeModal() {
        this.$emit('close');
      }
    }
  };
  </script>
  
  <style scoped lang="scss">
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    transition: opacity 0.3s ease;
  }
  
  .modal-container {
    background-color: #ffffff;
    border-radius: 8px;
    width: 95%; 
    max-width: 800px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    padding: 1rem; 
  }
  
  .modal-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem; 
  }
  
  .icon-container {
    background-color: #fee2e2;
    border-radius: 50%; 
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0; 
    margin-right: 1rem;
  }
  
  .icon {
    color: #ef4444;
    font-size: 1.5rem;
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #111827;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis; 
  }
  
  .modal-content {
    padding-left: calc(40px + 1rem);
    padding-right: 1rem; 
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #6b7280;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding-top: 1rem;
  }
  
  .btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .btn-deactivate {
    background-color: #ef4444;
    color: #ffffff;
    margin-left: 1rem;
    &:hover {
      background-color: #dc2626;
    }
  }
  
  .btn-cancel {
    background-color: #ffffff;
    color: #374151;
    border: 1px solid #d1d5db;
    &:hover {
      background-color: #f3f4f6;
    }
  }
  </style>
  