<template>
    <div>
        <!-- <v-row> -->

        <v-divider></v-divider>
        <div class="gallery-actions d-flex justify-space-between align-center flex-wrap">
            <strong
                ><span> All Stories ({{ stories.length }}) </span></strong
            >

            <div style="gap: 5px" class="d-flex flex-wrap">
                <!-- <custom-tooltip v-if="!disableContribute" :tooltipProps="{ maxWidth: 300, top: true }">
                    <template v-slot:activator>
                        <v-btn @click="$emit('init-invite')" depressed small>
                            <span class="mr-2"> Share</span>
                            <font-awesome-icon style="font-size: 0.9rem" icon="fa-regular fa-share"></font-awesome-icon
                        ></v-btn>
                    </template>
                    <template v-slot:title>
                        <span>Story Link</span>
                    </template>
                    <template v-slot:content>
                        <span>Share a link with others so they can upload their own stories.</span>
                    </template>
                </custom-tooltip> -->

                <custom-tooltip v-if="!disableContribute" :tooltipProps="{ maxWidth: 300, top: true }">
                    <template v-slot:activator>
                        <v-btn @click="handleUploaderOpen" dark color="orange" depressed small>
                            <span class="mr-2"> Upload</span>
                            <font-awesome-icon
                                style="font-size: 0.9rem"
                                icon="fa-regular fa-cloud-arrow-up"
                            ></font-awesome-icon
                        ></v-btn>
                    </template>
                    <template v-slot:title>
                        <span>Upload Media</span>
                    </template>
                    <template v-slot:content>
                        <span
                            >Add videos to {{ tributeVideo.firstName ? tributeVideo.firstName + "'s" : '' }} Stories
                            Video.</span
                        >
                    </template>
                </custom-tooltip>

                <custom-tooltip :tooltipProps="{ maxWidth: 300, top: true }">
                    <template v-slot:activator>
                        <v-btn @click="$emit('open-settings')" depressed small>
                            <font-awesome-icon style="font-size: 0.9rem" icon="fa-regular fa-gear"></font-awesome-icon
                        ></v-btn>
                    </template>
                    <template v-slot:title>
                        <span>Settings</span>
                    </template>
                    <template v-slot:content>
                        <span
                            >Update settings for
                            {{ tributeVideo.firstName ? tributeVideo.firstName + "'s" : '' }} Stories Video.</span
                        >
                    </template>
                </custom-tooltip>
            </div>
        </div>
        <v-divider></v-divider>
        <v-row no-gutters v-if="render">
            <v-col class="p-2" cols="12">
                <v-btn
                    v-if="$route.name != 'TributeUploadPage' || this.$route.name == 'TributeFamilyPage'"
                    @click="deleteRenderStep1"
                    x-small
                    color="error"
                    >{{ render.status == 0 || render.status == 1 ? 'Cancel' : 'Delete' }}</v-btn
                >
            </v-col>
            <v-col class="p-2" v-if="render.status == 2" cols="12">
                <video
                    :poster="handleThumbnail(render.url, 'story-render')"
                    preload
                    ref="renderPlayer"
                    class="tribute-player elevation-4 rounded-lg"
                    @error="handleRenderError"
                    :src="render.url"
                    :key="reloadCount"
                    width="100%"
                    controls
                >
                    Your browser does not support HTML video.
                </video>

                <div class="text-center ma-3">
                    <v-btn
                        color="#0d3d60"
                        :dark="!loading"
                        :disabled="loading"
                        @click="downloadTributeStory(render.url)"
                    >
                        <font-awesome-icon
                            icon="fa-regular fa-arrow-down-to-bracket"
                            style="font-size: 0.9rem"
                            class="mr-2"
                        />
                        <span>Download</span>
                    </v-btn>

                    <v-btn
                        class="ml-2"
                        v-if="service.slug && tributeStoryLink"
                        :disabled="loading"
                        @click="initStoryViewShareModal"
                    >
                        <font-awesome-icon icon="fa-regular fa-share" style="font-size: 0.9rem" class="mr-2" />
                        <span>Share</span>
                    </v-btn>
                </div>
            </v-col>
            <v-col v-if="render.status == 0 || render.status == 1" class="text-center" cols="12">
                <div class="d-flex justify-center m-5">
                    <custom-loader></custom-loader>
                </div>

                <h5 class="mt-6">Creating Stories Video. This will take a few minutes.</h5>
            </v-col>
            <v-cols v-if="render.status == 3" cols="12">
                <v-alert outlined text prominent transition="scale-transition" type="error" icon="mdi-cloud-alert">
                    Unable to create tribute video due to an error, please check settings and try again.
                    <v-divider class="error" style="opacity: 0.22"></v-divider>
                    <small v-if="disableSubmit">Please select {{ minPhotos }} or more photos to retry</small>
                    <div>
                        <v-btn :dark="!disableSubmit" :disabled="disableSubmit" @click="$emit('render-stories', id)"
                            >Retry</v-btn
                        >
                    </div>
                </v-alert>
            </v-cols>
        </v-row>

        <v-row class="pt-4">
            <v-col cols="12">
                <div class="d-flex justify-content-between">
                    <tribute-story-uploader
                        v-if="token"
                        ref="storyUploader"
                        @refresh="refreshTributeStories(event.id)"
                        :eventId="event.id"
                        :hide-button="true"
                        :token="token"
                    ></tribute-story-uploader>

                    <p class="text-caption" v-if="stories.length > 0">Viewing {{ stories.length }} of {{ total }}</p>

                    <!-- <v-btn @click="loadMoreStories">Load more</v-btn> -->
                </div>
            </v-col>
            <v-col v-if="stories.length == 0" cols="12">
                <!-- <p class="text-center">
                    No stories currently available. Click
                    <span @click="handleUploaderOpen" class="anchor">here</span> to upload one now.
                </p> -->

                <div class="d-flex flex-column align-items-center">
                    <div class="m-5" style="width: 100%; max-width: 200px">
                        <v-img style="max-width: 100%; max-height: 100%" :src="storiesSvg" alt="stories svg"></v-img>
                    </div>

                    <h3>Upload Story</h3>
                    <p style="max-width: 650px" class="text-center">
                        Encourage families to share their cherished memories of {{ service.firstName }} using the
                        MemoryShare Scanner App. Families can record short video memories on their phone, and we'll
                        transform these stories into a keepsake that loved ones can treasure forever.
                    </p>

                    <div v-if="!disableContribute" class="m-5">
                        <v-btn @click="handleUploaderOpen" dark depressed color="orange">Upload Video</v-btn>
                    </div>
                    <div v-if="disableContribute" class="m-3 text-center text-overline">
                        <p>Submissions are closed</p>
                    </div>
                </div>
            </v-col>
        </v-row>
        <draggable
            class="grid"
            v-model="stories"
            @start="dragging = true"
            @end="dragging = false"
            @change="handleChange($event)"
            animation="300"
            :disabled="mobile"
        >
            <div v-for="(story, index) in stories" class="grid-item">
                <div
                    @click="initUploadUserInfoModal(story)"
                    class="pointer subtitle-2 mb-3 text-center pa-2 story-name-label"
                >
                    <span class="text-truncate" v-if="story.uploadUserLastName || story.uploadUserFirstName">
                        {{ story.uploadUserFirstName }} {{ story.uploadUserLastName }}
                    </span>
                    <span class="text-truncate" v-else>Unknown</span>
                </div>
                <div class="video-container grab">
                    <custom-tooltip class="moderated-status-label" :tooltipProps="{ top: true }">
                        <template v-slot:activator>
                            <v-chip
                                class="pointer"
                                :color="moderatedStatuses[story.moderatedStatus].color"
                                @click="initModerationModal(story)"
                                dark
                                >{{ moderatedStatuses[story.moderatedStatus].label }}</v-chip
                            >
                        </template>
                        <template v-slot:title>
                            <span>Moderation Status</span>
                        </template>
                        <!-- <template v-slot:content>
                            <span>{{ moderatedStatuses[story.moderatedStatus].label }}</span>
                        </template> -->
                    </custom-tooltip>
                    <video
                        v-if="story.url"
                        :ref="'storyPlayer' + index"
                        @error="handleStoryError(index)"
                        :poster="handleThumbnail(story.url, 'story')"
                        :src="story.url"
                        controls
                        preload
                    >
                        Your browser does not support the video tag.
                    </video>
                    <div v-if="!story.url" class="error-message">Error occurred while loading the video.</div>
                    <v-btn
                        v-if="$route.name != 'TributeUploadPage' || this.$route.name == 'TributeFamilyPage'"
                        @click="initDeleteStory(story)"
                        color="error"
                        fab
                        class="del-action-btn"
                        ><font-awesome-icon icon="fa-regular fa-x"></font-awesome-icon
                    ></v-btn>
                </div>
            </div>
        </draggable>

        <div id="infinite-scroll-story-target"></div>

        <!-- </v-row> -->

        <v-dialog v-model="deleteStoryModal" max-width="400px">
            <v-card v-if="selectedStory">
                <v-card-title>Delete Story?</v-card-title>
                <v-card-text
                    >Please confirm to delete <strong>{{ selectedStory.name }}</strong
                    >, this action cannot be undone.</v-card-text
                >

                <div class="p-3">
                    <v-btn @click="confirmDelete(selectedStory.id)" color="error">Confirm</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteRenderModal" max-width="400px">
            <v-card>
                <v-card-title>Delete Render?</v-card-title>
                <v-card-text>Please confirm to delete render, this action cannot be undone.</v-card-text>

                <div class="p-3">
                    <v-btn @click="confirmDeleteRender(event.id)" color="error">Confirm</v-btn>
                </div>
            </v-card>
        </v-dialog>

        <v-dialog v-model="moderationModal" max-width="400px">
            <v-card>
                <v-card-title>Moderation Status</v-card-title>
                <v-card-text v-if="selectedStory">
                    <v-select
                        label="Status"
                        :items="moderatedStatuses"
                        item-value="value"
                        item-text="label"
                        v-model="selectedStory.moderatedStatus"
                    >
                        <template v-slot:item="{ item }">
                            <v-chip dark :color="item.color">{{ item.label }}</v-chip>
                        </template>
                        <template v-slot:selection="{ item }">
                            <v-chip dark :color="item.color">{{ item.label }}</v-chip>
                        </template>
                    </v-select>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        depressed
                        :dark="!loading"
                        :disabled="loading"
                        :loading="loading"
                        color="#0c3c60"
                        @click="updateModerationStatus(selectedStory.id, selectedStory.moderatedStatus)"
                        >Save</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog max-width="500px" v-if="selectedStory" v-model="uploadUserInfoModal">
            <v-card>
                <v-card-title>Uploaded By</v-card-title>
                <v-card-text>
                    <v-text-field
                        readonly
                        v-model="selectedStory.uploadUserFirstName"
                        label="First Name"
                    ></v-text-field>
                    <v-text-field readonly v-model="selectedStory.uploadUserLastName" label="Last Name"></v-text-field>
                    <v-text-field readonly v-model="selectedStory.uploadUserEmail" label="Email"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn depressed @click="uploadUserInfoModal = false">Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <share-upload-modal
            v-if="tributeVideo"
            ref="storyUploadShareModal"
            :type="'story'"
            :tributeVideo="tributeVideo"
            :service="service"
            @submit-invites="data => handleInviteSubmit(data)"
            @invalidEmail="email => handleInvalidEmail(email)"
            @refresh-link="val => handleRefreshLink(val)"
        ></share-upload-modal>

        <!-- START: Render download modal -->
        <v-dialog persistent v-model="downloading" max-width="500px">
            <v-card class="p-3">
                <div class="d-flex justify-content-between">
                    <h5>Downloading</h5>
                    <p>{{ downloadProgress }}%</p>
                </div>
                <v-progress-linear rounded v-model="downloadProgress"></v-progress-linear>
                <v-btn class="mt-3" color="error" @click="cancelDownload">Cancel</v-btn>
            </v-card>
        </v-dialog>
        <!-- END: Render download modal -->

        <link-share-modal
            v-if="service.slug && tributeStoryLink"
            :title="`Share ${service.firstName}'s Tribute Story`"
            :link="tributeStoryLink"
            :linkType="'Tribute Video'"
            :qrFileName="`${service.firstName}'s Tribute Story`"
            ref="storyViewShareModal"
            @submit-invites="contacts => handleStoryViewInviteSubmit(contacts)"
            @invalidEmail="email => handleInvalidEmail(email)"
        ></link-share-modal>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import draggable from 'vuedraggable';
import VerticalTestVideo from '@/assets/video/VerticalTestVideo.mp4';
import TributeStoryUploader from '@/components/ManageService/Tribute/TributeStoryUploader.vue';
import storiesSvg from '@/assets/svg/stories.svg';
import CustomLoader from '@/components/ui/CustomLoader.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import ShareUploadModal from '@/components/ManageService/Tribute/ShareUploadModal.vue';
import BrandedModal from '@/components/ui/BrandedModal.vue';
import LinkShareModal from '@/components/ui/LinkShareModal.vue';
import { moderatedStatuses } from '@/constants';

export default {
    data() {
        return {
            moderatedStatuses,
            storiesSvg,
            stories: [],
            total: 0,
            token: '',
            loading: true,
            VerticalTestVideo,
            selectedStory: null,
            deleteStoryModal: false,
            deleteRenderModal: false,
            pageNumber: 0,
            pageSize: 12,
            observer: null,
            tempStories: [
                { source: VerticalTestVideo },
                { source: VerticalTestVideo },
                { source: VerticalTestVideo },
                { source: VerticalTestVideo },
                { source: VerticalTestVideo },
                { source: VerticalTestVideo },
            ],
            render: null,
            reloadCount: 0,
            posterReloadCount: 0,
            storyShareModal: false,
            downloading: false,
            downloadCancelToken: null,
            downloadProgress: 0,
            privateModal: false,
            selectedStory: null,
            moderationModal: false,
            actionItems: [
                {
                    label: 'Show on Video Player',
                    icon: 'fa-regular fa-clapperboard-play',
                    callback: this.initPrivateModal,
                    tooltip: 'Toggle tribute story visibility in public video player',
                    tooltipTitle: 'Show on Video Player',
                },
            ],
            tributeStoryLink: '',
            phoneNumber: '',
            uploadUserInfoModal: false,
        };
    },
    watch: {
        total() {
            this.$emit('stories-count', this.total);
        },
        moderationModal(newVal, oldVal) {
            if (!newVal) {
                this.selectedStory = null;
            }
        },
        uploadUserInfoModal(newVal) {
            if (!newVal) {
                this.selectedStory = null;
            }
        },
    },
    props: {
        tributeToken: {
            type: String,
            default: '',
        },
        mobile: {
            type: Boolean,
            default: false,
        },
        service: {
            type: Object,
            required: true,
        },
        event: {
            type: Object,
            required: true,
        },
        story: {
            type: Object,
            required: true,
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        initUploadUserInfoModal(story) {
            this.uploadUserInfoModal = true;
            this.selectedStory = story;
        },
        initModerationModal(story) {
            if (!story) return;

            this.selectedStory = { ...story };
            this.moderationModal = true;
        },
        updateModerationStatus(tributeStoryId, status) {
            if (!tributeStoryId) {
                this.showSnackbar({ message: 'Tribute story id cannot be null', color: 'error' });
                return;
            }

            if (!(status >= 0)) {
                this.showSnackbar({ message: 'Status cannot be null', color: 'error' });
                return;
            }

            this.loading = true;
            this.axiosInstance
                .put(`/TributeStory/update-moderation-status/${tributeStoryId}/${status}`)
                .then(resp => {
                    let found = this.stories.find(x => x.id == tributeStoryId);

                    if (found) {
                        found.moderatedStatus = status;
                    }
                    this.showSnackbar({ message: 'Status updated' });
                    this.moderationModal = false;
                    this.$emit('moderation-update');
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error updating status', color: 'error' });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        checkDatePassed(datetime) {
            // Check if specifiedDatetime is null or empty
            if (!datetime) {
                return false; // Exit the method early
            }

            // Convert the specified datetime string to a Date object
            const specifiedDate = new Date(datetime);

            // Get the current date and time
            const currentDate = new Date();

            // Compare the dates
            return currentDate > specifiedDate;
        },
        handleStoryViewInviteSubmit(contacts) {
            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: this.tributeStoryLink,
            };

            this.axiosInstance
                .post(`/TributeStory/story-player-invite/${this.tributeVideo.id}`, data)
                .then(resp => {
                    this.showSnackbar({ message: 'Invitations sent' });
                    this.$refs.storyViewShareModal.reset();
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401) {
                        this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                    } else {
                        this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                    }
                });
        },
        initStoryViewShareModal() {
            if (this.$refs.storyViewShareModal) {
                this.$refs.storyViewShareModal.displayModal = true;
            }
        },

        initUploadShareModal() {
            this.$refs.storyUploadShareModal.displayModal = true;
        },

        cancelDownload() {
            this.downloadCancelToken.cancel('Download canceled by user.');
        },
        downloadTributeStory(url) {
            let name = `${this.tributeVideo.firstName} ${this.tributeVideo.lastName}'s' Story`;
            this.showSnackbar({ message: 'Downloading' });

            this.downloadCancelToken = this.axios.CancelToken.source();

            this.downloading = true;
            this.axios
                .get(url, {
                    responseType: 'blob',
                    onDownloadProgress: progressEvent => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        this.downloadProgress = progress;
                    },
                    cancelToken: this.downloadCancelToken.token,
                })
                .then(response => {
                    const blob = new Blob([response.data], { type: 'video/mp4' });
                    const blobUrl = window.URL.createObjectURL(blob);
                    const anchor = document.createElement('a');
                    anchor.style.display = 'none';
                    anchor.href = blobUrl;
                    anchor.download = name;
                    document.body.appendChild(anchor);
                    anchor.click();
                    window.URL.revokeObjectURL(blobUrl);
                    this.showSnackbar({ message: 'Download complete' });
                })
                .catch(error => {
                    console.log(error);
                    if (this.axios.isCancel(error)) {
                        this.showSnackbar({ message: 'Download Canceled', color: 'error' });
                    } else {
                        this.showSnackbar({ message: 'Error downloading video', color: 'error' });
                    }
                })
                .finally(() => {
                    this.downloading = false;
                    this.downloadCancelToken = null;
                    this.downloadProgress = 0;
                });
        },
        handleRefreshLink(linkType) {
            let val = null;
            if (linkType == 'family') {
                val = 1;
            } else if (linkType == 'contributor') {
                val = 0;
            } else if (linkType == 'story') {
                val = 2;
            } else {
                this.showSnackbar({ message: 'Invalid link type', color: 'error' });
                return;
            }

            this.axiosInstance
                .post(`/TributeVideo/refresh-branch-link/${this.tributeVideo.id}/${val}`)
                .then(resp => {
                    if (val == 0) {
                        this.tributeVideo.deeplink = resp.data.deeplink;
                    }
                    if (val == 1) {
                        this.tributeVideo.familyLink = resp.data.familyLink;
                    }
                    if (val == 2) {
                        this.tributeVideo.storyContributeLink = resp.data.storyContributeLink;
                    }
                    this.$refs.storyUploadShareModal.refresh();
                })
                .catch(err => {
                    console.log('link refresh errror', err);
                });
        },
        handleInvalidEmail(email) {
            this.showSnackbar({ message: `${email} is not a valid email`, color: 'error' });
        },
        handleInviteSubmit(contacts) {
            let data = {
                emailList: contacts.emailList.join(','),
                phoneList: contacts.phoneList.join(','),
                url: this.tributeVideo.storyContributeLink,
            };
            this.$refs.storyUploadShareModal.reset();

            this.axiosInstance
                .post(`/TributeStory/invite/${this.tributeVideo.id}`, data)
                .then(resp => {
                    this.showSnackbar({ message: 'Invitations sent' });
                })
                .catch(err => {
                    console.log(err);
                    if (err.response.status === 401) {
                        this.showSnackbar({ message: 'Unauthorized access', color: 'error' });
                    } else {
                        this.showSnackbar({ message: 'Error sending invitations', color: 'error' });
                    }
                })
                .finally(() => {
                    this.uploadModal = false;
                });
        },
        handleUploaderOpen() {
            if (this.$refs.storyUploader) {
                this.$refs.storyUploader.handleOpen();
            }
        },

        async handleChange(e) {
            var movedEl = e.moved.element;

            const targetOrder = this.total - 1 - e.moved.newIndex;

            await this.updateOrder(movedEl.id, targetOrder);

            await this.refreshTributeStories(this.event.id);
        },
        initDeleteStory(story) {
            this.selectedStory = { ...story };
            this.deleteStoryModal = true;
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async updateOrder(id, index) {
            return this.axiosInstance.put(`TributeStory/update-order/${id}/${index}`);
        },
        handleThumbnail(url, type) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations
            //https://ik.imagekit.io/demo/sample-video.mp4/ik-thumbnail.jpg
            //https://ik.imagekit.io/memoryshare/templates/fa6da4d5-b142-40ac-a67f-efdc1acd0523.mp4/ik-thumbnail.jpg?tr=so-5

            const thumbNailMod = '/ik-thumbnail.jpg?tr=so-2';
            let srcPath = '';
            let poster = '';

            if (type == 'story') {
                srcPath = url.split('/tribute-stories/')[1];
                poster = `${process.env.VUE_APP_IMG_KIT_BASE}stories/${srcPath}${thumbNailMod}`;
            } else if (type == 'story-render') {
                srcPath = url.split('/tribute-story-renders/')[1];
                poster = `${process.env.VUE_APP_IMG_KIT_BASE}story-renders/${srcPath}${thumbNailMod}`;
            }

            return poster;
        },
        async refreshTributeStories(eventId, params) {
            let results = await this.getTributeStories(eventId, params);

            if (results) {
                this.stories = results.data.tributeStories;
                this.total = results.data.total;
            }
        },
        getTributeStories(eventId, params) {
            this.loading = true;
            return this.axiosInstance
                .get(`/TributeStory/event-stories/${eventId}`, { params: params })
                .then(resp => {
                    //Used to handle video reload when imageKit realtime thumbnail not ready
                    let tributeStoriesWithReplayCount = resp.data.tributeStories.map(story => {
                        return {
                            ...story,
                            replayCount: 0,
                        };
                    });
                    resp.data.tributeStories = tributeStoriesWithReplayCount;
                    return resp;
                })
                .catch(error => {
                    console.log('error', error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async loadMoreStories() {
            if (this.stories.length < this.total && !this.loading) {
                this.pageNumber++;
                let newResults = await this.getTributeStories(this.event.id, {
                    pageNumber: this.pageNumber,
                    pageSize: this.pageSize,
                });

                if (newResults) {
                    let combined = this.stories.concat(newResults.data.tributeStories);
                    this.stories = combined;
                }
            }
        },
        initObserver() {
            let options = {
                root: null,
                rootMargin: '0px',
                treshold: 0.1,
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && !this.loading) {
                        this.loadMoreStories();
                    }
                });
            }, options);
            this.observer = observer;

            let target = document.querySelector('#infinite-scroll-story-target');
            observer.observe(target);
        },
        async confirmDelete(id) {
            try {
                const resp = await this.axiosInstance.delete(`/TributeStory/${id}`);
                this.deleteStoryModal = false;
                this.selectedStory = null;

                let tempPageSize = this.pageNumber * this.pageSize + this.pageSize;
                this.refreshTributeStories(this.event.id, {
                    pageNumber: 0,
                    pageSize: tempPageSize,
                });
            } catch (error) {
                console.log(error, 'error');
            }
        },

        deleteRenderStep1() {
            this.deleteRenderModal = true;
        },
        confirmDeleteRender(id) {
            this.loading = true;
            this.axiosInstance
                .delete(`/TributeStory/render/${id}`)
                .then(resp => {
                    this.showSnackbar({ message: 'Render Deleted' });
                    this.render = null;
                })
                .catch(error => {
                    console.log(error, 'error');
                    this.showSnackbar({ message: 'Error deleting render', color: 'error' });
                })
                .finally(() => {
                    this.deleteRenderModal = false;
                    this.loading = false;
                });
        },
        handleRenderError() {
            const videoEl = this.$refs['renderPlayer'];
            const maxAttempts = 5;

            if (this.posterReloadCount < maxAttempts) {
                setTimeout(() => {
                    // console.log(`reloading render | ${this.reloadCount}`);
                    videoEl.poster = this.handleThumbnail(this.render.url, 'story-render');
                    this.posterReloadCount++;
                }, 1000);
            }
        },
        handleStoryError(index) {
            const maxAttempts = 5;
            const videoEl = this.$refs['storyPlayer' + index][0];
            // console.log(videoEl.error, 'error message');

            //imageKit thumbnail generation takes time on initial generate;
            //reset poster if this throws error
            if (this.stories[index].replayCount < maxAttempts) {
                setTimeout(() => {
                    // console.log(`reloading video | ${index} : ${this.stories[index].replayCount}`);
                    videoEl.poster = this.handleThumbnail(this.stories[index].url, 'story');
                    this.stories[index].replayCount++;
                }, 1000);
            }
        },
        checkVideoOrientation(url) {
            return new Promise((resolve, reject) => {
                const video = document.createElement('video');
                video.preload = 'metadata';

                // Event handler when metadata is loaded
                video.onloadedmetadata = function () {
                    // Get the orientation from the video's metadata
                    const orientation = video.videoWidth > video.videoHeight ? 'landscape' : 'portrait';
                    resolve(orientation);
                };

                // Event handler if an error occurs
                video.onerror = function () {
                    reject(new Error('Unable to load the video.'));
                };

                // Load the video from the URL
                video.src = url;
            });
        },
        async getFileSize(url) {
            try {
                const resp = await this.axios.head(url);
                const fileSize = resp.headers['content-length'];
                return parseInt(fileSize);
            } catch (err) {
                console.log(err, 'file resp error');
                return null;
            }
        },
        async compareFileSizes() {
            const files = {
                creatomate: 'https://cdn.creatomate.com/renders/117091b9-ef60-408f-a817-c633cb66647c.mp4',
                azure: 'https://videos-dev.memoryshare.com/tribute-story-renders/117091b9-ef60-408f-a817-c633cb66647c.mp4',
            };

            const creatomate_size = await this.getFileSize(files['creatomate']);
            const azure_size = await this.getFileSize(files['azure']);
        },
    },
    components: {
        TributeStoryUploader,
        draggable,
        CustomLoader,
        CustomTooltip,
        ShareUploadModal,
        BrandedModal,
        LinkShareModal,
    },
    computed: {
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        disableRenderBtn() {
            if (this.loading || this.render?.status == 0 || this.render?.status == 1) {
                return true;
            }
            return false;
        },
        disableContribute() {
            return this.render || this.checkDatePassed(this.story.deadline);
        },
    },
    sockets: {
        async NotifyUpload(data) {
            if (this.event.id) {
                if (data.id == this.event.id) {
                    let tempPageSize = this.pageNumber * this.pageSize + this.pageSize;

                    this.refreshTributeStories(this.event.id, {
                        pageNumber: 0,
                        pageSize: tempPageSize,
                    });
                }
            }
        },
    },

    async mounted() {
        if (this.tributeToken) {
            this.token = this.tributeToken;
        } else {
            await this.setAuthToken();
        }

        this.createAxiosInstance();

        if (this.event.id) {
            //await this.getStoryEvent(this.tributeVideo.storyEventId);

            await this.refreshTributeStories(this.event.id, {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
            });
            setTimeout(() => {
                this.initObserver();
            }, 200);

            this.$emit('mounted');
        }

        if (this.service) {
            this.tributeStoryLink = process.env.VUE_APP_BASE_URI + '/view-story/' + this.service.slug;
        }
    },
    beforeDestroy() {
        let target = document.querySelector('#infinite-scroll-story-target');
        if (target && this.observer) {
            this.observer.unobserve(target);
        }
    },
};
</script>
<style lang="scss" scoped>
.story-name-label {
    border-radius: 5px;
    transition: 0.2s;
}

.story-name-label:hover {
    background-color: #f5f5f5;
}
.pointer {
    cursor: pointer !important;
}
.moderated-status-label {
    position: absolute;
    top: -8px;
    left: -8px;
    z-index: 1;
}
.grid {
    cursor: pointer;
    display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(150px, min-content));
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    justify-items: start;
    grid-gap: 5px;
}

.grid-item {
    max-width: 200px;
    width: 100%;
    padding: 4px;
}
.anchor {
    text-decoration: underline;
    cursor: pointer;
    color: #ff530d;
}
.anchor:hover {
    color: #fb8c00;
}
.grab {
    cursor: move;
    cursor: grab;
}
.video-container {
    // border: 1px solid;
    position: relative;
    width: 100%;
    aspect-ratio: 9/16;
    // aspect-ratio: 16/9;
    // height: 0;
    // padding-bottom: 177.78%; /* Set the aspect ratio based on the video's height and width */
    background-color: black;
    border-radius: 5px;
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    //object-fit: cover; /* Adjust the object-fit property as needed */
    object-fit: contain; /* Adjust the object-fit property as needed */
}

.error-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 18px;
    text-align: center;
}

.del-action-btn {
    position: absolute;
    z-index: 2;
    top: 10px;
    right: 10px;
    opacity: 0;
    height: 20px !important;
    width: 20px !important;
}

.video-container:hover .del-action-btn {
    opacity: 0.4;
    // border: 2px solid red;
}

.del-action-btn:hover {
    opacity: 1 !important;
}

.tribute-player {
    padding: 0;
    margin: 0;
}
</style>
