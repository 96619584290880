import axios from 'axios';

const ApiService = {
    createApiInstance(token) {
        const apiInstance = axios.create({
            baseURL: process.env.VUE_APP_API,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return {
            get(resource, config = {}) {
                return apiInstance.get(resource, config);
            },

            post(resource, data, config = {}) {
                return apiInstance.post(resource, data, config);
            },

            put(resource, data, config = {}) {
                return apiInstance.put(resource, data, config);
            },

            delete(resource, config = {}) {
                return apiInstance.delete(resource, config);
            },
        };
    },
};

export default ApiService;
