<template>
    <div>
        <v-row class="mb-2">
            <v-col>
                <div id="tribute-avatar" style="display: flex; align-items: center; flex-grow: 1; gap: 12px">
                    <v-avatar @click="openMainPhotoModal" class="pointer" v-if="tributeVideo.mainPhotoUrl" size="70">
                        <v-img
                            :src="handleMainImgSrc(tributeVideo.mainPhotoUrl)"
                            :lazy-src="handleMainImgSrc(tributeVideo.mainPhotoUrl)"
                            alt="Avatar"
                        />
                    </v-avatar>

                    <v-avatar
                        v-else
                        color="#f5f5f5"
                        @click="openMainPhotoModal"
                        class="pointer text-uppercase"
                        size="70"
                    >
                        <h3 class="m-0 p-0 te">
                            <span v-if="service.firstName.length > 0">{{ service.firstName[0] }}</span>
                            <span v-if="service.lastName.length > 0">{{ service.lastName[0] }}</span>
                        </h3>
                    </v-avatar>

                    <div class="mr-4" style="display: flex; flex-direction: column; justify-content: center">
                        <h3>Tribute Video</h3>
                        <div class="d-flex align-items-center" style="color: #999999">
                            <p @click="openSettingsModal" class="m-0 pointer deadline-link">
                                <!-- {{ service.firstName }} {{ service.lastName }} -->
                                <font-awesome-icon icon="fa-regular fa-calendar-circle-exclamation" />
                                <span v-if="tributeVideo.deadline">
                                    {{ $moment.utc(tributeVideo.deadline).local().format('MMM Do, h:mm a') }}
                                </span>
                                <span class="text-decoration-underline ml-2" v-else>Set a Deadline </span>
                            </p>
                        </div>
                    </div>
                    <div class="chip-container" style="display: flex; gap: 0.5rem; align-items: center">
                        <span v-if="event.hidden" :style="{ '--background-color': '#fb8c00' }" class="chip">
                            <strong>Hidden</strong>
                        </span>
                    </div>
                </div>
            </v-col>

            <!-- START: Tabs -->
            <v-col cols="auto">
                <v-tabs class="mb-1" show-arrows center-active v-model="innerTabIndex">
                    <v-tab v-for="(tab, index) in tabs" :key="index" :href="'#' + tab.value">{{ tab.label }}</v-tab>
                </v-tabs>
            </v-col>
            <!-- END: Tabs -->
        </v-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            innerTabIndex: this.tabIndex,
        };
    },
    watch: {
        tabIndex(newVal) {
            this.innerTabIndex = newVal;
        },
        innerTabIndex(newVal) {
            this.$emit('tab-index', newVal);
        },
    },
    props: {
        tributeVideo: {
            type: Object,
            default: () => {},
        },
        event: {
            type: Object,
            default: () => {},
        },
        service: {
            type: Object,
            default: () => {},
        },
        standardRender: {
            type: Object,
            default: () => {},
        },
        copyrightSafeRender: {
            type: Object,
            default: () => {},
        },
        tabIndex: {
            type: String,
            default: 'share',
        },
        tabs: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        handleMainImgSrc(url) {
            if (url && url.includes('/tribute-photos/')) {
                const imgPath = url.split('/tribute-photos/')[1];
                return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-500,h-500,fo-face/' + imgPath;
            }
        },
        openMainPhotoModal() {
            this.$emit('open-main-photo-modal');
        },
        openSettingsModal() {
            this.$emit('open-settings-modal');
        },
        setTabIndex(val) {
            this.innerTabIndex = val;
        },
    },
};
</script>
<style lang=""></style>
