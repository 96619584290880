<template>
    <div>
        <!-- START: Dupe Modal -->
        <BrandedModal @close="modalVisible = false" v-model="modalVisible" max-width="1100px">
            <template #body>
                <div class="d-flex justify-space-between">
                    <h4>Duplicate Images</h4>
                    <v-btn
                        depressed
                        small
                        v-if="$auth.role.includes('SuperAdmin')"
                        @click="handleDevModeToggle(!devMode)"
                        >Dev Mode</v-btn
                    >
                </div>
                <v-divider></v-divider>

                <div class="text-center" v-if="duplicatePhotos.length == 0">No duplicate images found</div>
                <div v-else>
                    <div class="d-flex flex-wrap justify-center" style="gap: 12px">
                        <div class="d-flex" v-for="group in duplicatePhotos">
                            <div
                                class="pa-2 d-flex flex-column justify-space-between"
                                style="
                                    border: 2px solid rgb(24, 119, 242);
                                    background-color: rgb(24, 119, 242, 0.1);
                                    border-radius: 5px;
                                "
                            >
                                <div class="d-flex flex-wrap align-center" style="gap: 12px; height: 100%">
                                    <div v-for="(photo, index) in group.photos" :key="`${group.groupKey}-${index}`">
                                        <image-dedupe-item
                                            style="max-width: 195px"
                                            @delete-photo="deletePhoto(photo)"
                                            :photo="photo"
                                            :loading="loading"
                                            type="DUPLICATE"
                                            :isReference="index == 0"
                                            :devMode="devMode"
                                        />
                                    </div>
                                </div>
                                <v-btn text @click="viewGroup(group, 'DUPLICATE')">View</v-btn>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-center my-3">
                        <v-btn @click="deleteAllDupesConfirmModal = true" depressed color="error"
                            >Remove Duplicates</v-btn
                        >
                    </div>
                </div>
                <v-divider></v-divider>
                <h4>Similar Images</h4>
                <v-divider></v-divider>
                <div class="text-center" v-if="similarPhotos.length == 0">No similar images found</div>
                <div v-else class="d-flex flex-wrap justify-center" style="gap: 12px">
                    <div class="d-flex" v-for="group in similarPhotos">
                        <div
                            class="pa-2 d-flex flex-column justify-space-between"
                            style="
                                border: 2px solid rgb(24, 119, 242);
                                background-color: rgb(24, 119, 242, 0.1);
                                border-radius: 5px;
                            "
                        >
                            <div class="d-flex flex-wrap align-center" style="gap: 12px; height: 100%">
                                <div v-for="(photo, index) in group.photos" :key="`${group.groupKey}-${index}`">
                                    <image-dedupe-item
                                        style="max-width: 195px"
                                        @delete-photo="deletePhoto(photo)"
                                        :photo="photo"
                                        :loading="loading"
                                        type="SIMILAR"
                                        :isReference="index == 0"
                                        :devMode="devMode"
                                    />
                                </div>
                            </div>
                            <v-btn text @click="viewGroup(group, 'SIMILAR')">View</v-btn>
                        </div>
                    </div>
                </div>
            </template>
        </BrandedModal>
        <!-- END: Dupe Modal -->

        <!-- START: Photo  group view modal -->
        <v-dialog v-model="photoGroupViewModal" max-width="1500px">
            <v-card class="pa-2">
                <v-card-title> Photo Viewer </v-card-title>

                <v-card-text v-if="selectedPhotoGroup">
                    <div style="gap: 24px" class="d-flex justify-center">
                        <div
                            v-for="(photo, index) in selectedPhotoGroup.photos"
                            :key="`selected-view-${photo.id}`"
                            style="max-width: 700px"
                            class="d-flex flex-column align-center justify-center flex-wrap"
                        >
                            <image-dedupe-item
                                @delete-photo="deletePhoto(photo)"
                                :photo="photo"
                                :loading="loading"
                                :type="selectedGroupType"
                                :isReference="index == 0"
                                :devMode="devMode"
                                maxHeight="650px"
                                style="max-width: 100%"
                            />
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <div style="width: 100%" class="text-center">
                        <v-btn @click="photoGroupViewModal = false">Close</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- END: Photo  group view modal -->

        <!-- START: Delete all dupes confirm modal -->
        <v-dialog v-model="deleteAllDupesConfirmModal" max-width="400px">
            <v-card>
                <v-card-title> Remove all found duplicate photos? </v-card-title>
                <v-card-text> This cannot be undone, confirm to continue.</v-card-text>
                <v-card-actions class="d-flex justify-space-between">
                    <v-btn depressed @click="deleteAllDupesConfirmModal = false">Cancel</v-btn>
                    <v-btn :disabled="loading" :loading="loading" depressed color="error" @click="submitDeleteAllDupes"
                        >Delete All</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- END: Delete all dupes confirm modal -->
    </div>
</template>
<script>
import TributePhotoService from '@/services/tributePhoto.service';
import BrandedModal from '@/components/ui/BrandedModal.vue';
import ImageDedupeItem from '@/components/ManageService/Tribute/ImageDedupeItem.vue';
import { mapActions } from 'vuex';
export default {
    data() {
        return {
            duplicatePhotos: [],
            similarPhotos: [],
            loading: false,
            photoGroupViewModal: false,
            selectedPhotoGroup: null,
            selectedGroupType: null,
            deleteAllDupesConfirmModal: false,
        };
    },
    components: {
        BrandedModal,
        ImageDedupeItem,
    },
    computed: {
        modalVisible: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
    props: {
        serviceId: {
            type: Number,
            required: true,
        },
        value: {
            type: Boolean,
            required: true,
        },
        devMode: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        modalVisible(newVal) {
            if (newVal) {
                this.refreshCollectionDedupe();
            }
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        submitDeleteAllDupes() {
            console.log(this.duplicatePhotos, 'dupes');

            const photosToDelete = this.duplicatePhotos.flatMap(group => group.photos.slice(1));

            this.deletePhotoList(photosToDelete);
        },
        viewGroup(group, groupType) {
            this.photoGroupViewModal = true;
            this.selectedPhotoGroup = group;
            this.selectedGroupType = groupType;
        },
        refreshCollectionDedupe() {
            if (this.serviceId) {
                this.getCollectionDuplicates(this.serviceId);
                this.getCollectionSimilars(this.serviceId);
            }
        },
        async deletePhoto(photo) {
            if (!photo.id) {
                this.showSnackbar({ message: 'Error deleting photo', color: 'error' });
            }
            this.loading = true;
            try {
                await this.tributePhotoApiInstance.deletePhoto(photo.id);
                this.showSnackbar({ message: 'Photo deleted' });
                this.refreshCollectionDedupe();
            } catch (error) {
                console.log(error, 'error');
                this.showSnackbar({ message: 'Error deleting photo', color: 'error' });
            }
            this.loading = false;
        },
        async deletePhotoList(items) {
            this.loading = true;

            try {
                const idsToDelete = items.map(obj => obj.id);
                await this.tributePhotoApiInstance.deletePhotoBatch(idsToDelete);
                this.refreshCollectionDedupe();
                this.deleteAllDupesConfirmModal = false;
            } catch (error) {
                console.log(error, 'error');
            } finally {
                this.loading = false;
            }
        },
        async getCollectionDuplicates(serviceId) {
            if (!serviceId) return;
            try {
                var resp = await this.tributePhotoApiInstance.getCollectionDuplicates(serviceId);
                if (resp.data.results) {
                    this.duplicatePhotos = resp.data.results;
                }
            } catch (error) {
                console.log(error, 'duplicates error');
            }
        },
        async getCollectionSimilars(serviceId) {
            if (!serviceId) return;

            try {
                var resp = await this.tributePhotoApiInstance.getCollectionSimilars(serviceId);
                if (resp.data.results) {
                    this.similarPhotos = resp.data.results;
                }
            } catch (error) {
                console.log(error, 'similars error');
            }
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        handleDevModeToggle(devModeStatus) {
            this.$emit('handleDevModeToggle', devModeStatus);
        }
    },
    async mounted() {
        await this.setAuthToken();

        this.tributePhotoApiInstance = TributePhotoService(this.token);

        if (this.serviceId) {
            this.getCollectionDuplicates(this.serviceId);
            this.getCollectionSimilars(this.serviceId);
        }
    },
};
</script>
<style lang=""></style>
