<template>
    <img :src="previewUrl" :alt="$props.alt" v-if="previewUrl">
</template>

<script>
// Takes a file that is pending upload, and generate a preview image as well as any clean up like revoking Object URL to prevent memory leaks.
import fallback from '@/assets/images/icon_dark.png';
export default {
    name: 'imagePreview',
    props: {
        media: {
            type: [Object, File, Blob, MediaSource],
            required: true
        },
        altText: {
            type: String,
            default: '',
        }
    },
    data() {
        return {
            previewUrl: null,
            fallbackImage: fallback
        }
    },
    methods: {
        createURL() {
            // console.log('creating object url...', this.$props);
            // TODO: Handle varius type of media here? Right now it assumes an oject coming from SlideUpload component
            this.previewUrl = URL.createObjectURL(this.$props.media.data);
        },
        revokeURL() {
            if (this.previewUrl) {
                // console.log('image url revoked');
                URL.revokeObjectURL(this.previewUrl);
            }
        }
    },
    watch: {
        media: {
            deep: true,
            immediate: true,
            handler(newVal, oldVal) {
                if (newVal && newVal !== oldVal) {
                    if (this.previewUrl?.length) {
                        this.revokeURL();
                    }
                    this.$nextTick(() => {
                        this.createURL();
                    });
                }
            }
        }
    },
    beforeDestroy() {
        this.revokeURL();
    }
}
</script>

<style lang="scss" scoped>
</style>