<!-- Refactored version of TributePhotos.vue -->

<template>
    <div>
        <div
            v-if="outOfSyncPhotos.length > 0 && disableGallery"
            class="gallery-container"
            :class="outOfSyncPhotos.length > 0 ? 'top-bottom-borders' : ''"
            style="overflow: scroll"
        >
            <p>
                <strong>Out Of Sync Slides</strong>
            </p>
            <div class="grid no-gutters">
                <gallery-item
                    ref="galleryItems"
                    v-for="(item, index) in outOfSyncPhotos"
                    :id="`out-of-sync-item-${item.id}`"
                    class="grid-item"
                    :key="'out-of-sync-item' + item.id + '-' + item.refreshKey"
                    :item="item"
                    :index="index"
                    :max-index="outOfSyncPhotos.length - 1"
                    :devMode="devMode"
                    :eventId="tributeVideo.eventId"
                    :token="token"
                    :disabled="true"
                    :showSyncLabel="true"
                    :thumbnailOnly="thumbnailsOnly"
                    gallery-type="out-of-sync"
                    @custom-context="data => handleCustomContext(data.evt, data.item)"
                    @delete="item => deleteStep1(item)"
                    @rotate="item => rotateHandler(item)"
                    @toggle-mute="handleMuteToggle(item.id, !item.mute)"
                ></gallery-item>
            </div>
            <v-divider></v-divider>
        </div>

        <div class="gallery-wrap">
            <div style="height: 5px">
                <v-progress-linear v-if="loading || deleteInProgress" rounded indeterminate></v-progress-linear>
            </div>

            <div class="py-1 d-flex justify-space-between text-caption">
                <span>
                    <p class="ma-0" v-if="selectedItems.length > 0">Selected: {{ selectedItems.length }}</p>
                </span>
                <p class="ma-0">Viewing {{ tributeVideo.photos.length }} of {{ tributeVideo.totalPhotos }}</p>
            </div>

            <div class="gallery-section">
                <div v-if="disableGallery && !devMode" class="gallery-overlay">
                    <v-btn class="gallery-overlay-btn" @click.stop="initGalleryReenableModal" dark color="warning"
                        >Make Changes</v-btn
                    >
                </div>

                <!-- /*@item-dblclick="data => handleGalleryItemDblClick(data.evt, data.item)"*/ -->
                <div
                    id="gallery-container"
                    class="gallery-container"
                    :class="[
                        blockOverscroll ? 'block-overscroll' : '',
                        scrollable ? '' : 'no-max-height',
                        photos.length > 0 ? 'top-bottom-borders' : '',
                    ]"
                >
                    <div v-if="!endReached" class="d-flex justify-center">
                        <v-btn
                            :loading="loading"
                            :disabled="loading"
                            class="m-3"
                            depressed
                            @click="triggerInfiniteScrollNext"
                            >Load More</v-btn
                        >
                    </div>
                    <div ref="draggableContainer" id="draggable-container" class="grid no-gutters">
                        <gallery-item
                            ref="galleryItems"
                            v-for="(item, index) in reversedPhotos"
                            :id="`gallery-item-${item.id}`"
                            class="grid-item"
                            :key="'main-gallery-item' + item.id + '-' + item.refreshKey"
                            :class="[{ 'sortable-selected': checkSelected(item) }]"
                            :item="item"
                            :index="index"
                            :max-index="photos.length - 1"
                            :devMode="devMode"
                            :eventId="tributeVideo.eventId"
                            :token="token"
                            :disabled="disableGallery"
                            :showSyncLabel="!disableGallery"
                            :thumbnailOnly="thumbnailsOnly"
                            @custom-context="data => handleCustomContext(data.evt, data.item)"
                            @item-click="data => handleGalleryItemClick(data.evt, data.item)"
                            @delete="item => deleteStep1(item)"
                            @rotate="item => rotateHandler(item)"
                            @toggle-mute="handleMuteToggle(item.id, !item.mute)"
                            @gallery-top-intersect="triggerInfiniteScrollNext"
                            @gallery-bottom-intersect="handleGalleryBottomIntersect"
                        ></gallery-item>
                    </div>
                </div>
            </div>

            <div class="d-flex justify-space-between mt-1">
                <div v-if="$auth.role.includes('SuperAdmin')" class="d-flex" style="gap: 8px">
                    <input type="checkbox" id="showFileNamesToggle" v-model="thumbnailsOnly" />
                    <span class="text-caption">Thumbnails Only</span>
                </div>
                <div v-else></div>
                <div v-if="tributeVideo.maxItems" class="text-caption text-right">
                    {{ tributeVideo.totalPhotos }} / {{ tributeVideo.maxItems }} Max Items
                </div>
            </div>

            <v-dialog eager v-model="imglyModal" fullscreen style="z-index: 1002">
                <v-card>
                    <div class="text-right pa-2">
                        <v-btn @click="imglyModal = false" depressed>Close</v-btn>
                    </div>
                    <div v-if="showImageError" class="image-error-div">
                        <div v-if="imageErrorisHeic">
                            <p>Unsupported image type (HEIC) for image editor, image queued for conversion</p>
                        </div>
                        <div class="text-center" v-else>
                            <p>An error occured while loading the image.</p>
                            <v-btn @click="handleDeleteEmit(imageToEdit)" depressed color="error">Delete Image</v-btn>
                        </div>
                    </div>

                    <div v-else class="image-error-div">
                        <v-progress-circular size="50" indeterminate color="primary"></v-progress-circular>
                    </div>
                    <div>
                        <PhotoEditor
                            ref="photoEditor"
                            :photo="imageToEdit"
                            :visible="imglyModal"
                            :theme="imglyConfig.theme"
                            :layout="mobile ? 'basic' : 'advanced'"
                            :image-path="path"
                            :blank-image="blankImage"
                            :token="token"
                            :eventId="event.id"
                            @close="closeImglyModal"
                            @export-success="replacement => handleExport(replacement)"
                            @delete="handleDeleteEmit(imageToEdit)"
                            @revert-original="handleRevertEmit(imageToEdit)"
                            @image-error="handleEditorImageError(path)"
                        />
                    </div>
                </v-card>
            </v-dialog>

            <v-dialog v-model="deleteModal" :loading="deleteInProgress" max-width="400px">
                <v-card>
                    <v-card-title>
                        Delete {{ selectedItems.length }} Photo{{ selectedItems.length > 1 ? 's' : '' }}?
                    </v-card-title>
                    <v-card-text>This cannot be undone, confirm to continue</v-card-text>

                    <v-card-actions class="d-flex justify-space-between">
                        <v-btn depressed @click="deleteModal = false">Cancel</v-btn>
                        <v-btn
                            :loading="deleteInProgress || loading"
                            :disabled="deleteInProgress || loading"
                            color="error"
                            depressed
                            @click="deletePhotoList(selectedItems)"
                            >Confirm</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="revertConfirmModal" max-width="400px">
                <v-card>
                    <v-card-title> Revert to Original? </v-card-title>
                    <v-card-text
                        >This will return the photo to the original version and any edits will be lost.
                        <strong>This cannot be undone</strong>, confirm to continue.</v-card-text
                    >

                    <v-card-actions class="d-flex justify-space-between">
                        <v-btn depressed @click="revertConfirmModal = false">Cancel</v-btn>
                        <v-btn color="error" depressed @click="revertPhoto(imageToEdit)">Confirm</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Start: video Preview Modal -->
            <v-dialog v-model="previewModal" max-width="800">
                <v-card v-if="previewItem != null" class="image-edit-card p-3">
                    <!-- <div class="text-right mb-2">
                    <v-btn @click="deletePhoto(previewItem)" x-small color="error">Delete</v-btn>
                </div> -->
                    <!-- <v-btn
                    @click="previewModal = false"
                    fab
                    small
                    style="position: absolute; top: 10px; left: 10px; z-index: 5"
                    ><font-awesome-icon :icon="['far', 'x']"
                /></v-btn> -->
                    <div>
                        <div v-if="previewItem.mediaType == 1">
                            <h4>Edit Video</h4>
                            <p style="color: #69849e">{{ previewItem.name }}</p>

                            <div style="position: relative">
                                <div
                                    @click.stop="handleMuteToggle(previewItem.id, !previewItem.mute)"
                                    class="mute-action-overlay"
                                >
                                    <span v-if="previewItem.mute"
                                        >Video Muted
                                        <font-awesome-icon
                                            class="ml-2"
                                            icon="fa-regular fa-volume-xmark"
                                        ></font-awesome-icon
                                    ></span>
                                    <span v-else
                                        >Mute Video
                                        <font-awesome-icon class="ml-2" icon="fa-regular fa-volume"></font-awesome-icon
                                    ></span>
                                </div>
                                <video
                                    controls
                                    :muted="previewItem.mute"
                                    class="preview-video"
                                    :src="previewItem.url"
                                ></video>
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-dialog>
            <!-- End: video Preview Modal -->

            <custom-context-menu
                ref="customContext"
                id="customContext"
                :items="contextItems"
                @item-click="e => handleContextItemClick(e)"
                :refreshKey="contextRefreshKey"
            ></custom-context-menu>

            <!-- @closed="selectedItems = []" -->

            <v-dialog max-width="500px" v-model="galleryReenableModal">
                <v-card>
                    <v-card-title>Make Changes?</v-card-title>
                    <v-card-text
                        >This tribute video has already been rendered. If any changes are made, the video will need to
                        be rerendered for changes to take effect.</v-card-text
                    >
                    <v-card-actions class="d-flex justify-space-between">
                        <v-btn @click="galleryReenableModal = false" depressed>Cancel</v-btn>
                        <v-btn @click="reenableGallery" depressed color="warning">Confirm</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>
<script>
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';
import { uploadSources } from '@/constants';
import PhotoEditor from '@/components/ui/PhotoEditor.vue';
import CustomContextMenu from '@/components/ui/CustomContextMenu.vue';
import JSZip from 'jszip';
import { debounceV2 } from '@/utilities/debounce.js';
import { Sortable, MultiDrag } from 'sortablejs';
import GalleryItem from '@/components/ManageService/Tribute/GalleryItem.vue';
import { setDictInLocalStorage, getDictFromLocalStorage } from '@/utilities/general';
import { checkHeicFtyp } from '@/utilities/imageProcessing';
import TributePhotoService from '@/services/tributePhoto.service';

export default {
    data() {
        return {
            message: 'hi',
            selectedPhoto: {
                id: '',
                order: 1,
            },
            blockOverscroll: false,
            uploadSources,
            draggedElem: null,
            overElem: null,
            token: '',
            dropIndex: null,
            reorderSortAsc: false,
            imageToEdit: null,
            imageEditModal: false,
            retryCount: 0,
            // totalPhotos: 0,
            // pageNumber: 0,
            // pageSize: 24,
            dragging: false,
            timeoutId: null,
            deleteModal: false,
            photoRefreshKey: 0,
            imglyKey: 0,
            quickActionItems: [
                // { label: 'Rotate Left', icon: 'fa-solid fa-rotate-left', value: 1 },
                // { label: 'Rotate Right', icon: 'fa-solid fa-rotate-right', value: 2 },
                { label: 'Preview', icon: 'fa-solid fa-image', value: 3 },
                { label: 'Download', icon: 'fa-solid fa-arrow-down-to-bracket', value: 4 },
                { label: 'Delete', icon: 'fa-solid fa-trash', value: 5 },
            ],
            observerStart: null,
            observerEnd: null,
            awaitingPhotoLoad: false,
            loading: false,
            lockGalleryScroll: true,
            revertConfirmModal: false,
            imglyModal: false,
            imglyConfig: {
                theme: 'light',
                // layout: 'advanced',
                // layout: 'basic',
                license: '{"owner":"Imgly Inc.","version":"2.4"}',
            },
            contextRefreshKey: 0,
            path: '',
            blankImage: false,
            showImageError: false,
            previewModal: false,
            previewItem: null,
            selectedItems: [],
            rotateTimers: {},
            placeholderImg: null,
            testKey: 0,
            photoRotation: 0,
            sortable: null,
            rotatingImages: false,
            deleteInProgress: false,
            guidStorageName: 'ms-upload-guids',
            contextItems: [],
            outOfSyncPhotos: [],
            disableGallery: false,
            galleryReenableModal: false,
            mounted: false,
            previousScrollHeightMinusScrollTop: 0,
            imageErrorisHeic: false,
            keyboardScroll: false,
            clickTimeout: null,
            thumbnailsOnly: true,
            guidStorageName: 'ms-upload-guids',
            preventDragEndEvt: false,
            displaySlidesReversed: true,
        };
    },
    components: {
        draggable,
        PhotoEditor,
        CustomContextMenu,
        GalleryItem,
    },
    props: {
        event: {
            type: Object,
            required: true,
        },
        service: {
            type: Object,
            required: true,
        },
        devMode: {
            type: Boolean,
        },
        showProgress: {
            type: Boolean,
            default: false,
        },
        tributeToken: {
            type: String,
            required: false,
        },
        scrollable: {
            required: false,
            default: true,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isContributeTributePage() {
            return this.$route.name == 'TributeUploadPage';
        },
        isFamilyTributePage() {
            return this.$route.name == 'TributeFamilyPage';
        },
        photos: {
            get() {
                return this.$store.state.tributeVideo.photos.map(photo => {
                    return { ...photo, currentRotation: 0, refreshKey: 0 };
                });
            },
            set(value) {
                this.updateTributeVideoPhotos(value);
            },
        },
        pageNumber: {
            get() {
                return this.$store.state.tributeVideo.photosOptions.pageNum;
            },
            set(val) {
                this.updateTributeVideoPhotosOptions({ pageNum: val });
            },
        },
        pageSize: {
            get() {
                return this.$store.state.tributeVideo.photosOptions.pageSize;
            },
            set(val) {
                this.updateTributeVideoPhotosOptions({ pageSize: val });
            },
        },
        reversedPhotos: {
            get() {
                return this.photos.slice().reverse();
            },
        },
        selectedPhotos: {
            get() {
                return this.$store.state.tributeVideo.selectedPhotos;
            },
            set(value) {
                this.updateTributeVideoSelectedPhotos(value);
            },
        },
        totalPhotos: {
            get() {
                return this.$store.state.tributeVideo.totalPhotos;
            },
            set(val) {
                this.updateTributeVideo({ totalPhotos: val });
            },
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
        computedKey(item) {
            return item => `${item.id}-${item.refreshKey}`;
        },
        endReached() {
            return this.photos.length === this.totalPhotos;
        },
    },
    methods: {
        ...mapActions(['showSnackbar']),
        ...mapActions('tributeVideo', [
            'updateGalleryItem',
            'updateTributeVideo',
            'updateTributeVideoPhotos',
            'updateTributeVideoSelectedPhotos',
            'updateTributeVideoPhotosOptions',
        ]),
        async handleEditorImageError(path) {
            const isHeic = await checkHeicFtyp(path);

            this.showImageError = true;
            this.imageErrorisHeic = isHeic;
        },
        async reenableGallery() {
            this.disableGallery = false;
            this.galleryReenableModal = false;
            if (this.tributeVideo.eventId) {
                await this.updatePhotoStore(0, this.pageSize, false);
            }

            if (this.galleryReenableCallback && typeof this.galleryReenableCallback === 'function') {
                this.galleryReenableCallback();
                this.galleryReenableCallback = null;
            }
        },
        initGalleryReenableModal(callback = null) {
            this.galleryReenableModal = true;

            if (callback && typeof callback === 'function') {
                this.galleryReenableCallback = callback;
            }
        },
        checkSelected(item) {
            const found = this.selectedItems.find(x => x.id === item.id);
            if (found) {
                return true;
            }
            return false;
        },
        handleOutsideClick(e) {
            var insideClick = false;

            const galleryItems = this.$refs.galleryItems;
            if (!galleryItems) return;

            galleryItems.forEach(elem => {
                if (elem.$el.contains(e.target)) {
                    insideClick = true;
                }
            });

            const contextEl = document.getElementById('context-menu');
            if (contextEl) {
                if (contextEl.contains(e.target)) {
                    insideClick = true;
                }
            }

            if (this.deleteModal) {
                insideClick = true;
            }
            const isOutside = !insideClick;

            if (isOutside) {
                this.deselectAllMultiDrag();
            }
        },
        handleGalleryItemClick(event, item) {
            if (this.clickTimeout) {
                clearTimeout(this.clickTimeout);
                this.clickTimeout = null;
                this.handleGalleryItemDblClick(event, item);
            } else {
                this.handleGalleryItemSingleClick(event, item);
                this.clickTimeout = setTimeout(() => {
                    this.clickTimeout = null;
                }, 300);
            }
        },
        handleGalleryItemSingleClick(event, item, contextClick = false) {
            if (!item) return;

            if (!contextClick && this.selectedItems.length == 1 && this.selectedItems[0] == item) {
                this.toggleSelected(item);
                return;
            }

            if (event.ctrlKey || event.metaKey) {
                this.toggleSelected(item);
                return;
            }

            if (event.shiftKey) {
                this.handleShiftClick(item);
                return;
            }

            this.selectedItems = [item];
            this.syncMultiDragSelection();
        },
        async handleGalleryItemDblClick(event, item) {
            if (event.ctrlKey || event.metaKey || event.shiftKey) return;

            if (item.mediaType == 1) {
                this.handlePreview(item);
            } else {
                if (this.$route.name == 'TributeUploadPage') {
                    const guid = await this.getGuidById(item.id);
                    if (!guid) {
                        this.showSnackbar({
                            message: 'Unauthorized access. Cannot edit photo at this time',
                            color: 'error',
                        });

                        this.deselectAllMultiDrag();
                        return;
                    }
                }

                this.editImage(item);
            }
        },
        handleShiftClick(item) {
            const selectedItems = [...this.selectedItems];

            const found = this.selectedItems.find(x => x.id == item.id);

            if (selectedItems.length === 0) {
                if (!found) {
                    this.sortableSelectItem(item);
                }
            } else if (selectedItems.length > 0) {
                const firstItem = selectedItems[0];

                const firstIndex = this.photos.indexOf(firstItem);

                const lastIndex = this.photos.indexOf(item);

                const newSelectedRange = [];

                if (firstIndex <= lastIndex) {
                    for (let i = firstIndex; i <= lastIndex; i++) {
                        const currentItem = this.photos[i];
                        newSelectedRange.push(currentItem);
                    }
                } else {
                    for (let i = firstIndex; i >= lastIndex; i--) {
                        const currentItem = this.photos[i];
                        newSelectedRange.push(currentItem);
                    }
                }

                this.selectedItems = newSelectedRange;
                this.syncMultiDragSelection();
            }
        },
        toggleSelected(item) {
            const found = this.selectedItems.find(x => x.id == item.id);

            if (found) {
                const index = this.selectedItems.indexOf(found);
                this.selectedItems.splice(index, 1);
            } else {
                this.selectedItems.push(item);
            }
            this.syncMultiDragSelection();
        },
        async submitMainPhotoUpdate(eventId, photoId) {
            if (this.isContributeTributePage) {
                this.showSnackbar({
                    message: 'Unauthorized to set profile picture',
                    color: 'error',
                });
                return;
            }

            try {
                await this.tributePhotoApiService.updateProfilePhoto(eventId, photoId);
                this.showSnackbar({ message: 'Profile picture updated!' });
            } catch (error) {
                console.log(error);
                this.showSnackbar({ message: 'Error updating profile picture' });
            }
        },
        handleContextItemClick(menuItem) {
            if (this.loading) {
                this.showSnackbar({
                    message: 'Please wait until loading complete to perform this action',
                    color: 'error',
                });
                return;
            }

            switch (menuItem.value) {
                case 0:
                    this.downloadImages(this.selectedItems);
                    break;
                case 1:
                    // this.quickOrder(photo.id, this.totalPhotos - 1);
                    this.handleMoveStart(this.selectedItems);
                    break;
                case 2:
                    // this.quickOrder(item.id, 0);
                    this.handleMoveEnd(this.selectedItems);
                    break;
                case 3:
                    if (this.selectedItems.length == 1) {
                        this.editImage(this.selectedItems[0]);
                    } else {
                        this.showSnackbar({
                            message: 'Cannot edit more than one image at a time',
                            color: 'error',
                        });
                        return;
                    }
                    break;
                case 4:
                    this.rotateHandler();
                    break;
                case 5:
                    this.deletePhotoList(this.selectedItems);
                    break;
                case 6: {
                    let item = null;
                    if (this.selectedItems.length == 1) {
                        item = this.selectedItems[0];
                        this.submitMainPhotoUpdate(this.event.id, item.id);
                    } else {
                        this.showSnackbar({
                            message: 'Cannot select more than one image at a time',
                            color: 'error',
                        });
                        return;
                    }
                }
            }

            if (menuItem.value != 4) {
                this.hideCustomContext();
                this.clearSelectedItems();
            }
        },
        clearSelectedItems() {
            this.selectedItems = [];
            this.syncMultiDragSelection();
        },
        handleCustomContext(e, item) {
            const customContext = this.$refs.customContext;
            if (!customContext) return;

            customContext.handleCustomContext(e, item);

            this.handleGalleryItemSingleClick(e, item, true);
            // const index = this.selectedItems.indexOf(item);

            // if (index == -1) {
            //     this.selectedItems.push(item);
            // }

            const galleryContainer = document.getElementById('gallery-container');
            const layoutContainer = document.getElementById('scroll');

            if (galleryContainer) {
                galleryContainer.addEventListener('scroll', this.hideCustomContext);
            }
            if (layoutContainer) {
                layoutContainer.addEventListener('scroll', this.hideCustomContext);
            }

            this.syncMultiDragSelection();
        },
        hideCustomContext() {
            const customContext = this.$refs.customContext;
            if (!customContext) return;

            customContext.hideMenu();

            const galleryContainer = document.getElementById('gallery-container');
            const layoutContainer = document.getElementById('scroll');

            if (galleryContainer) {
                galleryContainer.removeEventListener('scroll', this.hideCustomContext);
            }
            if (layoutContainer) {
                layoutContainer.removeEventListener('scroll', this.hideCustomContext);
            }
        },
        async isGuidInLocal(id) {
            const uploads = await this.getLocalUploads();
            return uploads[id] !== undefined;
        },
        async areAllGuidsInLocal(arr) {
            const uploads = await this.getLocalUploads();

            for (const id of arr) {
                if (uploads[id] === undefined) {
                    return false; // Return false if any ID is not in local storage
                }
            }

            return true;
        },
        async getLocalUploads() {
            return getDictFromLocalStorage(this.guidStorageName);
        },
        async deleteStep1(item = null) {
            if (this.isContributeTributePage) {
                const isUploadUser = await this.isGuidInLocal(item.id);

                if (!isUploadUser) {
                    this.showSnackbar({ message: 'Unauthorized to delete', color: 'error' });
                    return;
                }
            }

            if (item != null) {
                const found = this.selectedItems.find(x => x.id == item.id);
                if (!found) {
                    this.toggleSelected(item);
                }
            }

            this.syncMultiDragSelection();

            this.deleteModal = true;
        },
        handleMuteToggle(id, val) {
            if (this.previewItem) {
                this.previewItem.mute = val;
            }

            let data = { mute: val };
            this.updateGalleryItem({ id: id, data: data });
            this.axiosInstance.post(`/TributeVideoPhoto/toggle-slide-mute/${id}/${val}`).then(resp => {});
        },
        async handlePreview(item) {
            //Only block based on upload user for public page, allow family page full access
            if (this.$route.name == 'TributeUploadPage') {
                const guid = await this.getGuidById(item.id);

                if (!guid) {
                    this.showSnackbar({
                        message: 'Unauthorized access. Cannot delete photo at this time',
                        color: 'error',
                    });
                    return;
                }
            }

            this.previewItem = item;
            this.previewModal = true;
        },
        handleExport(replacement = null) {
            this.imglyModal = false;
            this.imageEditModal = false;
            this.imageToEdit = null;
            this.path = '';

            if (replacement) {
                var found = this.photos.find(x => x.id == replacement.id);
                if (found) {
                    found.url = replacement.url;
                    found.loading = false;
                    found.originalPhoto = replacement.originalPhoto;
                }

                var selectedFound = this.selectedPhotos.find(x => x.id == replacement.id);
                if (selectedFound) {
                    selectedFound.url = replacement.url;
                    selectedFound.loading = false;
                    selectedFound.originalPhoto = replacement.originalPhoto;
                }
            }
        },
        closeImglyModal() {
            this.imglyModal = false;
            this.blankImage = false;
        },
        initBlankImage() {
            if (this.disableGallery) {
                this.initGalleryReenableModal(this.initBlankImage);
                return;
            }

            this.blankImage = true;
            this.path = '';
            this.imglyModal = true;
            this.imageEditModal = false;
        },
        imglyEdit(item) {
            this.path = item.url;
            this.imglyModal = true;
            this.imageEditModal = false;
        },
        multiUpdateOrder(ids, startIndex) {
            return this.axiosInstance
                .put(`TributeVideoPhoto/multi-update-order/${startIndex}`, ids)
                .then(resp => {})
                .catch(err => {
                    console.log(err, 'multi update error');
                });
        },
        async handleMultiDrag(e) {
            const sortedNewIndicies = e.newIndicies.slice().sort((a, b) => a.index - b.index);

            const startingIndex = sortedNewIndicies[0].index;

            this.selectedItems.sort((a, b) => a.order - b.order);

            const photoIds = this.selectedItems.map(obj => obj.id);

            const invertedIndex = this.photos.length - 1 - startingIndex;
            const targetOrder = this.tributeVideo.totalPhotos - 1 - invertedIndex;

            await this.multiUpdateOrder(photoIds, targetOrder);
            this.deselectAllMultiDrag();
        },
        async handleSingleDrag(e) {
            const parsedId = e.item.id.split('-')[2];
            const photo = this.photos.find(x => x.id == parsedId);
            if (photo) {
                //sortable index starts @ one, account for this since backend indexes @ 0
                const invertedIndex = this.photos.length - 1 - e.newIndex;
                const targetOrder = this.tributeVideo.totalPhotos - 1 - invertedIndex;
                await this.quickOrder(photo.id, targetOrder);
            }
        },
        async handleMoveEnd(items = []) {
            try {
                if (items.length == 0) {
                    //Only block based on upload user for public page, allow family page full access

                    this.closeImglyModal();
                    this.imageEditModal = false;
                    this.quickOrder(this.imageToEdit.id, this.tributeVideo.totalPhotos - 1);
                    this.imageToEdit = null;
                } else {
                    const reversedItems = [...items].reverse();
                    let startIndex = this.tributeVideo.totalPhotos - 1;

                    for (let i = 0; i < reversedItems.length; i++) {
                        const item = reversedItems[i];
                        await this.quickOrder(item.id, startIndex - i);
                    }
                    this.deselectAllMultiDrag();
                }
            } catch (error) {
                this.showSnackbar({ message: 'Error occured at quick order', color: 'error' });
            }
        },
        async handleMoveStart(items = []) {
            try {
                if (items.length == 0) {
                    this.closeImglyModal();
                    this.imageEditModal = false;
                    await this.quickOrder(this.imageToEdit.id, 0);
                    this.imageToEdit = null;
                } else {
                    const reversedItems = [...items].reverse();
                    for (let i = 0; i < reversedItems.length; i++) {
                        const item = reversedItems[i];
                        await this.quickOrder(item.id, i);
                    }
                    this.deselectAllMultiDrag();
                }
            } catch (err) {
                this.showSnackbar({ message: 'Error occured at quick order', color: 'error' });
            }
        },
        async handleRevertEmit(photo) {
            await this.revertPhoto(photo);
            this.path = this.imageToEdit.url;
            this.closeImglyModal();
            this.imageEditModal = false;
        },
        revertPhoto(photo) {
            if (photo.originalPhoto == null) {
                this.showSnackbar({ message: 'No photo to revert to' });
                return;
            }

            return this.axiosInstance.post(`/TributeVideoPhoto/revert-original-photo/${photo.id}`).then(resp => {
                this.revertConfirmModal = false;

                this.imageToEdit = null;
                this.imageToEdit = { ...resp.data, currentRotation: 0 };
                let found = this.photos.find(x => x.id == this.imageToEdit.id);
                if (found) {
                    let index = this.photos.indexOf(found);
                    this.photos.splice(index, 1, this.imageToEdit);
                }

                this.showSnackbar({ message: 'Original photo restored' });
            });
        },
        initRevert() {
            this.revertConfirmModal = true;
        },
        handleBase(filename) {
            var splitList = filename.split('.');
            splitList.splice([splitList.length - 1], 1);
            return splitList.join(' ');
        },
        handleExtension(filename) {
            var splitList = filename.split('.');
            return '.' + splitList[splitList.length - 1];
        },
        initQuickAction(photo, option) {
            switch (option.value) {
                case 1:
                    this.rotateImage(photo, -90);
                    break;
                case 2:
                    this.rotateImage(photo, 90);
                    break;
                case 3:
                    this.editImage(photo);
                    break;
                case 4:
                    this.downloadImages([photo]);
                    break;
                case 5:
                    this.handleDeleteEmit(photo);
                    break;
            }
        },

        updatePhotoStoreOld(pageNum, pageSize, addingPhotos, scrollToBottom = true) {
            return new Promise(async (resolve, reject) => {
                try {
                    this.updateTributeVideo({ ...this.tributeVideo, loading: true });
                    this.loading = true;

                    const result = await this.getPhotos(this.tributeVideo.eventId, {
                        pageNumber: pageNum,
                        pageSize: pageSize,
                        reversed: true,
                        // lastRenderState: this.disableGallery,
                    });

                    const newList = result.photos.map(img => {
                        return { ...img, loading: false, refreshKey: 0 };
                    });

                    if (addingPhotos) {
                        const combinedList = [...this.photos, ...newList];
                        this.updateTributeVideoPhotos(combinedList);
                    } else {
                        this.updateTributeVideoPhotos(newList);
                    }

                    if (scrollToBottom) {
                        this.scrollToGalleryBottom();
                    }

                    this.tributeVideo.totalPhotos = result.total;
                    this.updateTributeVideo({
                        loading: false,
                        totalPhotos: result.total,
                    });

                    this.loading = false;

                    // Resolve the promise with some relevant data or a success message
                    resolve('Photos updated successfully');
                } catch (error) {
                    // Reject the promise in case of an error
                    this.loading = false;
                    this.updateTributeVideo({ loading: false });
                    reject(error);
                }
            });
        },
        async updatePhotoStore(pageNum, pageSize, addingPhotos, scrollToBottom = true) {
            this.setLoadingState(true);

            try {
                const result = await this.getPhotos(this.tributeVideo.eventId, {
                    pageNumber: pageNum,
                    pageSize: pageSize,
                    reversed: true,
                    lastRenderState: this.disableGallery,
                });

                const newPhotos = this.formatPhotoList(result.photos);

                this.updatePhotoList(newPhotos, addingPhotos);

                if (scrollToBottom) {
                    this.scrollToGalleryBottom();
                }

                this.updateTributeVideo({ totalPhotos: result.total });

                const resultsEndReached = this.photos.length == result.total;
                return resultsEndReached;
            } catch (error) {
                console.log('error updating photos:', error);
            } finally {
                this.setLoadingState(false);
            }
        },
        updatePhotoList(newPhotos, addingPhotos) {
            if (addingPhotos) {
                const existingIds = new Set(this.photos.map(photo => photo.id));
                const filteredNewPhotos = newPhotos.filter(photo => !existingIds.has(photo.id));

                this.photos = [...this.photos, ...filteredNewPhotos];
            } else {
                this.photos = newPhotos;
            }
            this.updateTributeVideoPhotos(this.photos);
        },
        formatPhotoList(photos) {
            return photos.map(photo => ({
                ...photo,
                loading: false,
                refreshKey: 0,
            }));
        },
        setLoadingState(bool) {
            this.loading = bool;
            this.updateTributeVideo({ loading: bool });
        },
        imageKey(id) {
            return `imageKey-${id}${this.retryCount}`;
        },
        updatePhoto(url) {
            if (this.retryCount < 5) {
                setTimeout(() => {
                    let found = this.photos.find(x => x.url == url);
                    let dateString = Date.now();
                    //reset cached image source
                    let clone = { ...found, url: url + '?' + dateString };
                    let index = this.photos.indexOf(found);

                    this.photos.splice(index, 1, clone);
                    this.retryCount++;
                }, 1000);
            }
        },
        getPhotos(id, params) {
            return this.axios
                .create({ headers: { Authorization: `Bearer ${this.token}` } })
                .get(process.env.VUE_APP_API + `/TributeVideoPhoto/event-photos/${id}`, {
                    params: params,
                })
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    [console.log(error, 'error')];
                });
        },
        cancelEdit() {
            if (this.imageToEdit) {
                this.imageEditModal = false;
                this.imageToEdit.currentRotation = 0;
                this.animateRotation(this.imageToEdit, 0, 0, '#editImage');
                this.imageToEdit = null;
            }
        },
        async editImage(photo) {
            if (this.isContributeTributePage) {
                const isUploadUser = await this.isGuidInLocal(photo.id);

                if (!isUploadUser) {
                    this.showSnackbar({
                        message: 'Unauthorized to edit this photo',
                        color: 'error',
                    });
                    return;
                }
            }

            this.imageToEdit = { ...photo, currentRotation: 0 };
            this.imglyEdit(photo);
        },
        animateRotation(photo, deg, time, elementId = `#photo-${photo.id}`) {
            var el = null;
            el = document.querySelector(elementId);
            if (el) {
                el.style.transition = `transform ${time}s ease-in-out`;
                el.style.transform = `rotate(${deg}deg)`;
            }
        },
        async rotateHandler(item = null) {
            if (item != null) {
                const found = this.selectedItems.find(x => x.id == item.id);
                if (!found) {
                    this.toggleSelected(item);
                }
            }

            //filter out videos
            const photos = this.selectedItems.filter(x => x.mediaType != 1);

            if (this.isContributeTributePage) {
                const isUploadUser = await this.areAllGuidsInLocal(photos.map(item => item.id));

                if (!isUploadUser) {
                    this.showSnackbar({
                        message: 'Unauthorized to edit these photos',
                        color: 'error',
                    });
                    return;
                }
            }

            if (photos.length == 0) {
                this.showSnackbar({ message: 'No valid items selected', color: 'error' });
                return;
            }

            //Only block based on upload user for public page, allow family page full access
            if (this.$route.name == 'TributeUploadPage') {
                for (let i = 0; i < photos.length; i++) {
                    const photo = photos[i];
                    const guid = await this.getGuidById(photo.id);

                    if (!guid) {
                        this.showSnackbar({
                            message: 'Unauthorized access. Cannot rotate photo at this time',
                            color: 'error',
                        });
                        return;
                    }
                }
            }

            // Update the rotation degree
            this.photoRotation += 90;

            //Animate the rotation for each selected item
            photos.forEach(photo => {
                const elementId = `#photo-${photo.id}`;
                this.animateRotation(photo, this.photoRotation, 0.3, elementId);
            });

            this.debouncedMultiRotate(photos, this.photoRotation);
        },
        async rotateMultipleImages(photos, deg) {
            this.photoRotation = 0;
            this.deselectAllMultiDrag();
            this.hideCustomContext();

            const minRotation = deg % 360;
            if (minRotation == 0) {
                return;
            }

            const galleryItems = this.$refs.galleryItems;

            const selectedIds = photos.map(obj => obj.id);

            if (!galleryItems) return;

            this.rotatingImages = true;

            const selectedGalleryItems = galleryItems.filter(component => selectedIds.includes(component.item.id));

            for (let item of selectedGalleryItems) {
                item.loading = true;
            }

            for (let item of selectedGalleryItems) {
                try {
                    await item.rotateImage(deg);
                } catch (err) {
                    console.log('Error rotating image', err);
                }
            }

            this.rotatingImages = false;
            this.$emit('refresh-sync');
        },
        async downloadImages(photos) {
            try {
                this.loading = true;
                const promises = photos.map(async image => {
                    if (image.url) {
                        const blob = await this.getImageData(image);
                        return { ...image, blob: blob };
                    }
                });

                const res = await Promise.all(promises);

                //Download multiple as zip folder
                if (photos.length > 1) {
                    const zip = new JSZip();

                    res.forEach((image, index) => {
                        zip.file(image.name, image.blob);
                    });

                    const zipFile = await zip.generateAsync({ type: 'blob' });

                    this.downloadBlob(zipFile, `${this.service.firstName} ${this.service.lastName} Tribute Photos`);
                    this.showSnackbar({ message: 'Photos downloaded' });
                } else {
                    this.downloadBlob(res[0].blob, res[0].name);
                    this.showSnackbar({ message: 'Photo downloaded' });
                }
                this.deselectAllMultiDrag();
                this.loading = false;
            } catch {
                this.showSnackbar({ message: 'Error downloading images', color: 'error' });
            }
        },
        downloadBlob(file, name) {
            const blob = window.URL.createObjectURL(file);
            const anchor = document.createElement('a');

            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = name;
            document.body.appendChild(anchor);
            anchor.click();
            anchor.remove();
            window.URL.revokeObjectURL(blob);
        },
        getImageData(photo) {
            return new Promise((resolve, reject) => {
                this.loading = true;

                var name = photo.name;

                if (photo.url) {
                    fetch(photo.url)
                        .then(resp => resp.blob())
                        .then(blobObject => {
                            resolve(blobObject);
                        })
                        .catch(error => {
                            console.log(error, 'error');
                            this.showSnackbar({ message: 'Error downloading image', color: 'error' });
                            this.loading = false;
                            reject();
                        });
                }
            });
        },

        async quickOrder(id, order) {
            if (this.isContributeTributePage) {
                this.showSnackbar('Unauthorized to edit order');
                return;
            }

            return new Promise(async (resolve, reject) => {
                try {
                    const resp = await this.updateOrder(id, order);
                    this.imageEditModal = false;
                    resolve();
                } catch (error) {
                    console.log(error, 'quick order error');
                    reject(error);
                }
            });
        },
        async handleDeleteEmit(photo) {
            this.closeImglyModal();
            this.deleteInProgress = true;
            await this.deletePhoto(photo);
            this.deleteInProgress = false;
        },
        async getGuidById(id) {
            try {
                const uploads = (await getDictFromLocalStorage(this.guidStorageName)) || {};
                if (uploads.hasOwnProperty(id)) {
                    return uploads[id];
                } else {
                    return null;
                }
            } catch (error) {
                console.log(error, 'Error retrieving GUID by ID from local storage.');
                return null;
            }
        },
        async deletePhotoList(items) {
            if (this.isContributeTributePage) {
                const isUploadUser = await this.areAllGuidsInLocal(items.map(item => item.id));

                if (!isUploadUser) {
                    this.showSnackbar({ message: 'Unauthorized to delete', color: 'error' });
                    return;
                }
            }

            this.deleteInProgress = true;
            try {
                const idsToDelete = items.map(obj => obj.id);
                await this.tributePhotoApiService.deletePhotoBatch(idsToDelete);
            } catch (error) {
                console.log(error, 'error');
            }

            this.deselectAllMultiDrag();
            this.previewModal = false;
            this.deleteModal = false;
            this.imageEditModal = false;
            this.imageToEdit = null;
            await this.updatePhotoStore(0, this.pageSize, false);
            this.$emit('refresh-sync');
            this.$emit('refresh-tribute', this.event);
            this.deleteInProgress = false;
        },
        deletePhoto(item) {
            return new Promise(async (resolve, reject) => {
                if (this.tributeVideo.mainPhotoId == item.id) {
                    this.showSnackbar({
                        message: 'Cannot delete main photo, please update main photo to continue',
                        color: 'error',
                    });
                    return resolve();
                }

                var requestUrl = `/TributeVideoPhoto/${item.id}`;

                //Only block based on upload user for public page, allow family page full delete access
                if (this.$route.name == 'TributeUploadPage') {
                    const guid = await this.getGuidById(item.id);

                    if (!guid) {
                        this.showSnackbar({
                            message: 'Unauthorized access. Cannot delete photo at this time',
                            color: 'error',
                        });
                        return resolve();
                    } else {
                        requestUrl = `/TributeVideoPhoto/unique-delete/${guid}`;
                    }
                }

                this.axios
                    .create({ headers: { Authorization: `Bearer ${this.token}` } })
                    .delete(process.env.VUE_APP_API + requestUrl)
                    .then(res => {
                        this.pageNumber = 0;
                        this.pageSize = this.photos.length >= 24 ? this.photos.length : 24;
                        this.updatePhotoStore(this.pageNumber, this.pageSize, false, false);
                        if (item.mediaType == 1) {
                            this.updateTributeVideo({
                                videoSlidesCount: this.tributeVideo.videoSlidesCount - 1,
                                videoSlidesDuration: this.tributeVideo.videoSlidesDuration - item.duration,
                            });
                        }

                        resolve();
                    })
                    .catch(error => {
                        console.log(error, 'error');

                        reject(error);
                    });
            });
        },
        async updateOrder(id, index) {
            //Only block based on upload user for public page, allow family page full access
            if (this.$route.name == 'TributeUploadPage') {
                const guid = await this.getGuidById(id);

                if (!guid) {
                    this.showSnackbar({
                        message: 'Unauthorized access. Cannot reorder photo at this time',
                        color: 'error',
                    });
                    return;
                }
            }

            return this.axiosInstance.put(`TributeVideoPhoto/update-order/${id}/${index}`).then();
        },
        createAxiosInstance() {
            this.axiosInstance = this.axios.create({
                headers: { Authorization: `Bearer ${this.token}` },
                baseURL: process.env.VUE_APP_API,
            });
        },
        async setAuthToken() {
            if (this.tributeToken) {
                this.token = this.tributeToken;
            } else {
                const response = await this.$auth.getIdTokenClaims();
                this.token = response.__raw;
            }
        },
        recordScrollPosition() {
            var div = document.getElementById('gallery-container');
            if (div) {
                this.previousScrollHeightMinusScrollTop = div.scrollHeight - div.scrollTop;
            }
        },
        restoreScrollPosition() {
            var div = document.getElementById('gallery-container');
            if (div) {
                div.scrollTop = div.scrollHeight - this.previousScrollHeightMinusScrollTop;
            }
        },
        async nextPagePhotos() {
            const expectedCurrentTotal = this.pageNumber * this.pageSize;
            const currentTotal = this.photos.length;

            if (currentTotal == this.totalPhotos) return;

            //If pagination gets out of sync, load all as failsafe
            if (expectedCurrentTotal > currentTotal) {
                this.pageNumber = 0;
                this.pageSize = this.photos.length;
            } else {
                this.pageNumber++;
            }

            await this.updatePhotoStore(this.pageNumber, this.pageSize, true, false);

            this.$nextTick(() => {
                this.restoreScrollPosition();

                if (this.keyboardScroll && this.selectedItems.length === 1) {
                    const htmlGridItem = this.getHtmlGridItem(this.selectedItems[0]);

                    htmlGridItem.scrollIntoView({
                        behavior: 'smooth',
                        block: 'nearest',
                        inline: 'nearest',
                    });
                }
            });
        },
        handleGalleryBottomIntersect() {
            this.blockOverscroll = false;
        },
        triggerInfiniteScrollNext() {
            this.recordScrollPosition();
            if (!this.mounted || this.loading) {
                return;
            }

            if (this.totalPhotos <= this.photos.length || this.disableGallery) {
                this.blockOverscroll = false;
                return;
            }

            this.blockOverscroll = true;
            this.debouncedNextPage();
        },
        handleVideoPosterSrc(url) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations

            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';

            if (url) {
                const imgPath = url.split('/tribute-photos/')[1];

                return process.env.VUE_APP_IMG_KIT_BASE + imgPath + '/ik-thumbnail.jpg';
            }

            return url;
        },
        handlePosterRefresh(item, index) {
            if (item.refreshKey > 5) {
                return;
            }

            setTimeout(async () => {
                try {
                    const response = await this.axios(this.handleVideoPosterSrc(item.url));

                    const videoEl = this.$refs[`video-${index}`][0];
                    if (!videoEl) return;

                    videoEl.poster = this.handleVideoPosterSrc(item.url);
                    item.refreshKey++;
                } catch (error) {
                    if (error.response.data.includes('asset is too large')) {
                        const videoEl = this.$refs[`video-${index}`][0];

                        if (!videoEl) return;
                        videoEl.removeAttribute('poster');
                        videoEl.load();
                    } else {
                        this.handlePosterRefresh(item, index);
                    }
                }
            }, 1000);
        },
        scrollToGalleryBottom() {
            var div = document.getElementById('gallery-container');
            if (div) {
                this.$nextTick(() => {
                    div.scrollTo({ top: div.scrollHeight, behavior: 'instant' });
                });
            }
        },
        keydownListener(e) {
            if (e.ctrlKey && e.key === 'a') {
                e.preventDefault(); // Prevent the default "select all" action
                this.handleCtrlA();
                return;
            }

            switch (e.key) {
                case 'Escape':
                    this.escapeKeyHandler(e);
                    break;
                case 'Delete':
                    this.deleteKeyHandler(e);
                    break;
                case 'ArrowLeft':
                    e.preventDefault();
                    if (this.displaySlidesReversed) {
                        this.handleRightArrow(e);
                    } else {
                        this.handleLeftArrow(e);
                    }
                    break;
                case 'ArrowRight':
                    e.preventDefault();
                    if (this.displaySlidesReversed) {
                        this.handleLeftArrow(e);
                    } else {
                        this.handleRightArrow(e);
                    }

                    break;
                case 'ArrowUp':
                    e.preventDefault();

                    this.handleArrowUp(e);
                    break;
                case 'ArrowDown':
                    e.preventDefault();

                    this.handleArrowDown(e);
                    break;
            }
        },
        async handleCtrlA() {
            if (this.photos.length < this.tributeVideo.totalPhotos) {
                this.pageSize = this.tributeVideo.totalPhotos;
                await this.updatePhotoStore(0, this.pageSize, false);
            }
            this.selectedItems = [...this.photos];
            this.syncMultiDragSelection();
        },
        escapeKeyHandler(e) {
            if (e.key === 'Escape') {
                this.deselectAllMultiDrag();
            }
        },
        deleteKeyHandler(e) {
            if (e.key !== 'Delete') return;

            if (this.loading) return;

            if (this.selectedItems.length == 0) return;

            if (this.selectedItems.length == 1) {
                this.deletePhotoList(this.selectedItems);
            } else {
                this.deleteStep1();
            }
        },
        arrowKeyListener(e) {
            const acceptedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
            //Temp removing vertical arrow support

            if (!acceptedKeys.includes(e.key)) return;

            e.preventDefault();

            if (e.key == 'ArrowRight') {
                this.handleRightArrow(e);
            }

            if (e.key == 'ArrowLeft') {
                this.handleLeftArrow(e);
            }

            if (e.key == 'ArrowDown') {
                this.handleArrowDown(e);
            }

            if (e.key == 'ArrowUp') {
                this.handleArrowUp(e);
            }
        },
        handleArrowHorizontal(e, direction) {
            if (this.selectedItems.length === 0) return;

            if (e.ctrlKey || e.metaKey) return;

            const lastSelected = this.selectedItems[this.selectedItems.length - 1];
            const selectedIndex = this.photos.indexOf(lastSelected);

            const newIndex = direction === 'right' ? selectedIndex + 1 : selectedIndex - 1;
            if (newIndex < 0 || newIndex >= this.photos.length) return;

            const itemToSelect = this.photos[newIndex];
            if (!itemToSelect) return;

            this.scrollGalleryToItem(itemToSelect.id);

            if (e.shiftKey) {
                this.handleShiftClick(itemToSelect);
            } else {
                this.selectedItems = [itemToSelect];
                this.syncMultiDragSelection();
            }
        },
        scrollGalleryToItem(itemId) {
            const itemElement = document.getElementById(`gallery-item-${itemId}`);
            if (itemElement) {
                itemElement.scrollIntoView({
                    behavior: 'smooth', // Smooth scroll into view
                    block: 'nearest', // Scroll just enough to bring the item into view
                    inline: 'center', // Center horizontally if necessary
                });
            }
        },
        handleArrowVertical(e, direction) {
            if (e.ctrlKey || e.metaKey || this.selectedItems.length === 0) return;

            const lastSelected = this.selectedItems[this.selectedItems.length - 1];

            const adjacentGridItem = this.findVerticalAdjacentGridItem(lastSelected, direction);

            if (!adjacentGridItem) {
                if (e.shiftKey) {
                    const itemToSelect = direction === 'down' ? this.photos[this.photos.length - 1] : this.photos[0];
                    this.handleShiftClick(itemToSelect);
                }
                return;
            }

            const newIndex = this.photos.indexOf(adjacentGridItem);
            if (newIndex < 0 || newIndex >= this.photos.length) return;

            const itemToSelect = this.photos[newIndex];
            if (!itemToSelect) return;

            this.scrollGalleryToItem(itemToSelect.id);

            if (e.shiftKey) {
                this.handleShiftClick(itemToSelect);
            } else {
                this.selectedItems = [itemToSelect];
                this.syncMultiDragSelection();
            }
        },

        handleRightArrow(e) {
            this.handleArrowHorizontal(e, 'right');
        },
        handleLeftArrow(e) {
            this.handleArrowHorizontal(e, 'left');
        },
        handleArrowDown(e) {
            this.handleArrowVertical(e, 'down');
        },
        handleArrowUp(e) {
            this.handleArrowVertical(e, 'up');
        },
        updateSelectedItemRange() {
            if (this.selectedItems.length === 0) return;

            if (this.selectedItems.length > 0) {
                const firstItem = this.selectedItems[0];
            }
        },
        async changeSelectedItem(newItem, oldItem) {
            // this.sortableDeselectItem(oldItem);
            // this.sortableSelectItem(newItem);

            const container = document.getElementById('gallery-container');
            if (!container) return null;

            let scrollTimeout;
            const onScroll = () => {
                this.keyboardScroll = true;
                if (scrollTimeout) {
                    clearTimeout(scrollTimeout);
                }

                scrollTimeout = setTimeout(() => {
                    container.removeEventListener('scroll', onScroll);
                    this.keyboardScroll = false;
                }, 100);
            };

            container.addEventListener('scroll', onScroll);

            const htmlGridItem = this.getHtmlGridItem(newItem);
            htmlGridItem.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            });
        },
        getHtmlGridItem(item) {
            const found = this.photos.find(x => x.id === item.id);
            if (!found) return null;

            const container = document.getElementById('draggable-container');
            if (!container) return null;

            const gridItems = container.querySelectorAll('.grid-item');
            const foundIndex = this.photos.indexOf(found);
            const selectedGridItem = gridItems[foundIndex];

            if (!selectedGridItem) return null;

            return selectedGridItem;
        },
        findVerticalAdjacentGridItem(item, verticalDirectionString) {
            const allowedDirectionParams = ['up', 'down'];
            if (!allowedDirectionParams.includes(verticalDirectionString)) return null;

            const verticalDirection = verticalDirectionString == 'up' ? 1 : -1;

            const container = document.getElementById('draggable-container');

            if (!container) return null;

            const gridItems = container.querySelectorAll('.grid-item');

            const found = this.photos.find(x => x.id === item.id);
            if (!found) return null;

            const foundIndex = this.photos.indexOf(found);
            const selectedGridItem = gridItems[foundIndex];

            const rect1 = selectedGridItem.getBoundingClientRect();
            const containerScrollTop = container.scrollTop;
            const adjustedRect1Top = rect1.top + containerScrollTop;

            const containerRect = container.getBoundingClientRect();

            let index = foundIndex + verticalDirection;

            while (index >= 0 && index < this.photos.length) {
                const gridItem = gridItems[index];
                const listItem = this.photos[index];

                const rect2 = gridItem.getBoundingClientRect();
                const isTopRow = rect2.top <= containerRect.top;

                const adjustedRect2Top = rect2.top + containerScrollTop;

                const isBelow = verticalDirection > 0 && adjustedRect2Top > adjustedRect1Top;
                const isAbove = verticalDirection < 0 && adjustedRect2Top < adjustedRect1Top;
                // const isSameColumn = rect1.left == rect2.left;

                if (isBelow || isAbove) {
                    return listItem;
                }

                index += verticalDirection;
            }

            return null;
        },
        sortableSelectItem(item) {
            let id = item.id;
            const photoFound = this.photos.find(x => x.id == id);
            if (!photoFound) return;
            const selectedFound = this.selectedItems.find(x => x.id == id);
            if (!selectedFound) {
                this.selectedItems.push(photoFound);
            }
            this.syncMultiDragSelection();
        },
        sortableDeselectItem(item) {
            const found = this.selectedItems.find(x => x.id == item.id);
            if (found) {
                const index = this.selectedItems.indexOf(found);
                this.selectedItems.splice(index, 1);
            }

            this.syncMultiDragSelection();
        },
        deselectAllMultiDrag() {
            const allSelectedItems = document.querySelectorAll('.sortable-selected');
            this.selectedItems = [];
            allSelectedItems.forEach(item => {
                Sortable.utils.deselect(item);
            });
        },
        syncMultiDragSelection() {
            // Deselect all items that are currently marked as selected in the DOM
            const allSelectedItems = document.querySelectorAll('.sortable-selected');
            allSelectedItems.forEach(item => {
                Sortable.utils.deselect(item);
            });

            // Select only those items that are in this.selectedItems array
            this.selectedItems.forEach(selectedItem => {
                const element = document.getElementById(`gallery-item-${selectedItem.id}`);
                if (element) {
                    Sortable.utils.select(element);
                }
            });
        },
        async checkOutOfSyncPhotos(id) {
            if (id) {
                return this.axiosInstance
                    .get(`TributeVideoPhoto/event-photos-out-of-sync/${id}`)
                    .then(resp => {
                        if (resp.data.photos) {
                            this.outOfSyncPhotos = resp.data.photos;
                        } else {
                            this.outOfSyncPhotos = [];
                        }
                    })
                    .catch(error => {
                        console.log(error, 'error');
                    });
            }
        },
        async getTributeRender(tributeId) {
            if (tributeId) {
                this.loading = true;
                return this.axiosInstance
                    .get(`/TributeVideoRender/${tributeId}`)
                    .then(resp => {
                        this.render = resp.data;
                    })
                    .catch(error => {
                        if (error.response && error.response.status == 404) {
                            return null;
                        } else {
                            console.log('error', error);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        async handleSortableStart(evt) {
            //Only block based on upload user for public page, allow family page full access
            if (this.$route.name == 'TributeUploadPage') {
                const handleUnauthorized = () => {
                    this.showSnackbar({
                        message: 'Unauthorized access. Cannot reorder photo at this time',
                        color: 'error',
                    });
                    Sortable.utils.deselect(evt.item); // Deselect the dragged item
                    this.sortable.option('disabled', true);
                    this.preventDragEndEvt = true;
                    return false;
                };

                if (evt.items.length > 1) {
                    for (let i = 0; i < evt.items.length; i++) {
                        const itemId = evt.items[i].id.split('gallery-item-')[1];
                        const guid = await this.getGuidById(itemId);
                        if (!guid) {
                            return handleUnauthorized();
                        }
                    }
                } else {
                    const itemId = evt.item.id.split('gallery-item-')[1];
                    const guid = await this.getGuidById(itemId);
                    if (!guid) {
                        return handleUnauthorized();
                    }
                }
            }
        },
        async handleSortableEnd(evt) {
            const { oldIndicies, newIndicies } = evt;

            if (!this.preventDragEndEvt) {
                if (evt.items.length > 1) {
                    await this.handleMultiDrag(evt);
                    // let updatedPhotos = [...this.photos];
                    // let oldSortedPhotos = [];
                    // let newSortedPhotos = [];
                    // // Get the moved items and sort them based on their old index
                    // oldIndicies.forEach(({ index }) => {
                    //     oldSortedPhotos.push(updatedPhotos[index]);
                    // });
                    // oldSortedPhotos.sort((a, b) => b.order - a.order);
                    // // Place the moved items at their new positions in newSortedPhotos
                    // newIndicies.forEach(({ index }, i) => {
                    //     newSortedPhotos[index] = oldSortedPhotos[i];
                    // });
                    // // Remove the old positions of the moved items from updatedPhotos
                    // oldIndicies.forEach(({ index }) => {
                    //     updatedPhotos.splice(index, 1, null); // Replace with null temporarily
                    // });
                    // // Insert the moved items at their new positions in updatedPhotos
                    // newIndicies.forEach(({ index }, i) => {
                    //     updatedPhotos.splice(index, 1, newSortedPhotos[index]);
                    // });
                    // // Remove any null values (these were placeholders for moved items)
                    // updatedPhotos = updatedPhotos.filter(item => item !== null);
                    // //Update vuex store
                    // this.updateTributeVideoPhotos(updatedPhotos);
                } else {
                    console.log(evt, 'drag end evt');
                    await this.handleSingleDrag(evt);
                    const { oldIndex, newIndex } = evt;
                    this.pageNumber = 0;
                    this.pageSize = this.photos.length;
                    // this.updatePhotoStore(this.pageNumber, this.pageSize, false, false);
                }
            }
            // this.updatePhotoStore(this.pageNumber, this.pageSize, false, false);

            this.deselectAllMultiDrag();
            this.sortable.option('disabled', this.mobile || this.disableGallery);

            // this.$emit('refresh-sync');
            this.preventDragEndEvt = false;
        },
        async createSortable() {
            if (this.sortable) {
                await this.sortable.destroy();
                this.sortable = null;
            }

            const draggableContainer = document.getElementById('draggable-container');

            try {
                // plugin mounted to class instead of instance
                //https://github.com/SortableJS/Sortable/issues/2132
                Sortable.mount(new MultiDrag());
            } catch (error) {}

            this.sortable = Sortable.create(draggableContainer, {
                // plugins: [Sortable.multiDrag],
                multiDrag: true,
                disabled: this.mobile || this.disableGallery,
                selectedClass: 'sortable-selected',
                filter: '.filtered',
                animation: 150,
                fallbackTolerance: 3,
                multiDragKey: 'del', // Key that must be down for items to be selected
                avoidImplicitDeselect: true, // true - if you don't want to deselect items on outside click

                // onSelect: this.handleSortableSelect,
                // onDeselect: this.handleSortableDeselect,
                onStart: this.handleSortableStart,
                onEnd: this.handleSortableEnd,
            });
        },
        rerenderItems() {
            this.galleryRefreshKey++;
        },
        handleTouchStart(evt, item) {
            this.isScrolling = false;
            this.touchStartX = evt.touches[0].clientX;
            this.touchStartY = evt.touches[0].clientY;
        },
        handleTouchEnd(evt, item) {
            // clearTimeout(this.touchTimeout);
            if (!this.isScrolling) {
                this.handleGalleryItemClick(evt, item);
            }
        },
        handleTouchMove(evt, item) {
            const touchEndX = evt.touches[0].clientX;
            const touchEndY = evt.touches[0].clientY;
            if (Math.abs(touchEndX - this.touchStartX) > 10 || Math.abs(touchEndY - this.touchStartY) > 10) {
                this.isScrolling = true;
            }
        },
        getOptions() {
            let options = {
                multiDrag: true,
                selectedClass: 'selected',
            };
            return options;
        },
        findRemoveTributePhoto(item) {
            const clonedList = [...this.tributeVideo.photos];
            const index = clonedList.indexOf(item);
            if (index !== -1) {
                clonedList.splice(index, 1);
                this.updateTributeVideoPhotos(clonedList);
            }
        },
        handleImgSrc(url, lowres = false) {
            // img kit docs: https://docs.imagekit.io/features/image-transformations
            //const imgKitBase = 'https://ik.imagekit.io/memoryshare/';
            if (url) {
                const imgPath = url.split('/tribute-photos/')[1];
                if (lowres) {
                    return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-50,h-50/' + imgPath;
                } else {
                    return process.env.VUE_APP_IMG_KIT_BASE + 'tr:w-250,h-250/' + imgPath;
                }
            }
            return url;
        },
        async handleUploadSuccess() {
            this.disableGallery = false;
            await this.updatePhotoStore(0, this.pageSize, false);
        },
        async uploadSocketHandler() {
            if (
                this.rotatingImages ||
                this.tributeVideo.uploadingPhotos ||
                !this.tributeVideo.eventId ||
                this.deleteInProgress
            ) {
                return;
            }

            // console.log('socket handler running');
            this.pageNumber = 0;
            this.pageSize = this.photos.length >= 24 ? this.photos.length : 24;
            await this.updatePhotoStore(this.pageNumber, this.pageSize, false, false);
            this.checkOutOfSyncPhotos(this.event.id);
            this.$emit('refresh-sync');
        },
        async onboardUploadHandler() {
            this.pageNumber = 0;
            this.pageSize = this.photos.length >= 24 ? this.photos.length : 24;
            await this.updatePhotoStore(this.pageNumber, this.pageSize, false, true);
        },
        initApiServices(token) {
            this.tributePhotoApiService = TributePhotoService(token);
        },
        customScrollHandler(event) {
            if (event.shiftKey) {
                event.preventDefault(); // Prevent default to stop horizontal scrolling
                // Manually adjust the scrollTop based on the wheel delta

                event.currentTarget.scrollBy({
                    top: event.deltaY * 0.8, // Scroll vertically by the deltaY value
                    behavior: 'auto', // You can set this to 'smooth' for smooth scrolling if preferred
                });
            }
        },
        setContextItems() {
            if (this.isContributeTributePage) {
                this.contextItems = [
                    {
                        text: 'Download',
                        icon: 'fa-regular fa-arrow-down-to-bracket',
                        color: '#1877f2',
                        disabled: false,
                        value: 0,
                    },
                    // { text: 'Set Profile', icon: 'fa-regular fa-user', color: '', disabled: false, value: 6 },
                    // {
                    //     text: 'Send to Front',
                    //     icon: 'fa-regular fa-arrow-left',
                    //     color: '',
                    //     disabled: false,
                    //     value: 1,
                    // },
                    // { text: 'Send to Back', icon: 'fa-regular fa-arrow-right', color: '', disabled: false, value: 2 },
                    {
                        text: 'Edit Photo',
                        icon: 'fa-regular fa-pencil',
                        color: '',
                        disabled: false,
                        value: 3,
                    },
                    {
                        text: 'Rotate',
                        icon: 'fa-regular fa-rotate-right',
                        color: '',
                        disabled: false,
                        value: 4,
                    },
                    {
                        text: 'Delete',
                        icon: 'fa-regular fa-trash-can',
                        color: '#ff5252',
                        disabled: false,
                        value: 5,
                    },
                ];
            } else {
                this.contextItems = [
                    {
                        text: 'Download',
                        icon: 'fa-regular fa-arrow-down-to-bracket',
                        color: '#1877f2',
                        disabled: false,
                        value: 0,
                    },
                    {
                        text: 'Set Profile',
                        icon: 'fa-regular fa-user',
                        color: '',
                        disabled: false,
                        value: 6,
                    },
                    {
                        text: 'Send to Front',
                        icon: 'fa-regular fa-arrow-left',
                        color: '',
                        disabled: false,
                        value: 1,
                    },
                    {
                        text: 'Send to Back',
                        icon: 'fa-regular fa-arrow-right',
                        color: '',
                        disabled: false,
                        value: 2,
                    },
                    {
                        text: 'Edit Photo',
                        icon: 'fa-regular fa-pencil',
                        color: '',
                        disabled: false,
                        value: 3,
                    },
                    {
                        text: 'Rotate',
                        icon: 'fa-regular fa-rotate-right',
                        color: '',
                        disabled: false,
                        value: 4,
                    },
                    {
                        text: 'Delete',
                        icon: 'fa-regular fa-trash-can',
                        color: '#ff5252',
                        disabled: false,
                        value: 5,
                    },
                ];
            }

            this.contextRefreshKey++;
        },
        smotthScroll(element, deltaY) {},
        addCustomScrollListener(elementId) {
            const el = document.getElementById(elementId);
            if (el) {
                el.addEventListener('wheel', this.customScrollHandler, { passive: false });
            }
        },
        removeCustomScrollListener(elementId) {
            const el = document.getElementById(elementId);
            if (el) {
                el.removeEventListener('wheel', this.customScrollHandler, { passive: false });
            }
        },
    },
    sockets: {
        async NotifyUpload(data) {
            // console.log('upload socket heard');
            if (data.id != this.event.id) return;
            this.debouncedUploadSocketHandler();
        },
    },
    watch: {
        replaceWithTestImages() {
            this.pageNumber = 0;
            this.$emit('toggleDevMode', false);
            this.updateTributeVideoPhotos([]);
            this.updatePhotoStore(0, this.pageSize + 1, false);
        },
        disableGallery(newVal) {
            this.createSortable();
        },
        imglyModal(newVal) {
            if (!newVal) {
                this.showImageError = false;
                this.imageErrorisHeic = false;
            }
        },
        previewModal(newVal) {
            if (!newVal) {
                this.previewItem = null;
            }
        },
    },
    created() {
        this.debouncedUploadSocketHandler = debounceV2(this.uploadSocketHandler, 700);
        this.debouncedNextPage = debounceV2(this.nextPagePhotos, 700);
    },
    async mounted() {
        this.setContextItems();
        this.pageNumber = 0;
        this.updateTributeVideoPhotosOptions({
            pageNum: this.pageNumber,
            pageSize: this.pageSize,
            reversed: true,
        });
        document.addEventListener('click', this.handleOutsideClick);
        document.addEventListener('keydown', this.keydownListener);

        this.addCustomScrollListener('gallery-container');

        await this.setAuthToken();
        this.createAxiosInstance();
        this.initApiServices(this.token);

        this.debouncedMultiRotate = debounceV2(this.rotateMultipleImages, 500);

        if (this.event.id) {
            await this.checkOutOfSyncPhotos(this.event.id);
            if (this.tributeVideo.id) {
                try {
                    await this.getTributeRender(this.tributeVideo.id);
                    if (this.render?.id) {
                        this.disableGallery = true;
                    }
                } catch (error) {}
            }
        }
        if (this.tributeVideo.eventId) {
            if (!this.scrollable) {
                this.pageSize = 48;
            }
            await this.updatePhotoStore(0, this.pageSize, false);
        }

        await this.createSortable();
        setTimeout(() => {
            this.scrollToGalleryBottom();
            this.mounted = true;
        }, 100);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handleOutsideClick);
        document.removeEventListener('keydown', this.keydownListener);
        this.removeCustomScrollListener('gallery-container');

        if (this.sortable) {
            this.sortable.destroy();
        }
    },
};
</script>
<style lang="scss" scoped>
.gallery-section {
    position: relative;
}

.gallery-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.533);
    z-index: 2;
    // pointer-events: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gallery-overlay > .gallery-overlay-btn {
    pointer-events: auto;
}
.gallery-container {
    overflow: auto;
    height: 100%;
    max-height: 550px;
    z-index: 1;
    padding: 4px 0;
}

.top-bottom-borders {
    border-top: 1px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
}

.no-max-height {
    max-height: none !important;
}
.grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.gallery-col-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hidden {
    opacity: 0;
}
.bottom-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.img-loading-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #e0e0e0;
    z-index: 0;
    border-radius: 6px;
    border: 2px solid white;
    max-width: 250px;
    max-height: 250px;
}
.modalVideo {
    aspect-ratio: 16/9;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    border-radius: 5px;
}

.image-edit-div {
    width: 620px;
    height: 620px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    #editImage {
        max-width: 600px;
        max-height: 600px;
    }
}

.image-error-div {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.gallery-col {
    display: flex;
    justify-content: center;
}

.ps {
    height: 550px;
}

.block-overscroll {
    overscroll-behavior-y: none;
}

.mute-action-overlay {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    margin: 12px;
    cursor: pointer;
    z-index: 1;
}

.preview-video {
    max-width: 100%;
    width: 100%;
    max-height: 600px;
    margin: 0;
    aspect-ratio: 16/9;
    padding: 0;
    border-radius: 5px;
    background-color: #000;
}
</style>
