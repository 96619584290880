<template>
    <div class="manage-container">
        <div :class="['manage-content']" :style="{ height: containerHeight }">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        containerHeight: {
            get() {
                return this.$store.state.tributeEditor.workspaceHeight;
            },
        },
    },
};
</script>
<style lang="scss" scoped>
.manage-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: transform 0.8s;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    margin: 10px 12px;
    width: 100%;
    max-width: calc(100% - 24px);
    border-radius: 8px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
}

.manage-content {
    height: 100%;
    background: white;
    border-radius: 8px;
    padding: 8px;
    gap: 8px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    transition: height 0.8s;
}

.manage-content > div:first-child {
    height: 100%;
}

// @media (max-width: 600px) {
//     .manage-content {
//         height: 40vh;
//     }

//     .manage-content.expanded {
//         height: 75vh;
//     }
// }
</style>
