<template>
    <div>
        <div class="video-wrapper event-viewer">
            <div class="watermark" v-if="test">
                <p class="text">Test</p>
                <p class="text">Test</p>
                <p class="text">Test</p>
                <p class="text">Test</p>
                <p class="text">Test</p>
                <p class="text">Test</p>
                <p class="text">Test</p>
                <p class="text">Test</p>
                <p class="text">Test</p>
                <p class="text">Test</p>
                <p class="text">Test</p>
                <p class="text">Test</p>
            </div>

            <div v-if="splashMessage" class="splash-message">
                <div
                    v-if="!archived && event.eventStatus == 2"
                    class="live-overlay d-flex flex-column align-center justify-center"
                >
                    <h2>Searching</h2>
                    <div class="loader my-8"></div>
                    <div>
                        {{ splashMessage }}
                    </div>
                    <div>
                        <span class="text-caption">This usually takes 1-3 minutes</span>
                    </div>
                    <span
                        @click="openTab('http://help.memoryshare.com/en/articles/9204456-no-video-feed-detected')"
                        class="need-help-tag"
                        ><u>Need Help?</u></span
                    >
                </div>
                <div v-else class="d-flex flex-column align-center jsutify-center">
                    {{ splashMessage }}
                </div>
            </div>

            <div>
                <styled-videojs-player
                    ref="videoPlayer"
                    :src="playerSrc"
                    :poster="poster"
                    :autoplay="event.eventStatus === 2 && this.$route.name != 'LiveDashboard'"
                    :show-time-controls="event.eventStatus !== 2"
                    @error="$emit('error')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import StyledVideojsPlayer from '@/components/videojs/StyledVideojsPlayer.vue';

const splashMessages = {
    waitingForFeed: 'No video feed detected.',
    eventEnded: 'No video is available for this event.',
    videoArchived: 'Video is archived for this event.',
};

export default {
    name: 'VideoPlayer',
    data() {
        return {
            playerSrc: '',
            splashMessage: null,
            refreshKey: 0,
        };
    },
    props: {
        src: {
            type: String,
            required: true,
        },
        poster: {
            type: String,
            required: false,
        },
        isLiveStream: {
            type: Boolean,
            required: false,
        },
        test: {
            type: Boolean,
            default: false,
        },
        event: {
            type: Object,
            default: false,
        },
        archived: {
            type: Boolean,
            defalut: false,
        },
        autoplay: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        openModal() {
            const modal = this.player.createModal('This is a modal!');
        },
        openTab(val) {
            window.open(val, '_blank');
        },
        async isStreamReady() {
            try {
                const response = await this.axios.get(this.src);
                return response.status === 200;
            } catch (error) {
                return false;
            }
        },
        async startPreview() {
            console.count('run');

            const streamReady = await this.isStreamReady();

            if (streamReady) {
                this.playerSrc = this.src;
                this.splashMessage = null;
                this.$emit('videoready');

                await this.$nextTick();
                if (this.$refs.videoPlayer) {
                    await this.$refs.videoPlayer.initVideoJs();
                }

                return;
            }

            if (this.event.eventStatus !== 2) {
                this.splashMessage = splashMessages.eventEnded;
                return;
            }

            this.splashMessage = splashMessages.waitingForFeed;
            this.timeoutId = setTimeout(this.startPreview, 5000);
        },
    },
    components: {
        StyledVideojsPlayer,
    },
    async mounted() {
        if (this.event.eventStatus === 7 && this.archived) {
            this.splashMessage = splashMessages.videoArchived;
            this.playerSrc = '';
        } else if (this.isLiveStream) {
            this.startPreview();
        } else {
            this.playerSrc = this.src;
        }
    },
    beforeDestroy() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    },
};
</script>
<style lang="scss">
.event-viewer {
    .watermark {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 101;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        pointer-events: none;
        box-sizing: border-box;

        .text {
            color: white;
            font-size: 4rem;
            font-weight: bold;
            opacity: 55%;
        }
    }

    //OLD MS PLAYER STYLES
    // .video-js {
    //     font-size: 10px !important;

    //     .vjs-play-progress {
    //         .vjs-time-tooltip {
    //             display: none !important;
    //         }
    //     }

    //     &:hover {
    //         cursor: pointer !important;
    //     }

    //     // Big play button
    //     .vjs-big-play-button {
    //         background: $pink !important;
    //         border-color: $pink !important;
    //         border-radius: 5px !important;
    //         color: #fff !important;
    //         opacity: 0.9 !important;
    //         transition: 0.2s all !important;
    //     }

    //     &:hover .vjs-big-play-button {
    //         background: $pink !important;
    //         border-color: $pink !important;
    //         opacity: 1 !important;
    //     }

    //     // Control bar
    //     .vjs-control-bar {
    //         background-color: rgba(0, 0, 0, 0.6) !important;
    //         padding: 0 2em !important;
    //         height: $control-bar-height !important;
    //     }

    //     // All control buttons (play, volume, full screen)
    //     .vjs-control {
    //         /* width: 3em !important; */

    //         .vjs-icon-placeholder::before {
    //             font-size: 2em !important;
    //             display: flex !important;
    //             align-items: center !important;
    //             justify-content: center !important;
    //         }
    //     }

    //     // Play button
    //     .vjs-play-control {
    //         font-size: 1.3em !important;
    //         max-width: 3em !important;
    //     }

    //     // Progress bar
    //     .vjs-progress-control:hover .vjs-progress-holder {
    //         font-size: 1em !important; // Overrides videojs styles which make the bar get taller on hover
    //     }

    //     .vjs-progress-holder {
    //         height: $slider-height !important;
    //     }

    //     .vjs-play-progress {
    //         background: #ab0658 !important;
    //     }

    //     .vjs-time-tooltip {
    //         font-size: 1.2em !important;
    //     }

    //     // Volume control
    //     .vjs-volume-control {
    //         height: 100% !important;
    //         align-items: center !important;
    //         margin-top: 0 !important; // Overrides top margin added when embedded
    //     }

    //     .vjs-mute-control {
    //         margin-top: 0 !important; // Overrides top margin added when embedded
    //     }

    //     .vjs-volume-bar.vjs-slider-horizontal {
    //         height: $slider-height !important;
    //     }

    //     .vjs-volume-level {
    //         height: 100% !important;

    //         &::before {
    //             display: none !important;
    //         }
    //     }
    // }

    .loader {
        width: 85px;
        height: 50px;
        --g1: conic-gradient(from 90deg at left 3px top 3px, #0000 90deg, #fff 0);
        --g2: conic-gradient(from -90deg at bottom 3px right 3px, #0000 90deg, #fff 0);
        background: var(--g1), var(--g1), var(--g1), var(--g2), var(--g2), var(--g2);
        background-position: left, center, right;
        background-repeat: no-repeat;
        animation: l9 1s infinite;
    }
    @keyframes l9 {
        0% {
            background-size: 25px 50%, 25px 50%, 25px 50%;
        }
        25% {
            background-size: 25px 100%, 25px 50%, 25px 50%;
        }
        50% {
            background-size: 25px 50%, 25px 100%, 25px 50%;
        }
        75% {
            background-size: 25px 50%, 25px 50%, 25px 100%;
        }
        100% {
            background-size: 25px 50%, 25px 50%, 25px 50%;
        }
    }

    .live-overlay {
        width: 100%;
        height: 100%;
        position: relative;
    }

    .need-help-tag {
        position: absolute;
        bottom: 10px;
        right: 15px;
        cursor: pointer;
    }
}

.video-wrapper {
    height: 100% !important;
    position: relative !important;
    z-index: 1;
}

.splash-message {
    background: rgb(23, 23, 23);
    position: absolute;
    // z-index: 100;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // top: 50%;
    // left: 50%;
    // height: calc(100% - 48px);
    // width: calc(100% - 48px);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    text-align: center;

    // transform: translate(-50%, -50%);
}
</style>
