<template>
    <div class="manage-photos-container">
        <div class="action-bar">
            <div class="left-buttons">
                <button class="primary-button">Share link to family to upload</button>
                <div class="button-divider"></div>
                <SlideUpload
                    ref="uploaderBtn"
                    :videoAllowed="allowVideoUploads"
                    :maxFiles="maxFilesLeft"
                    @change="tempFiles => (pendingUploads = tempFiles)"
                    @filesAdded="onFileAdded"
                >
                    <template #activator="{ onClick }">
                        <button class="secondary-button" @click="onClick">
                            <svg
                                class="icon"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3.33337 13.333L7.15486 9.51152C7.80574 8.86065 8.86101 8.86064 9.51189 9.51152L13.3334 13.333M11.6667 11.6663L12.9882 10.3449C13.6391 9.69398 14.6943 9.69398 15.3452 10.3449L16.6667 11.6663M11.6667 6.66634H11.675M5.00004 16.6663H15C15.9205 16.6663 16.6667 15.9201 16.6667 14.9997V4.99967C16.6667 4.0792 15.9205 3.33301 15 3.33301H5.00004C4.07957 3.33301 3.33337 4.0792 3.33337 4.99967V14.9997C3.33337 15.9201 4.07957 16.6663 5.00004 16.6663Z"
                                    stroke="#6B7280"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                            Add Photos
                        </button>
                    </template>
                </SlideUpload>

                <button class="secondary-button" @click="addNewTextSlide">
                    <svg
                        class="icon"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M3.33337 5H16.6667M3.33337 10H16.6667M3.33337 15H9.16671"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    Add Text Slide
                </button>
            </div>

            <div class="right-buttons">
                <button
                    class="icon-button"
                    @click="toggleHeight"
                    :style="{
                        borderTopLeftRadius: '6px',
                        borderBottomLeftRadius: '6px',
                    }"
                >
                    <svg
                        :style="{
                            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.5s',
                        }"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M4.16671 12.5L10 6.66667L15.8334 12.5"
                            stroke="#6B7280"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <button class="icon-button">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.33337 13.3337L3.33337 14.167C3.33337 15.5477 4.45266 16.667 5.83337 16.667L14.1667 16.667C15.5474 16.667 16.6667 15.5477 16.6667 14.167L16.6667 13.3337M13.3334 10.0003L10 13.3337M10 13.3337L6.66671 10.0003M10 13.3337L10 3.33366"
                            :stroke="isAnyImageSelected ? '#6B7280' : '#d1d5db'"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <button
                    @click="moveSlideBackward"
                    :disabled="!isAnyImageSelected || selectedSlideIndex === 0"
                    class="icon-button"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M5.83333 13.3337L2.5 10.0003M2.5 10.0003L5.83333 6.66699M2.5 10.0003L17.5 10.0003"
                            :stroke="isAnyImageSelected ? '#6B7280' : '#d1d5db'"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <button
                    @click="moveSlideForward"
                    :disabled="!isAnyImageSelected || selectedSlideIndex === reversedSlides.length - 1"
                    class="icon-button"
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.1667 6.66699L17.5 10.0003M17.5 10.0003L14.1667 13.3337M17.5 10.0003L2.5 10.0003"
                            :stroke="isAnyImageSelected ? '#6B7280' : '#d1d5db'"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <button @click="editActiveSlide" :disabled="!isAnyImageSelected" class="icon-button">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.8898 3.11019L17.5969 2.40309V2.40309L16.8898 3.11019ZM5.41673 17.5296V18.5296C5.68194 18.5296 5.9363 18.4242 6.12383 18.2367L5.41673 17.5296ZM2.50006 17.5296H1.50006C1.50006 18.0819 1.94778 18.5296 2.50006 18.5296V17.5296ZM2.50006 14.5537L1.79295 13.8466C1.60542 14.0341 1.50006 14.2885 1.50006 14.5537H2.50006ZM14.6507 3.8173C15.0737 3.39423 15.7596 3.39423 16.1827 3.8173L17.5969 2.40309C16.3928 1.19897 14.4406 1.19897 13.2364 2.40309L14.6507 3.8173ZM16.1827 3.8173C16.6058 4.24037 16.6058 4.9263 16.1827 5.34937L17.5969 6.76358C18.801 5.55946 18.801 3.6072 17.5969 2.40309L16.1827 3.8173ZM16.1827 5.34937L4.70962 16.8225L6.12383 18.2367L17.5969 6.76358L16.1827 5.34937ZM5.41673 16.5296H2.50006V18.5296H5.41673V16.5296ZM13.2364 2.40309L1.79295 13.8466L3.20717 15.2608L14.6507 3.8173L13.2364 2.40309ZM1.50006 14.5537V17.5296H3.50006V14.5537H1.50006ZM11.9864 5.0673L14.9327 8.01358L16.3469 6.59937L13.4007 3.65309L11.9864 5.0673Z"
                            :fill="isAnyImageSelected ? '#6B7280' : '#d1d5db'"
                        />
                    </svg>
                </button>
                <button @click="handleRotateImage" class="icon-button">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.33331 3.33301V7.49967H3.81791M16.6151 9.16634C16.205 5.87775 13.3997 3.33301 9.99998 3.33301C7.20216 3.33301 4.80689 5.05648 3.81791 7.49967M3.81791 7.49967H7.49998M16.6666 16.6663V12.4997H16.182M16.182 12.4997C15.1931 14.9429 12.7978 16.6663 9.99998 16.6663C6.6003 16.6663 3.79497 14.1216 3.38489 10.833M16.182 12.4997H12.5"
                            :stroke="isAnyImageSelected ? '#6B7280' : '#d1d5db'"
                            stroke-width="2"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
                <button
                    @click="openDeleteModal"
                    class="highlighted-icon-button"
                    style="
                        background: #fee2e2;
                        border: 1px solid #fca5a5;
                        border-top-right-radius: 6px;
                        border-bottom-right-radius: 6px;
                    "
                >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15.8333 5.83333L15.1105 15.9521C15.0482 16.8243 14.3225 17.5 13.4481 17.5H6.55184C5.67745 17.5 4.95171 16.8243 4.88941 15.9521L4.16665 5.83333M8.33331 9.16667V14.1667M11.6666 9.16667V14.1667M12.5 5.83333V3.33333C12.5 2.8731 12.1269 2.5 11.6666 2.5H8.33331C7.87308 2.5 7.49998 2.8731 7.49998 3.33333V5.83333M3.33331 5.83333H16.6666"
                            :stroke="isAnyImageSelected ? '#EF4444' : '#FCA5A5'"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            </div>
        </div>

        <!-- :class="['slides-container']" -->
        <draggable
            v-model="reversedSlides"
            :class="['slides-container', { expanded: isExpanded }]"
            :options="{
                animation: 300,
                ghostClass: 'ghost-hidden',
                draggable: '.slide',
            }"
            @start="onDragStart"
            @end="onDragEnd"
        >
            <div
                v-if="showLoadMore"
                v-intersect="(entries, observer, isIntersecting) => handleSlidesStartIntersect(isIntersecting)"
                @click="() => slidesNextPage()"
                class="ghost-slide"
            >
                <button>Load More</button>
            </div>

            <div
                v-for="(slide, index) in reversedSlides"
                :key="index"
                :id="`slide-${slide.id}`"
                class="slide"
                :class="{
                    dragging: isDragging && dragIndex === index,
                    selected: selectedSlideIndex === index,
                }"
                @click="selectImage(index)"
                :style="{ position: 'relative' }"
            >
                <div
                    class="slide-order"
                    :style="{
                        zIndex: 1000,
                    }"
                >
                    {{ getSlideDbIndex(index) }}
                </div>

                <img
                    :src="getSlideDisplaySrc(slide.url)"
                    :alt="'Slide ' + getSlideDbIndex(index)"
                    class="slide-image"
                    :style="{
                        transform: 'rotate(' + slide.rotation + 'deg)',
                    }"
                />
                <div
                    v-if="selectedSlideIndex === index"
                    class="selected-indicator"
                    :style="{ position: 'absolute', top: '8px', right: '8px', zIndex: 2 }"
                >
                    <svg
                        width="13.48"
                        height="10.5"
                        viewBox="0 0 13.48 10.5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M1 4.33333L5.08021 8.41208L12.48 1" stroke="white" stroke-width="2" />
                    </svg>
                </div>
            </div>

            <!-- These are the pending uploads -->
            <template v-for="(pendingSlide, index) in pendingUploads" v-if="pendingUploads.length">
                <div class="pending-slide" :key="pendingSlide.id">
                    <div
                        class="slide-order"
                        :style="{
                            zIndex: 1000,
                        }"
                    >
                        {{ totalSlides + (index + 1) }}
                    </div>
                    <ImagePreview
                        class="slide-image"
                        :media="pendingSlide"
                        :alt="'Slide ' + totalSlides + (index + 1)"
                    />
                    <div class="progress-indicator">
                        <div class="progress-slider" :style="{ width: `${pendingSlide.progress.percentage}%` }"></div>
                    </div>
                </div>
            </template>
            <div v-if="totalSlides == 0" class="ghost-slide" @click="() => $refs.uploaderBtn.openFileSelection()">
                <div class="ghost-icon-container">
                    <svg
                        class="ghost-icon"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M3 17C3 16.4477 3.44772 16 4 16H16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18H4C3.44772 18 3 17.5523 3 17ZM6.29289 6.70711C5.90237 6.31658 5.90237 5.68342 6.29289 5.29289L9.29289 2.29289C9.48043 2.10536 9.73478 2 10 2C10.2652 2 10.5196 2.10536 10.7071 2.29289L13.7071 5.29289C14.0976 5.68342 14.0976 6.31658 13.7071 6.70711C13.3166 7.09763 12.6834 7.09763 12.2929 6.70711L11 5.41421L11 13C11 13.5523 10.5523 14 10 14C9.44771 14 9 13.5523 9 13L9 5.41421L7.70711 6.70711C7.31658 7.09763 6.68342 7.09763 6.29289 6.70711Z"
                            fill="#F97316"
                        />
                    </svg>
                </div>
            </div>
        </draggable>

        <modal :open="showDeleteModal" @close="closeDeleteModal">
            <template v-slot:modal-title>
                <div>Are you sure you want to delete this image?</div>
            </template>

            <template v-slot:modal-content>
                <div>
                    This action cannot be undone, and the image will be permanently removed from your slide collection.
                </div>
            </template>

            <template v-slot:modal-footer>
                <button class="btn btn-deactivate" @click="confirmDelete">Confirm</button>
                <button class="btn btn-cancel" @click="closeDeleteModal">Cancel</button>
            </template>
        </modal>
        <!-- The Imgly CreativeEditor component wrapped in a modal -->
        <v-dialog v-model="showAddTextSlideModal">
            <CreativeEditor @onSave="onEditorSave" @close="showAddTextSlideModal = false" ref="imglyEditor" />
        </v-dialog>
        <!-- </div> -->
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import modal from './WarningModal.vue';
import initApiServices from '@/services/ApiInitializer';
import { orderBy, debounce, each } from 'lodash';
import SlideUpload from './SlideUpload.vue';
import ImagePreview from '../Media/ImagePreview.vue';
import { mapActions } from 'vuex';

export default {
    name: 'ManagePhotos',
    components: {
        draggable,
        modal,
        SlideUpload,
        ImagePreview,
        CreativeEditor: () => import('./Editor/CreativeEditor.vue'),
    },
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        tributeVideo: {
            type: Object,
            required: true,
        },
    },
    async mounted() {
        const response = await this.$auth.getIdTokenClaims();
        this.token = response.__raw;
        this.apiService = initApiServices(this.token);

        if (this.eventId) {
            this.getPhotos();
        }
    },
    data() {
        return {
            apiService: null,
            token: null,
            allowVideoUploads: false,
            maxFiles: 400,
            pendingUploads: [],
            slides: [],
            totalSlides: 0,
            isDragging: false,
            dragIndex: null,
            selectedSlideIndex: null,
            showAddTextSlideModal: false,
            textSlideBgColor: '#ffffff',
            textSlideContent: '',
            showDeleteModal: false,
            recordedScrollTop: 0,
            recordedScrollLeft: 0,
            pageNumber: 0,
            pageSize: 24,
            mounted: false,
            progress: 0,
        };
    },
    computed: {
        maxFilesLeft() {
            // computed difference between how many files max and existing items
            // TODO: included pending uploads while they resolve and remove if they error, etc
            return this.maxFiles - this.slides.length;
        },
        isExpanded: {
            get() {
                return this.$store.state.tributeEditor.expanded;
            },
        },
        containerHeight: {
            get() {
                return this.$store.state.tributeEditor.workspaceHeight;
            },
        },
        isAnyImageSelected() {
            return this.selectedSlideIndex !== null;
        },
        reversedSlides: {
            get() {
                return this.slides.slice().reverse();
            },
            set(val) {},
        },
        showLoadMore() {
            return this.slides.length < this.totalSlides;
        },
    },
    methods: {
        ...mapActions(['showSnackbar', 'block']),
        ...mapActions('tributeEditor', ['toggleExpandedState']),
        getSlideDisplaySrc(url) {
            let imgPath = null;

            if (url) {
                imgPath = this.extractFilePath(url);
            }

            if (imgPath) {
                return `${process.env.VUE_APP_IMG_KIT_BASE}tr:h-200/${imgPath}`;
            }

            return url;
        },
        extractFilePath(url) {
            let filePath = null;

            if (url.includes('/tribute-photos/')) {
                filePath = url.split('/tribute-photos/')[1];
            } else if (url.includes('/original-tribute-photos/')) {
                filePath = url.split('/original-tribute-photos/')[1];
            }

            return filePath;
        },
        getSlideDbIndex(index) {
            let hiddenSlides = 0;

            if (this.totalSlides > this.slides.length) {
                hiddenSlides = this.totalSlides - this.slides.length;
            }

            return index + 1 + hiddenSlides;
        },
        handleSlidesStartIntersect(isIntersecting) {
            if (this.mounted && isIntersecting) {
                this.slidesNextPage();
            }
        },
        recordScrollPosition() {
            const container = this.$el.querySelector('.slides-container');
            if (container) {
                this.recordedScrollTop = container.scrollHeight - container.scrollTop;
                this.recordedScrollLeft = container.scrollWidth - container.scrollLeft;
            }
        },
        restoreScrollPosition() {
            const container = this.$el.querySelector('.slides-container');

            if (container) {
                container.scrollTop = container.scrollHeight - this.recordedScrollTop;
                container.scrollLeft = container.scrollWidth - this.recordedScrollLeft;
            }
        },
        async slidesNextPage() {
            if (this.slides.length >= this.totalSlides) return;

            this.recordScrollPosition();

            this.pageNumber++;
            let data = {
                reversed: true,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
            };

            var resp = await this.apiService.tributePhoto.getPhotos(this.eventId, data);

            if (resp.data.photos) {
                this.slides = this.slides.concat(resp.data.photos).map(slide => {
                    return { ...slide, rotation: 0 };
                });

                this.$nextTick(() => {
                    this.restoreScrollPosition();
                });
            }
        },
        async getPhotos() {
            let data = {
                reversed: true,
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
            };

            var resp = await this.apiService.tributePhoto.getPhotos(this.eventId, data);

            if (resp.data) {
                this.slides = resp.data.photos.map(slide => {
                    return { ...slide, rotation: 0 };
                });

                this.$nextTick(() => {
                    this.mounted = true;
                    this.scrollGalleryToEnd();
                });
            }

            if (resp.data.total) {
                this.totalSlides = resp.data.total;
            }
        },
        // handleResize() {
        //     console.log('handling resize');
        //     const container = this.$el.querySelector('.slides-container');
        //     if (container) {
        //         container.scrollLeft = 0;
        //     }
        // },
        /**
         * Rotates an image and returns the rotated copy as a Blob.
         * @param {HTMLImageElement} image - The source image to rotate.
         * @param {number} angle - The angle to rotate the image in degrees (clockwise).
         * @param {string} mimeType - The MIME type of the output image (e.g., 'image/png', 'image/jpeg').
         * @param {number} quality - The quality of the output image (for lossy formats like JPEG, range 0-1).
         * @returns {Promise<Blob>} A Promise that resolves with the rotated image as a Blob.
         */
        rotateImageToBlob(image, angle, mimeType = 'image/png', quality = 1) {
            return new Promise((resolve, reject) => {
                try {
                    console.log('rotate image 2 blob ', angle);
                    // Create a canvas to draw the rotated image
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    // Calculate the dimensions of the rotated image
                    canvas.width = angle % 180 === 0 ? image.width : image.height;
                    canvas.height = angle % 180 === 0 ? image.height : image.width;

                    //Center the canvas
                    ctx.translate(canvas.width / 2, canvas.height / 2);

                    // Convert the angle to radians
                    const radians = (angle * Math.PI) / 180;

                    // Rotate the canvas
                    ctx.rotate(radians);

                    // Draw the image onto the rotated canvas
                    ctx.drawImage(image, -image.width / 2, -image.height / 2);

                    canvas.toBlob(
                        blob => {
                            if (blob) resolve(blob);
                            else reject(new Error('Canvas toBlob failed'));
                        },
                        mimeType,
                        quality,
                    );
                } catch (error) {
                    reject(error);
                }
            });
        },
        async deleteSelectedSlides() {
            try {
                // TODO: support multi select
                if (this.selectedSlideIndex === null) throw new Error('No slides selected');

                var slideToDelete = this.reversedSlides[this.selectedSlideIndex];

                if (!slideToDelete?.id) throw new Error('Slide not found');

                var resp = await this.apiService.tributePhoto.deletePhotoBatch([slideToDelete.id]);

                this.$emit('refresh-preview');
            } catch (error) {
                console.log(error, 'error deleting slide');
            }
        },
        scrollGalleryToEnd() {
            const container = this.$el.querySelector('.slides-container');
            if (container) {
                container.scrollTo({
                    top: container.scrollHeight,
                    left: container.scrollWidth,
                    behavior: 'instant',
                });
            }
        },
        moveSlideForward() {
            if (this.selectedSlideIndex !== null && this.selectedSlideIndex < this.reversedSlides.length - 1) {
                const currentIndex = this.selectedSlideIndex;
                const newIndex = currentIndex + 1;
                this.changeSlidePosition(currentIndex, newIndex);
                this.selectedSlideIndex = newIndex;
            }
        },
        moveSlideBackward() {
            if (this.selectedSlideIndex !== null && this.selectedSlideIndex > 0) {
                const currentIndex = this.selectedSlideIndex;
                const newIndex = currentIndex - 1;

                this.changeSlidePosition(currentIndex, newIndex);
                this.selectedSlideIndex = newIndex;
            }
        },
        changeSlidePosition(currentIndex, newIndex) {
            const slide = this.reversedSlides[currentIndex];

            if (!slide?.id) return;

            this.reversedSlides.splice(newIndex, 0, this.reversedSlides.splice(currentIndex, 1)[0]);
            // this.selectedSlideIndex = newIndex;

            const targetOrder = this.getTargetOrder(newIndex);
            this.updateSlideOrder(slide.id, targetOrder);
        },
        //Converts current "page" slide index to target db order
        getTargetOrder(arrIndex) {
            const invertedIndex = this.reversedSlides.length - arrIndex;
            return this.totalSlides - invertedIndex;
        },
        editActiveSlide() {
            if (this.selectedSlideIndex === null) {
                return;
            }
            if (this.selectedSlideIndex > -1) {
                this.openAddTextSlideModal();
                // TODO: this works for now, but there's a chance that it might need a longer delay once saving the tribute is implemented
                // before attempting to load an existing scene since it sort of assumes the editor is already initialized
                this.$nextTick(() => {
                    // if slide has sceneData treat it like a imgly slide
                    if (this.reversedSlides[this.selectedSlideIndex]?.hasOwnProperty('_sceneData')) {
                        this.$refs.imglyEditor.init().then(() => {
                            this.$refs.imglyEditor.loadScene(
                                this.reversedSlides[this.selectedSlideIndex]._sceneData,
                                this.selectedSlideIndex,
                            );
                        });
                    } else {
                        // Create a scene from image
                        this.$refs.imglyEditor.init().then(() => {
                            this.$refs.imglyEditor.createSceneFromImageURL(
                                this.reversedSlides[this.selectedSlideIndex].url,
                                this.selectedSlideIndex,
                            );
                        });
                    }
                });
            }
        },
        onDragStart(evt) {
            this.isDragging = true;
            this.dragIndex = this.showLoadMore ? evt.oldIndex - 1 : evt.oldIndex;
            this.selectedSlideIndex = null;
        },
        onDragEnd(evt) {
            this.isDragging = false;
            this.dragIndex = null;

            this.handleSingleItemDragEnd(evt);
        },
        async handleSingleItemDragEnd(evt) {
            let oldIndex = evt.oldIndex;
            let newIndex = evt.newIndex;

            if (this.showLoadMore) {
                oldIndex--;
                newIndex--;
            }

            if (oldIndex == newIndex) return;

            this.changeSlidePosition(oldIndex, newIndex);
        },
        updateSlideOrder: debounce(async function (id, order) {
            if (this.isContributePage) {
                this.showSnackbar({ message: 'Unauthorized to edit order' });
                return;
            }

            try {
                const resp = await this.apiService.tributePhoto.updateOrder(id, order);

                this.$emit('refresh-preview');
            } catch (error) {
                this.showSnackbar({ message: 'Error updating order' });
            }
        }, 500),
        triggerPhotoUpload() {
            this.$refs.fileInput.click();
        },
        async replaceSlideFile(slide, file) {
            try {
                if (!slide) throw new Error('Slide to replace not found');
                let { data: SAS_INFO } = await await this.apiService.tributePhoto.getUploadUrl(slide.eventId, file);

                // TODO: add cancel token
                await this.apiService.blob.upload(SAS_INFO.sas, file, {
                    onUploadProgress: this.createProgressHandler(file),
                });

                const replacement = await this.apiService.tributePhoto.replacePhoto(slide.id, SAS_INFO.fileName, true);
                this.$emit('refresh-preview');
            } catch (error) {
                console.log(error, 'error replacing photo');
            }
        },
        createProgressHandler(file) {
            return progressEvent => {
                const currentBytes = this.uploadedBytes + progressEvent.loaded;
                const percent = Math.ceil((currentBytes / this.totalBytes) * 100);

                this.progress = percent;
            };
        },
        async uploadTextSlide(eventId, file) {
            try {
                var remainingAllowed = await this.apiService.tributePhoto.getRemainingAllowedUploads(eventId);

                if (remainingAllowed.remaining <= 0) {
                    throw new Error(`Cannot exceed remaind allowed uploads: ${remainingAllowed.remaining}`);
                }

                let { data: SAS_INFO } = await await this.apiService.tributePhoto.getUploadUrl(eventId, file);

                // TODO: add cancel token
                await this.apiService.blob.upload(SAS_INFO.sas, file, {
                    onUploadProgress: this.createProgressHandler(file),
                });

                let tributePages = ['TributeUploadPage', 'TributeFamilyPage'];

                let data = [
                    {
                        duration: 0,
                        mediaType: 2,
                        name: file.name,
                        url: SAS_INFO.sas.split('?sv=')[0],
                        uniqueName: SAS_INFO.fileName,
                        uploadSource: tributePages.includes(this.$route.name) ? 2 : 0,
                        uploadUserName: tributePages.includes(this.$route.name) ? 'Unknown' : this.$auth.user.name,
                        uploadUserRelationship: tributePages.includes(this.$route.name) ? 'Unknown' : 'Funeral Staff',
                    },
                ];

                var resp = await this.apiService.tributePhoto.createPhotoBatch(eventId, data, false);

                this.scrollGalleryToEnd();
                this.$emit('refresh-preview');
            } catch (error) {
                console.log('Error uploading text slide', error);
            }
        },
        async onEditorSave(editorResults) {
            this.closeAddTextSlideModal();
            // Are we editing an existing slide or is this a new one?
            if (typeof editorResults.slideIndex === 'number' && editorResults.slideIndex > -1) {
                const index = editorResults.slideIndex;
                // For existing images this updated bloc should overwrite
                let slide = this.reversedSlides[index];
                let file = this.blob2File(editorResults.blob, slide.name);

                this.reversedSlides[index].url = URL.createObjectURL(editorResults.blob);
                this.reversedSlides[index]._sceneData = editorResults.scene;

                await this.replaceSlideFile(slide, file);
            } else {
                let file = this.blob2File(editorResults.blob, 'Title Slide.png');

                await this.uploadTextSlide(this.eventId, file);

                // New Slide being added
                this.reversedSlides.push({
                    id: Date.now(),
                    url: URL.createObjectURL(editorResults.blob),
                    rotation: 0,
                    _sceneData: editorResults.scene,
                });
                this.focusImage(this.reversedSlides.length - 1);
            }
        },
        blob2File(blob, fileName) {
            return new File([blob], fileName, {
                lastModified: new Date(),
                type: blob.type,
            });
        },
        addNewTextSlide() {
            // When adding a new text slide, first clear out any existing selected image
            if (this.selectedSlideIndex !== null) {
                this.selectedSlideIndex = null;
            }
            // Then open up the editor modal
            this.openAddTextSlideModal();
            this.$nextTick(() => {
                this.$refs.imglyEditor.init().then(() => {
                    this.$refs.imglyEditor.createBlankScene();
                });
            });
        },
        openAddTextSlideModal() {
            this.showAddTextSlideModal = true;
        },
        closeAddTextSlideModal() {
            this.showAddTextSlideModal = false;
        },
        onFileAdded: debounce(function () {
            this.scrollGalleryToEnd();
            // Listen for files being added then trigger the upload automatically
            this.$refs.uploaderBtn.initUpload(this.$props.eventId).then(newSlides => {
                // all uploads should be finished here, so set percentage to 100
                each(this.pendingUploads, up => {
                    if (up.progress.percentage < 100) {
                        up.progress.percentage = 100;
                    }
                });

                const preloadImagePromises = [];
                each(newSlides, slide => {
                    // start preloading of images to try and limit flashing between the swapping of slides
                    const tmpImagePromise = new Promise(resolve => {
                        const tmpImage = new Image();
                        tmpImage.onload(resolve);
                        tmpImage.src = slide.url;
                    });
                    preloadImagePromises.push(tmpImagePromise);
                });

                // After all images are preloaded we can move slides from pending to normal slides
                Promise.allSettled(preloadImagePromises).then(() => {
                    setTimeout(() => {
                        this.pendingUploads = [];
                        // append new slides and sort by order
                        // this.slides = orderBy(this.slides.concat(newSlides), 'order');

                        // Main photo is not included in slide show gallery
                        //If main photo is not assigned, first upload becomes main photo
                        if (this.tributeVideo.mainPhotoId <= 0) {
                            newSlides.shift();
                        }

                        this.slides = [
                            ...newSlides.map(slide => ({ ...slide, rotation: 0 })).reverse(),
                            ...this.slides,
                        ];
                        this.selectedSlideIndex = null;
                        this.$emit('refresh-preview');
                    }, 400);
                });
            });
        }, 300),
        addPhotos(event) {
            const files = event.target.files;
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onload = e => {
                    this.slides.push({
                        id: Date.now() + i,
                        url: e.target.result,
                        rotation: 0,
                        uploading: true,
                        uploadProgress: `${Math.random() * 100}%`,
                    });
                };
                reader.readAsDataURL(files[i]);
            }
        },
        async handleRotateImage() {
            if (this.selectedSlideIndex !== null) {
                this.reversedSlides[this.selectedSlideIndex].rotation =
                    (this.reversedSlides[this.selectedSlideIndex].rotation + 90) % 360;

                const slide = this.reversedSlides[this.selectedSlideIndex];

                this.generateRotatedImage(slide);
            }
        },
        generateRotatedImage: debounce(async function (slide) {
            const image = new Image();
            image.crossOrigin = 'anonymous';
            image.src = slide.url;

            image.onload = async () => {
                const rotatedBlob = await this.rotateImageToBlob(image, slide.rotation);

                const file = this.blob2File(rotatedBlob, slide.name);

                await this.replaceSlideFile(slide, file);
            };

            image.onerror = () => {
                console.error('image rotate error');
            };
        }, 500),
        selectImage(index) {
            if (this.selectedSlideIndex === index) {
                this.selectedSlideIndex = null;
            } else {
                this.selectedSlideIndex = index;
                this.focusImage(index);
            }
        },
        focusImage(index) {
            this.$nextTick(() => {
                const slide = this.$el.querySelectorAll('.slide')[index];
                if (slide) {
                    slide.scrollIntoView({ behavior: 'smooth', inline: 'center' });
                }
            });
        },
        openDeleteModal() {
            if (this.isAnyImageSelected) {
                this.showDeleteModal = true;
            }
        },
        closeDeleteModal() {
            this.showDeleteModal = false;
        },
        confirmDelete() {
            if (this.selectedSlideIndex !== null) {
                var slide = this.reversedSlides[this.selectedSlideIndex];

                if (!slide?.id) return;

                this.deleteSelectedSlides();
                this.reversedSlides.splice(this.selectedSlideIndex, 1);
                this.selectedSlideIndex = null;
            }
            this.closeDeleteModal();
        },
        getCurrentPhotos() {
            return this.slides.map(slide => ({
                id: slide.id,
                url: slide.url,
                rotation: slide.rotation,
            }));
        },
        getCurrentPhotoOrder() {
            return this.slides.map(slide => slide.id);
        },
        toggleHeight() {
            this.toggleExpandedState();
        },
    },
};
</script>

<style lang="scss" scoped>
.manage-photos-container {
    display: flex;
    flex-direction: column;
}

.action-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.primary-button,
.secondary-button {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.shadow-button {
    box-shadow: 0px 1px 2px 0px #0000000d;
}

.left-buttons {
    display: flex;
    align-items: center;
}

.primary-button {
    width: 228px;
    background: #ea580c;
    color: white;
    border: none;
    height: 38px;
    border-radius: 6px;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.secondary-button {
    width: 138px;
    background: white;
    color: #374151;
    border: 1px solid #d1d5db;
    height: 38px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.button-divider {
    width: 1px;
    height: 60%;
    background: #d1d5db;
    margin-right: 8px;
}

.right-buttons {
    display: flex;
    width: 260px;
    justify-content: space-between;
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px #0000000d;
}

.icon-button {
    width: 38px;
    height: 38px;
    background: white;
    border: 1px solid #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
}

.highlighted-icon-button {
    width: 38px;
    height: 38px;
    background: #fee2e2;
    border: 1px solid #fca5a5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slides-container {
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    overflow-x: auto;
    overflow-y: hidden;
    transition: height 0.8s;
    height: 100%;
}

.slides-container.expanded {
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100%;
    align-content: flex-start;
}

.slide,
.pending-slide {
    height: 100%;
    max-height: 242px;
    aspect-ratio: 1;
    border-radius: 8px;
    position: relative;
    transition: transform 0.2s, border 0.2s;
    flex-shrink: 0;
    display: inline-block;

    .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        z-index: -1;
    }
}
.pending-slide {
    img {
        opacity: 0.4;
    }

    .progress-indicator {
        width: 93%;
        display: inline-block;
        height: 5px;
        bottom: 10px;
        position: absolute;
        left: 0;
        right: 0;
        background: #ffedd5;
        margin: auto;
        border-radius: 10rem;
    }

    .progress-slider {
        height: 100%;
        background: #f97316;
        border-radius: 10rem;
        transition: width 200ms ease-in-out;
    }
}
.slide.dragging {
    transform: rotate(-3deg);
    border: 4px solid #ea580c;
}

.slide.selected .slide-image {
    border: 4px solid #ea580c;
}

.slide-order {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 42px;
    height: 32px;
    background: white;
    border: 1px solid #d1d5db;
    box-shadow: 0px 1px 2px 0px #0000000d;
    color: #6b7280;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 2.5%;
}

.selected-indicator {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    background: #ea580c;
    border: 1.5px solid #ea580c;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ghost-hidden {
    display: none !important;
}

.ghost-slide {
    // width: 242px;
    // height: 242px;
    height: 100%;
    max-height: 242px;
    aspect-ratio: 1;
    border: 1px dashed #d1d5db;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    flex-shrink: 0;
}

.ghost-icon-container {
    width: 52px;
    height: 38px;
    background: #fff7ed;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
}

.ghost-icon {
    width: 20px;
    height: 20px;
}
</style>
