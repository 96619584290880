import ApiService from './api.service';

const ServicesService = token => {
    const api = ApiService.createApiInstance(token);

    return {
        get(id) {
            return api.get(`/Events/${id}`);
        },
    };
};

export default ServicesService;
