import { render, staticRenderFns } from "./TributeTabSection.vue?vue&type=template&id=4c31617a&"
import script from "./TributeTabSection.vue?vue&type=script&lang=js&"
export * from "./TributeTabSection.vue?vue&type=script&lang=js&"
import style0 from "./TributeTabSection.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
import { VSlider } from 'vuetify/lib/components/VSlider';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VWindow } from 'vuetify/lib/components/VWindow';
import { VWindowItem } from 'vuetify/lib/components/VWindow';
installComponents(component, {VBtn,VCardActions,VCardText,VForm,VList,VListGroup,VListItem,VListItemContent,VListItemTitle,VMenu,VProgressLinear,VSlideYTransition,VSlider,VTabItem,VTabsItems,VTextField,VWindow,VWindowItem})
