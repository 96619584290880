<template>
    <div>
        <header class="explorer-header">
            <div class="header-left">
                <slot name="header-left"><span class="explorer-title">Explore our vast music list</span></slot>
            </div>
            <div class="header-right">
                <slot name="header-right">
                    <div>
                        <v-progress-circular indeterminate color="deep-orange" v-if="loading"></v-progress-circular>
                    </div>
                    <v-text-field :loading="loading" dense outlined v-model="filters.search" placeholder="Search Music"
                        :debounce="250" class="search-field" clearable>
                        <template v-slot:prepend-inner>
                            <font-awesome-icon icon="fa-regular fa-magnifying-glass"></font-awesome-icon>
                        </template>
                    </v-text-field>
                    <v-select ref="genreSelect" dense placeholder="Genres" class="explore-header-field" outlined
                        clearable :items="genres" item-text="name" item-value="id" multiple
                        v-model.lazy="filters.selectedGenres">
                        <template v-slot:prepend-item v-if="$vuetify.breakpoint.smAndDown">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        Filter by Genre
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-slot:append-item v-if="$vuetify.breakpoint.smAndDown">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-btn color="orange">Apply Filter</v-btn>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-slot:selection="{ item, index }">
                            <v-chip small v-if="index === 0">
                                <span>{{ item.name }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                                (+{{ filters.selectedGenres.length - 1 }})
                            </span>
                        </template>
                    </v-select>
                    <v-select ref="keywordSelect" dense class="explore-header-field" outlined placeholder="Keywords"
                        clearable :items="keywords" item-text="name" item-value="id" multiple
                        v-model.lazy="filters.selectedKeywords">
                        <template v-slot:selection="{ item, index }">
                            <v-chip small v-if="index === 0">
                                <span>{{ item.name }}</span>
                            </v-chip>
                            <span v-if="index === 1" class="grey--text text-caption">
                                (+{{ filters.selectedKeywords.length - 1 }})
                            </span>
                        </template>
                    </v-select>
                    <v-btn outlined class="custom-upload">
                        <v-icon left>
                            mdi-upload
                        </v-icon>
                        Upload your own
                    </v-btn>
                </slot>
            </div>
        </header>
        <main class="track-list py-2">
            <template v-for="song in songs">
                <MusicTrack :song="song" :key="song.id" :isSelected="selectedSongsIds.includes(song.id)"
                    :token="token" />
            </template>
            <div class="no-results" v-if="!songs.length">
                <div>No results to display.</div>
            </div>
        </main>
        <div class="pagination">
            <v-pagination v-model="currentPage" :length="totalPages"></v-pagination>
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import { map, debounce } from 'lodash';
// import draggable from 'vuedraggable';
// import TributeMusicTable from '@/components/Tables/Admin/TributeMusicTable.vue';
// import TributePlaylistExpansion from '@/components/ManageService/Tribute/TributePlaylistExpansion.vue';
import TributeMusicUploader from '@/components/ManageService/Tribute/TributeMusicUploader.vue';
import CustomTooltip from '@/components/ui/CustomTooltip.vue';
import MusicTrack from './MusicTrack.vue';
import TributeSongService from '@/services/tributeSong.service';

export default {
    name: 'MusicExplorer',
    data() {
        return {
            tributeSongApiInstance: null,
            token: null,
            message: '',
            total: 0,
            currentPage: 1,
            pageSize: 25,
            loading: false,
            keywords: [],
            filters: {
                search: '',
                selectedKeywords: [],
                selectedGenres: [],
            },
            genres: [],
            transitionDuration: 1,
            templateDuration: 0,
            songs: []
        };
    },
    components: {
        // draggable,
        // TributeMusicTable,
        // TributePlaylistExpansion,
        MusicTrack,
        TributeMusicUploader,
        CustomTooltip,
    },
    filters: {
        durationTimeStamp(val) {
            var minutes = Math.floor(val / 60);
            var seconds = val - minutes * 60;

            function str_pad_left(string, pad, length) {
                return (new Array(length + 1).join(pad) + string).slice(-length);
            }
            const finalTime = str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
            return finalTime;
        },
    },
    computed: {
        totalPages() {
            return Math.ceil(this.total / this.pageSize);
        },
        selectedSongs: {
            get() {
                return this.$store.state.tributeVideo.selectedSongs;
            },
        },
        selectedSongsIds() {
            if (this.selectedSongs?.length) {
                return map(this.selectedSongs, 'id');
            }
            return [];
        },
        selectedTemplates: {
            get() {
                return this.$store.state.tributeVideo.selectedTemplates;
            },
            set(value) {
                this.updateTributeVideoSelectedTemplates(value);
            },
        },
        playlistDuration() {
            let duration = 0;
            this.selectedSongs.forEach(song => {
                duration += song.duration;
            });
            return duration;
        },
        endingDuration() {
            let duration = 10;

            if (this.$store.state.tributeVideo.endingType == 2) {
                duration = this.$store.state.tributeVideo.endingDuration;
            }

            return duration;
        },
        recommendedDuration() {
            return this.templateDuration + this.slideshowDuration + this.endingDuration;
        },
        slideshowDuration() {
            // return (
            //     (this.tributeVideo.totalPhotos + 1) * this.tributeVideo.slideDuration - this.tributeVideo.totalPhotos
            // );
            const videoCount = this.$store.state.tributeVideo.videoSlidesCount;

            // current transition time is 1s, account for 1s overlap per slide during duration calc
            const totalSlides = this.$store.state.tributeVideo.totalPhotos - videoCount;

            let fullPhoto = totalSlides * this.$store.state.tributeVideo.slideDuration;
            let adjustedPhoto = fullPhoto - totalSlides * this.transitionDuration;

            let fullVideo = this.$store.state.tributeVideo.videoSlidesDuration;
            let adjustedVideo = fullVideo - videoCount * this.transitionDuration;

            let total = adjustedPhoto + adjustedVideo;
            return parseFloat(total.toFixed(2));
        },
        remainingSongs() {
            const averageSongLength = 210;
            const remainingTime = this.recommendedDuration - this.playlistDuration;
            return Math.round(remainingTime / averageSongLength);
        },
        tributeVideo() {
            return this.$store.state.tributeVideo;
        },
    },
    props: {
        filteredGenres: {
            type: Array,
            required: false,
        },
        featuredOnly: {
            type: Boolean,
            default: false,
        },
        funeralHomeId: {
            type: Number,
            required: false,
        },
        multiPlaylist: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        filteredGenres() {
            this.selectedGenres = [...this.filteredGenres];
        },
        filters: {
            handler() {
                this.debouncedDataFetch();
            },
            deep: true
        },
        currentPage() {
            this.debouncedDataFetch();
        }
    },
    methods: {
        ...mapActions('tributeVideo', [
            'updateTributeVideo',
            'updateTributeVideoSelectedTemplates',
            'updateTributeVideoSelectedSongs',
        ]),
        buildQueryFilters() {
            let filters = {};
            let queryFilters = {};
            filters.pageSize = this.pageSize;
            filters.pageNumber = this.currentPage - 1;
            if (this.filters.search?.length) {
                filters.search = this.filters.search;
            }
            // TODO: keywords & genres
            if (this.filters.selectedGenres?.length) {
                queryFilters.genre = this.filters.selectedGenres;
            }
            if (this.filters.selectedKeywords?.length) {
                queryFilters.keyword = this.filters.selectedKeywords;
            }

            return { filters, queryFilters };
        },
        getKeywords() {
            return this.tributeSongApiInstance.getKeywords()
                .then(res => {
                    this.keywords = res.data.musicKeywords;
                    this.totalKeywords = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        debouncedDataFetch: debounce(function () {
            this.getSongs();
        }, 700),
        getSongs() {
            this.loading = true;
            const {filters, queryFilters } = this.buildQueryFilters();
            return this.tributeSongApiInstance.getSongs(filters, queryFilters)
                .then(songResults => {
                    this.total = songResults.data?.total;
                    this.songs = songResults.data?.tributeSongs || [];
                    this.$nextTick(() => {
                        this.loading = false;
                    });
                });
        },
        getGenres() {
            return this.tributeSongApiInstance.getGenres()
                .then(res => {
                    this.genres = res.data.musicGenres;
                    this.totalGenres = res.data.total;
                })
                .catch(err => {
                    console.log(err, 'error');
                });
        },
        handleChange(event) {
            const selectedIds = this.selectedSongs.map(obj => obj.id);
            this.updateSelectedSongs(this.tributeVideo.id, selectedIds);
        },
        updateSelectedSongs(id, json) {
            this.axiosInstance
                .post(`TributeVideo/selected-songs/${id}`, json)
                .then(resp => {})
                .catch(err => {
                    console.log(err, 'template json error');
                });
        },
        async setAuthToken() {
            const response = await this.$auth.getIdTokenClaims();
            this.token = response.__raw;
        },
        setFilter(type, value) {
            if (value) {
                switch (type) {
                    case 'genre':
                        this.filters.selectedGenres = [value];
                        break;
                    case 'keyword':
                        this.filters.selectedKeywords = [value];
                        break;
                }
            } else {
                // If a user clicks on "Explore All Music" then we clear out existing filters
                this.filters.selectedGenres = [];
                this.filters.selectedKeywords = [];
            }
        }
        // setSlideDuration() {
        //     let slideDuration = 0;
        //     if (this.tributeVideo.totalPhotos + 1 < 100) {
        //         slideDuration = 7;
        //     } else {
        //         slideDuration = 5;
        //     }

        //     this.updateTributeVideo({ slideDuration: slideDuration });
        // },
    },
    async mounted() {
        await this.setAuthToken();
        this.tributeSongApiInstance = TributeSongService(this.token);
        
        // if (this.tributeVideo.slideDuration == 0) {
        //     this.setSlideDuration();
        // }
        this.getSongs();
        this.getKeywords();
        this.getGenres();
    },
};
</script>

<style lang="scss" scoped>
.explorer-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 20;
    @include mobile {
        flex-direction: column;
    }
    .header-left {
        .explorer-title {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
            color: $primary-grey;
        }
    }
    .header-right {
        display: flex;
        gap: 10px;
        @include mobile {
            flex-direction: column;
            align-self: stretch;
        }
    }
}
// tweak to shift the search field icon down a bit for better alignment
.search-field ::v-deep .v-input__prepend-inner {
    padding-top: 3px;
}
.custom-upload {
    height: 40px !important;
    & ::v-deep .v-btn__content {
        text-transform: initial;
        letter-spacing: normal;
    }
}
.explore-header-field {
    width: 240px;
    @include mobile {
        max-width: inherit;
    }
}
.track-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.no-results {
    padding: 1rem;
    font-weight: 500;
    background: #f9fafb;
    text-align: center;
}
.pagination {
    justify-content: flex-end;
}
</style>
